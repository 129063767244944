import YOGO_CLIENT_DOMAIN from '../services/YOGO_CLIENT_DOMAIN'
import cachebuster from '../services/cachebuster'

const _each = require('lodash/each')
const _isObject = require('lodash/isObject')


let connectorIframe,
  connectorIsLoaded = false,
  waitingCommandQueue = [],
  runningCommandCallbacks = {}

if (document.readyState !== 'loading') {
  loadConnector()
} else {
  document.addEventListener("DOMContentLoaded", loadConnector)
}


window.addEventListener('message', windowMessageReceived)


function loadConnector() {
  connectorIframe = document.createElement('iframe')

  let connectorUrl
  if (process.env.VUE_APP_CONNECTOR_URL) {
    // Local test environment
    connectorUrl = process.env.VUE_APP_CONNECTOR_URL + '?clientId=' + process.env.VUE_APP_CLIENT_ID + '&cachebuster=' + cachebuster;
  } else {
    // Staging / production
    connectorUrl = 'https://' + YOGO_CLIENT_DOMAIN + '/widget-connector/index.html?cachebuster=' + cachebuster;

  }

  connectorIframe.src = connectorUrl

  const hiddenIFrameStyles = [
    'position: absolute !important;',
    'opacity: 0 !important;',
    'width: 1px !important;',
    'height: 1px !important;',
    'top: 0 !important;',
    'left: 0 !important;',
    'border: none !important;',
    'display: block !important;',
    'z-index: -1 !important;',
    'pointer-events: none;',
  ]

  connectorIframe.setAttribute('style', hiddenIFrameStyles.join(''))
  connectorIframe.id = 'yogo-connector-frame'

  connectorIframe.addEventListener('load', connectorLoaded)

  document.body.appendChild(connectorIframe)

}

function connectorLoaded() {

  connectorIsLoaded = true
  if (waitingCommandQueue.length) {
    _each(waitingCommandQueue, function (commandObject) {
      commandObject.callbackIdentifier = getRandomIdentifier()
      runningCommandCallbacks[commandObject.callbackIdentifier] = commandObject.callback
      delete commandObject.callback
      connectorIframe.contentWindow.postMessage(JSON.stringify(commandObject), '*')
    })
    waitingCommandQueue = []
  }
}

function windowMessageReceived(e) {
  if (!e.data) return

  let commandObject = e.data

  if (!_isObject(commandObject)) {
    try {
      commandObject = JSON.parse(commandObject)
    } catch (e) {
      // Not a YOGO object
      return
    }

  }

  if (
    !commandObject.response ||
    !commandObject.callbackIdentifier ||
    !runningCommandCallbacks[commandObject.callbackIdentifier]
  ) return

  runningCommandCallbacks[commandObject.callbackIdentifier](commandObject.response)
}

function getRandomIdentifier() {
  return 'id-' + Math.floor(Math.random() * 0x1000000)
}


function callApi(params) {
  return new Promise((resolve, reject) => {

    let commandObject = {
      endpoint: params.endpoint,
      method: params.method,
      body: params.body,
      aux: params.aux,
    }
    processCommandObject(commandObject, resolve)
  })
}

function processCommandObject(commandObject, resolveFunction) {
  if (connectorIsLoaded) {
    commandObject.callbackIdentifier = getRandomIdentifier()
    runningCommandCallbacks[commandObject.callbackIdentifier] = resolveFunction
    connectorIframe.contentWindow.postMessage(JSON.stringify(commandObject), '*')
  } else {
    commandObject.callback = resolveFunction
    waitingCommandQueue.push(commandObject)
  }
}

function get(endpoint) {
  return callApi({
    endpoint: endpoint,
  })
}

function post(endpoint, body) {
  return callApi({
    endpoint: endpoint,
    method: 'post',
    body: body,
  })
}

function deleteFnc(endpoint) {
  return callApi({
    endpoint: endpoint,
    method: 'delete',
  })
}

async function addToLocalCart(localCartItem) {
  return new Promise((resolve, reject) => {
    const commandObject = {
      commandType: 'addToLocalCart',
      localCartItem: localCartItem,
    }
    processCommandObject(commandObject, resolve)
  })
}

export default {
  get: get,
  post: post,
  'delete': deleteFnc,
  addToLocalCart: addToLocalCart,
}


