<template>
  <div></div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['client']),
  },
  methods: {
    formatCurrency(amount) {
      return new Intl.NumberFormat(
          this.client.settings.locale,
          {
            style: 'currency',
            currency: this.client.currency,
          },
      ).format(amount);
    },
  },
};

</script>
