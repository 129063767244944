import Vue from 'vue'
import Vuex from 'vuex'
import YogoApi from '@/gateways/YogoApi'
import themeSettings from './theme-settings'
import YogoFrontend from '@/gateways/YogoFrontend';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    themeSettings
  },
  state: {
    user: null,
    connectorUrl: process.env.VUE_APP_CONNECTOR_URL,
    frontendUrl: process.env.VUE_APP_FRONTEND_URL,
    client: null,
    routerBase: '/frontend',
    ready: false,
    classesCounter: 0
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    clearUser(state) {
      state.user = null
    },
    setClient(state, client) {
      state.client = client
    },
    setReady(state) {
      state.ready = true
    },
    setLocale(state, locale) {
      state.locale = locale
    },
    incrementClassesCounter(state) {
      state.classesCounter = state.classesCounter + 1
    }
  },
  getters: {
    userIsLoggedIn(state) {
      return !!state.user
    },
    userName(state) {
      if (state.user) {
        return state.user.first_name + ' ' + state.user.last_name
      } else {
        return ''
      }
    },
    user(state) {
      if (state.user) {
        return state.user
      } else {
        return null
      }
    },
    client(state) {
      return state.client ? state.client : {}
    },
    locale(state) {
      return state.client ? state.client.settings.locale : 'en';
    },
    clientLogoFilename(state) {
      return state.client && state.client.logo && state.client.logo.filename ? state.client.logo.filename : ''
    },
    apiRoot(state) {
      return state.apiRoot
    },
    routerBase(state) {
      return state.routerBase
    },
    stateReady(state) {
      return state.ready
    },
    connectorUrl(state) {
      return state.connectorUrl
    },
    frontendUrl(state) {
      return state.frontendUrl
    },
    classSignups(state) {
      return state.user ? state.user.class_signups : null
    },
    eventSignups(state) {
      return state.user ? state.user.event_signups : null
    },
    classPasses(state) {
      return state.user ? state.user.class_passes : null
    },
    memberships(state) {
      return state.user ? state.user.memberships : null
    },
    classSignoffDeadlineInHumanFormat(state) {
      if (!state.client) return 0
      let minutes = state.client.class_signoff_deadline_minutes
      const days = Math.floor(minutes / 1440)
      minutes -= days * 1440
      const hours = Math.floor(minutes / 60)
      minutes -= hours * 60
      let textParts = []
      if (days) textParts.push(days + ' dage')
      if (hours) textParts.push(hours + ' timer')
      if (minutes) textParts.push(minutes + 'minutter')
      if (textParts.length === 3) {
        return textParts[0] + ', ' + textParts[1] + ' og ' + textParts[2]
      } else {
        return textParts.join(' og ')
      }
    },
    productResetCounter(state) {
      return state.productResetCounter
    },
    classesCounter(state) {
      return state.classesCounter
    }
  },
  actions: {
    async logout({commit}) {
      localStorage.removeItem('accessToken')
      sessionStorage.removeItem('accessToken')
      await YogoApi.post('/logout')
      await this.dispatch('updateUser')
      commit('incrementClassesCounter')
    },
    async init({commit}) {

      await Promise.all([
        this.dispatch('updateUser'),
        this.dispatch('updateClient'),
      ])

      commit('setReady', true);

      // If we are redirecting back from Stripe
      YogoFrontend.openPopupFromUrlParameter();
    },

    async updateClient({commit}) {
      let client = await YogoApi.get('/clients/current?populate[]=settings&populate[]=branches')
      commit('setClient', client)
      window.changeYogoWidgetsLocale(client.settings.locale)
    },

    async updateUser({commit}) {

      const loginStatus = await YogoApi.get('/get-login-status')

      if (loginStatus.status === 'LOGGED_IN') {
        const user = await
          YogoApi.get('/users/' + loginStatus.user.id +
            '?populate[]=image' +
            '&populate[]=event_signups' +

            '&populate[]=class_signups' +
            '&populate[]=class_signups.used_class_pass' +
            '&populate[]=class_signups.used_class_pass.class_pass_type' +
            '&populate[]=class_signups.used_membership' +

            '&populate[]=class_passes' +
            '&populate[]=class_passes.class_pass_type' +
            '&populate[]=class_passes.class_pass_type.class_types' +

            '&populate[]=memberships' +
            '&populate[]=memberships.membership_type' +
            '&populate[]=memberships.membership_type.class_types',
          );

        // Response might be an axios object, if an error occurred.
        if (parseInt(user.id) === parseInt(loginStatus.user.id)) {
          commit('setUser', user)
        } else {
          commit('clearUser')
        }

      } else {
        commit('clearUser')
      }


    },
  },
  strict: debug,
})
