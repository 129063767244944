// If we are running on a production build (which also applies to staging),
// get the domain from which this script itself was loaded,
// and use that to load connector and frontend iframes.

// Yogo App always (in production and staging, at least) runs from a client domain so logins are kept on that domain.

let YOGO_CLIENT_DOMAIN;
let thisScript = document.querySelector('script[src*="/yogo-widgets.js"]');
if (thisScript) {
    let thisScriptProductionSrc = thisScript.getAttribute('src');

    let domainRegexResult = thisScriptProductionSrc.match(/[\w-]+\.(yogo\.dk|yogo\.no|yogobooking\.se|yogobooking\.com|yogobooking\.de|yogobooking\.pt)/);

    if (domainRegexResult) {
        YOGO_CLIENT_DOMAIN = domainRegexResult[0]
    }
}

export default YOGO_CLIENT_DOMAIN
