<template>
  <div></div>
</template>

<script>
import moment from 'moment';
import { DateTime, Info } from 'luxon';
import qs from 'qs';
import LoadingSpinner from '../LoadingSpinner.vue';
import WidgetPadding from '../WidgetPadding.vue';
import _find from "lodash/find";
import _filter from "lodash/filter";
import _padStart from 'lodash/padStart';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _isString from 'lodash/isString';
import YogoApi from '../../gateways/YogoApi';
import { mapActions, mapGetters } from 'vuex';

import LoginStatus from '../LoginStatus.vue';

import Imgix from '@/services/Imgix';
import ColorLuminance from "../../inc/ColorLuminance";

import ClassSignup from "../ClassSignup";
import EventSignup from "../EventSignup";
import ClassDialog from "../ClassDialog";
import EventDialog from "../EventDialog";
import YogoFrontend from '../../gateways/YogoFrontend';


export default {
  mixins: [Imgix],
  components: {
    widgetPadding: WidgetPadding,
    loadingspinner: LoadingSpinner,
    loginstatus: LoginStatus,
    ClassSignup,
    EventSignup,
    ClassDialog,
    EventDialog,
  },
  data() {
    return {
      dateLabel: 'dato',

      loading: false,

      dialogClass: null,
      showClassDialog: false,
      classes: [],

      eventTimeSlots: [],
      showEventDialog: false,
      dialogEventTimeSlot: null,

      branchNameIsInvalid: false,
      invalidBranchName: '',

      active: false,
    };
  },
  props: [
    'branchName',
    'date',
    'filterBySessionType',
    'filterByTeacher',
    'filterByClassType',
    'widgetClassTypes',
    'widgetOnlyLivestream',
  ],
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userName',
      'client',
      'user',
      'stateReady',
      'eventSignups',
      'classSignups',

      'classPasses',
      'memberships',

      'classSignoffDeadlineInHumanFormat',

      'classesCounter',
    ]),
  },

  mounted: function () {
    if (this.stateReady) this.updateCalendar();
  },

  async created() {
    [this.teachers, this.classTypes] = await Promise.all([
      YogoApi.get(
          '/users' +
          '?teacher=1' +
          '&hasClassesOrEventTimeSlotsFromDateForward=' + moment().startOf('isoWeek').format(
              'YYYY-MM-DD'),
      ),
      YogoApi.get(
          '/class-types' +
          '?hasClassesFromDateForward=' + moment().startOf('isoWeek').format('YYYY-MM-DD'),
      ),
    ]);

  },

  methods: {
    ...mapActions('calendarFilters', ['setFilter']),
    toggle() {
      this.active = !this.active;
    },

    showSignupButton(calendarItem) {
      if (calendarItem.class_type) {
        return calendarItem.user_can_sign_up_for_class ||
            (!this.user && calendarItem.class_accepts_customer_signups);
      } else if (calendarItem.event) {
        return calendarItem.user_can_sign_up_for_event ||
            (!this.user && calendarItem.event_accepts_customer_signups);
      }
    },

    async signUpForClass(classItem) {
      this.$refs.classSignupComponent.$emit('signUpForClass', classItem);
    },

    async signUpForWaitingList(classItem) {
      this.$refs.classSignupComponent.$emit('signUpForWaitingList', classItem);
    },

    async signUpForLivestream(classItem) {
      this.$refs.classSignupComponent.$emit('signUpForLivestream', classItem);
    },

    async startLivestream(classItem) {
      YogoFrontend.open(`/livestream/class/${classItem.id}/preloader`);
    },

    onCalendarItemClick(calendarItem) {
      if (calendarItem.class_type) {
        this.openClassDialog(calendarItem);
      } else {
        this.onEventTimeSlotClick(calendarItem);
      }
    },

    openClassDialog(classItem) {
      this.dialogClass = classItem;
      this.showClassDialog = true;
    },

    onEventTimeSlotClick(eventTimeSlot) {
      this.dialogEventTimeSlot = eventTimeSlot;
      this.showEventDialog = true;
    },

    signUpForEvent(event) {
      this.$refs.eventSignupComponent.$emit('signUpForEvent', event);
    },

    async onEventSignupSuccess(signup, event) {
      this.updateCalendar();
    },

    async updateCalendar() {
      this.loading = true;
      const branch = this.branchName ?
          _find(
              this.client.branches,
              branch => branch.name.toLowerCase() == this.branchName.toLowerCase(),
          ) :
          0;

      if (this.branchName && !branch) {
        this.branchNameIsInvalid = true;
        this.invalidBranchName = this.branchName;
        this.loading = false;
        return;
      }

      const classQueryParameters = {
        startDate: this.momentDay(0).format('YYYY-MM-DD'),
        endDate: this.momentDay(6).format('YYYY-MM-DD'),
        populate: [
          'teachers',
          'location',
          'class_type',
          'teachers.image',
          'class_type.image',
          'signup_count',
          'room',
          'room.branch',
          'class_series_types',
          'class_series_types.number_of_remaining_classes_and_livestream_classes',
          'class_series_types.current_price',
          'class_series_types.customer_has_class_series_type',
          'class_series_types.customer_can_buy_class_series_type',
          'class_series_types_livestream',
          'class_series_types_livestream.number_of_remaining_classes_and_livestream_classes',
          'class_series_types_livestream.current_price',
          'class_series_types_livestream.customer_has_class_series_type',
          'class_series_types_livestream.customer_can_buy_class_series_type',
          'capacity_text',
          'user_can_sign_up_for_class',
          'user_can_sign_up_for_number_of_seats',
          'user_signup_count',
          'user_can_sign_off_from_class',
          'class_signoff_warning',
          'class_signoff_deadline_timestamp',
          'user_must_receive_warning_after_signoff_deadline',
          'class_accepts_customer_signups',
          'class_accepts_livestream_signups',
          'user_is_signed_up_for_waiting_list',
          'user_can_sign_up_for_waiting_list',
          'user_can_sign_off_from_waiting_list',
          'user_can_sign_up_for_livestream',
          'user_can_sign_off_from_livestream',
          'user_number_on_waiting_list',
          'waiting_list_text',
          'user_can_start_livestream',
          'livestream_link',
          'ics_url',
          'ics_url_livestream',
        ],
      };
      if (parseInt(this.filterByTeacher)) classQueryParameters.teacher = this.filterByTeacher;

      if (this.widgetClassTypes) {
        classQueryParameters.class_type = this.widgetClassTypes;
      } else if (parseInt(this.filterByClassType)) {
        classQueryParameters.class_type = this.filterByClassType;
      }

      if (this.filterBySessionType) classQueryParameters.sessionType = this.filterBySessionType;
      if (branch) classQueryParameters.branch = branch.id;
      if (this.widgetOnlyLivestream) classQueryParameters.livestream_enabled = true;

      if (this.client.settings.calendar_show_public_class_attendee_list) {
        classQueryParameters.populate.push('public_attendee_list');
      }

      const classQueryString = qs.stringify(classQueryParameters, { arrayFormat: 'brackets' });

      const eventTimeSlotQueryParameters = {
        startDate: this.momentDay(0).format('YYYY-MM-DD'),
        endDate: this.momentDay(6).format('YYYY-MM-DD'),
        populate: [
          'event',
          'event.room',
          'event.room.branch',
          'event.image',
          'event.teachers',
          'event.event_group',
          'event.teachers.image',
          'event.signup_count',
          'user_can_sign_up_for_event',
          'user_can_sign_off_from_event',
          'event_accepts_customer_signups',
          'teachers',
          'room',
          'room.branch',
        ],
        onlyEventsVisibleInCalendar: 1,
      };

      if (parseInt(this.filterByTeacher)) eventTimeSlotQueryParameters.teacher = this.filterByTeacher;
      if (branch) eventTimeSlotQueryParameters.branch = branch.id;

      const eventTimeSlotQueryString = qs.stringify(eventTimeSlotQueryParameters);

      let classResponse, eventTimeSlots;
      if (this.widgetOnlyLivestream) {
        classResponse = await YogoApi.get(`/classes?${classQueryString}`);
        this.classes = classResponse.classes;
        this.eventTimeSlots = [];
      } else {
        [classResponse, eventTimeSlots] = await YogoApi.get([
          `/classes?${classQueryString}`,
          `/event-time-slots?${eventTimeSlotQueryString}`,
        ]);
        this.classes = classResponse.classes;
        this.eventTimeSlots = eventTimeSlots;
      }

      this.loading = false;

      if (this.adjustCalendarItemHeights) {
        this.$nextTick(this.adjustCalendarItemHeights);
      }
    },

    moment(date) {
      return moment(date);
    },
    momentDay(dayIndex) {
      return moment(this.date).startOf('isoWeek').add(dayIndex, 'day');
    },

    weekdayName(dayIndex) {
      return Info.weekdays('long', { locale: this.client.settings.locale })[dayIndex];
    },

    weekdayShortDate(dayIndex) {
      const dateTime = _isString(this.date) ? DateTime.fromISO(this.date) : DateTime.fromJSDate(this.date);
      return dateTime.startOf('week').plus({ days: dayIndex }).toLocaleString({
        locale: this.client.settings.locale,
        day: 'numeric',
        month: 'short',
      });
    },

    combinedClassesAndEventsForDay(dayIndex) {

      let calendarItems;

      switch (this.client.settings.calendar_show_classes_and_events_separately_or_together) {
        case 'together':
          calendarItems = this.classesForDay(dayIndex).concat(this.eventTimeSlotsForDay(dayIndex));
          break;
        case 'separately':
          switch (this.filterBySessionType) {
            case 'event':
              calendarItems = this.eventTimeSlotsForDay(dayIndex);
              break;

            case 'group':
            case 'private':
              calendarItems = this.classesForDay(dayIndex);
              break;
          }
          break;
      }

      calendarItems = _sortBy(
          calendarItems,
          'start_time',
      );

      if (this.adjustOverlappingCalendarItems) {
        calendarItems = this.adjustOverlappingCalendarItems(calendarItems);
      }

      return calendarItems;
    },

    classesForDay(dayIndex) {
      const momentDay = this.momentDay(dayIndex);
      let classes = _filter(this.classes, (classItem) => {
        return moment(classItem.date).format('YYYY-MM-DD') === momentDay.format('YYYY-MM-DD');
      });

      classes = _map(classes, classItem => {
        classItem.branchName = _get(classItem, 'room.branch.name');
        classItem.start_time_integer = parseInt(classItem.start_time.replace(':', ''));
        classItem.end_time_integer = parseInt(classItem.end_time.replace(':', ''));
        return classItem;
      });

      // Sort classes by start_time and then by end_time DESC, so classes with same start time are arranged in the calendar with the longest blocks first
      classes = _sortBy(classes,
          classItem => classItem.start_time_integer,
          classItem => -classItem.end_time_integer);

      return classes;
    },

    eventTimeSlotsForDay(dayIndex) {
      const momentDay = this.momentDay(dayIndex);
      let eventTimeSlots = _filter(this.eventTimeSlots, (eventTimeSlot) => {
        return moment(eventTimeSlot.date).format('YYYY-MM-DD') === momentDay.format('YYYY-MM-DD');
      });

      eventTimeSlots = _map(eventTimeSlots, eventTimeSlot => {
        eventTimeSlot.start_time_integer = parseInt(eventTimeSlot.start_time.replace(':', ''));
        eventTimeSlot.end_time_integer = parseInt(eventTimeSlot.end_time.replace(':', ''));
        eventTimeSlot.branchName = eventTimeSlot.event.time_slots_have_separate_rooms_and_teachers
            ? _get(eventTimeSlot, 'room.branch.name')
            : _get(eventTimeSlot, 'event.room.branch.name');
        return eventTimeSlot;
      });

      // Sort eventTimeSlots by start_time and then by end_time DESC, so eventTimeSlots with same start time are arranged in the calendar with the longest blocks first
      eventTimeSlots = _sortBy(eventTimeSlots,
          eventTimeSlot => eventTimeSlot.start_time_integer,
          eventTimeSlot => -eventTimeSlot.end_time_integer);

      return eventTimeSlots;
    },

    getEventTimeSlotTeachers(timeSlot) {
      if (timeSlot.event.time_slots_have_separate_rooms_and_teachers) {
        return timeSlot.teachers;
      }
      return timeSlot.event.teachers;
    },

    getEventTimeSlotRoom(timeSlot) {
      if (timeSlot.event.time_slots_have_separate_rooms_and_teachers) {
        return timeSlot.room;
      }
      return timeSlot.event.room;
    },

    zeroPad(string) {
      return _padStart(string, 2, '0');
    },
    dbTimeToHumanTime(dbTime) {
      return dbTime.substring(0, 5);
    },
    logout() {
      this.$store.dispatch('logout');
    },

    ColorLuminance: ColorLuminance,
  },


  watch: {
    showClassDialog(newShowClassDialog) {
      if (newShowClassDialog === false) {
        this.showTeacherInfo = [];
      }
    },
    showEventDialog(newShowEventDialog) {
      if (newShowEventDialog === false) {
        this.showTeacherInfo = [];
      }
    },
    date(newDate) {
      this.loading = true;
      if (this.stateReady) this.updateCalendar();
    },
    stateReady(newReadyState) {
      if (newReadyState) this.updateCalendar();
    },
    filterByTeacher(newFilterByTeacher) {
      this.loading = true;
      if (this.stateReady) this.updateCalendar();
    },
    filterByClassType(newFilterByClassType) {
      this.loading = true;
      if (this.stateReady) this.updateCalendar();
    },
    filterBySessionType(newfilterBySessionType) {
      this.loading = true;
      if (this.stateReady) this.updateCalendar();
    },
    filterByBranch(newFilterByBranch) {
      this.loading = true;
      if (this.stateReady) this.updateCalendar();
    },
    async classesCounter() {
      this.updateCalendar();
    },
    branchName() {
      this.updateCalendar();
    },

  },
};
</script>

<style lang="stylus">

.cd-schedule--loading
  opacity .3

.fadein-enter-active
  transition .5s

.fadein-enter
  opacity 0

.md-datepicker.calendar-datepicker
  .md-clear
    display none !important

</style>

<style lang="scss" scoped>


.calendar {
  margin-top: 30px;
}

.cal-top {
  background: #f4f4f4;
  text-transform: uppercase;
  padding: 10px;
  line-height: 14.4px;
  z-index: 2;
  @media (min-width: 768px) {
    margin-right: 10px;
  }

  span {
    font-size: 12px;
  }
}

.class-items {
  display: flex
}

.class-item {
  padding: 20px 10px !important;
  border-bottom: 1px solid #cecece;
  font-size: 12px;
  cursor: pointer;
  overflow: hidden;
  line-height: 16.8px;
  transition: background .15s;
  -webkit-font-smoothing: antialiased;
  @media (min-width: 768px) {
    margin-right: 10px;
  }

  &:last-of-type {
    margin-bottom: 20px;
  }

  &:hover {
    background: #e7e7e7 !important;

    .class-color {
      width: 8px;
    }
  }
}

.current-day {
  .cal-top {
    background: #555 !important;
    color: #fff;
    @media (min-width: 768px) {
      margin-right: 10px;
    }
  }
}

.class-color {
  display: block;
  height: 8px;
  width: 35px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: width .3s ease;
}

.fadein-enter-active {
  transition: .5s;
}

.fadein-enter {
  opacity: 0;
}

.cd-schedule--loading {
  opacity: .3;
}

.fadein-enter-active {
  transition: .5s;
}

.fadein-enter {
  opacity: 0;
}

.md-datepicker.calendar-datepicker {
  .md-clear {
    display: none !important;
  }
}

.no-border {
  box-shadow: none;
}

.slide-fade-enter-active {
  transition: all .3s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-active {
  transition: all .2s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

.modal__teacher--info .icon-arrowdown {
  font-size: .8em
}

</style>
