<template>

  <div>

    <loadingspinner v-if="loading" :centered="true"></loadingspinner>

    <div :class="{ 'cd-schedule--loading': loading }" class="yogo-calendar-list">

      <ul class="yogo-flex-column wrap mt2 class-items width--100" ref="calendarWidth">
        <li
          class="events-group yogo-tac-md" v-for="dayIndex in [0,1,2,3,4,5,6]"
          :class="{'current-day' : momentDay(dayIndex).isSame(moment(),'day'), 'before-today' : momentDay(dayIndex).isBefore(moment(),'day')}"
        >
          <div class="cal-top sticky">
            <div>
              <span>
                {{ weekdayName(dayIndex) }}
              </span>
            </div>
            <div class="bold">
              {{ weekdayShortDate(dayIndex) }}
            </div>
          </div>

          <ul>

            <li
              class="class-item yogo-tac-md"
              :class="{'class-has-started' : calendarItem.class_has_started, 'class-cancelled':calendarItem.cancelled }"
              v-for="(calendarItem, calendarItemIdx) in combinedClassesAndEventsForDay(dayIndex)"
              :style="getClassInlineStyle(calendarItem, calendarItemIdx)"
              :ref="'calendarItem_' + dayIndex + '_' + calendarItemIdx"
            >

              <a @click="onCalendarItemClick(calendarItem)" v-if="calendarItem.class_type">

                <div v-if="calendarItem.cancelled" class="mb10">
                  {{ ( calendarItem.cancelled ? $t('global.cancelled') : '') }}
                </div>

                <span class="class-color m0a-md" :style="getClassBarInlineStyle(calendarItem)"></span>
                <TwoCirclesIcon
                  v-if="calendarItem.class_series_types.length"
                  class="yogo-mb-2 m0a-md"
                >
                </TwoCirclesIcon>

                <div class="mb-1">
                  {{ dbTimeToHumanTime(calendarItem.start_time) }} - {{ dbTimeToHumanTime(calendarItem.end_time) }}
                </div>

                <div class="ttu bold event-name mb-1">
                  {{ ( calendarItem.class_type ? calendarItem.class_type.name : '' ) }}
                </div>

                <div>
                  {{ calendarItem.subtitle }}
                </div>

                <div class="bold mb-1" v-if="calendarItem.branchName && !branchName">
                  {{ calendarItem.branchName }}
                </div>

                <div class="bold mb-1" v-if="client.settings.calendar_show_room && calendarItem.room">
                  {{ calendarItem.room.name }}
                </div>

                <div v-for="teacher in calendarItem.teachers" class="small">
                  {{ teacher.first_name }}
                </div>


                <div class="justify-end yogo-flex-column flex-grow">
                  <div
                    v-if="!calendarItem.cancelled"
                    class="bold"
                  >
                    <br>
                    {{ calendarItem.capacity_text }}
                    <span v-if="calendarItem.waiting_list_text">
                      <br>
                      {{ calendarItem.waiting_list_text }}
                    </span>
                  </div>

                  <ClassAction
                    :classItem="calendarItem"
                    @signUpForClass="signUpForClass"
                    @signUpForWaitingList="signUpForWaitingList"
                    @signUpForLivestream="signUpForLivestream"
                    @startLivestream="startLivestream"
                    @openClassDialog="openClassDialog"
                    align="center"
                    customClass="fs-lg"
                    buttonClass="mb-0"
                    :widgetOnlyLivestream="widgetOnlyLivestream"
                  ></ClassAction>
                </div>
              </a>

              <!-- event -->

              <a v-else @click="onCalendarItemClick(calendarItem)">

                <span class="class-color m0a-md" :style="getEventTimeSlotInlineStyle(calendarItem)"></span>

                <div class="mb-1">
                  {{ dbTimeToHumanTime(calendarItem.start_time) }} - {{ dbTimeToHumanTime(calendarItem.end_time) }}
                </div>

                <div class="ttu bold mb-1">
                  {{ calendarItem.event.name }}
                </div>

                <div class="bold" v-if="calendarItem.branchName && !branchName">
                  {{ calendarItem.branchName }}
                </div>

                <div class="bold mb-1" v-if="client.settings.calendar_show_room && getEventTimeSlotRoom(calendarItem)">
                  {{ getEventTimeSlotRoom(calendarItem).name }}
                </div>

                <div v-for="teacher in getEventTimeSlotTeachers(calendarItem)" class="small">
                  {{ teacher.first_name }}
                </div>

                <br>

                <div class="justify-end yogo-flex-column flex-grow">
                  <SignedUpCheckmark
                    v-if="calendarItem.user_is_signed_up_for_event"
                    color="black"
                  >
                  </SignedUpCheckmark>

                  <div v-else-if="calendarItem.event_has_started">
                    {{ $t('global.eventHasStarted') }}
                  </div>
                  <div class="bold" v-else-if="calendarItem.event_is_fully_booked">
                    {{ $t('global.fullyBooked') }}
                  </div>
                  <div class="yogo-btn-secondary mb-0" v-if="showSignupButton(calendarItem)" @click.stop="signUpForEvent(calendarItem.event)">
                    {{ $t('global.signup') }}
                  </div>
                </div>

              </a>

              <!-- end event -->

            </li>

          </ul>
        </li>


      </ul>

    </div>


    <ClassDialog
      :showClassDialog.sync="showClassDialog"
      v-if="dialogClass"
      :dialogClass="dialogClass"
      @signupsChanged="updateCalendar"
      @startLivestream="startLivestream"
      :widgetOnlyLivestream="widgetOnlyLivestream"
    ></ClassDialog>

    <ClassSignup
      :user="user"
      ref="classSignupComponent"
      @classSignupSuccess="updateCalendar"
      @classSignoffSuccess="updateCalendar"
      @classSignupFailure="updateCalendar"
      @classWaitingListSignupSuccess="updateCalendar"
      @classWaitingListSignoffSuccess="updateCalendar"
      @classLivestreamSignupSuccess="updateCalendar"
      @classLivestreamSignoffSuccess="updateCalendar"
    >
    </ClassSignup>

    <EventDialog
      :showEventDialog.sync="showEventDialog"
      v-if="dialogEventTimeSlot"
      :dialogEventTimeSlot="dialogEventTimeSlot"
      @signupsChanged="updateCalendar"
    ></EventDialog>

    <EventSignup
      :user="user"
      ref="eventSignupComponent"
      @eventSignupSuccess="updateCalendar"
    ></EventSignup>

  </div>


</template>

<script>
  import ScheduleBase from "./ScheduleBase"
  import ClassAction from "./ClassAction"
  import TwoCirclesIcon from "@/graphics/icons/TwoCirclesIcon";

  import LoadingSpinner from '../LoadingSpinner.vue'

  import YogoFrontend from '../../gateways/YogoFrontend'

  import {mapActions, mapGetters} from 'vuex'

  import LoginStatus from '../LoginStatus.vue'

  import Imgix from '@/services/Imgix'
  import SignedUpCheckmark from "../../graphics/SignedUpCheckmark"

  import _fill from 'lodash/fill'
  import _debounce from 'lodash/debounce'

  export default {
    extends: ScheduleBase,
    mixins: [Imgix],
    components: {
      loadingspinner: LoadingSpinner,
      loginstatus: LoginStatus,
      ClassAction,
      SignedUpCheckmark,
      TwoCirclesIcon
    },
    data() {
      return {
        dateLabel: 'dato',
        showTeacherInfo: [],

        YogoFrontend: YogoFrontend,

        active: false,

        rowMinHeights: [],
        debouncedAdjustCalendarItemHeightsFunction: null,
        debouncedGetCalendarParentWidth: null
      }
    },
    props: [
      'branchName',
      'date',
    ],
    computed: {
      ...mapGetters([
        'userIsLoggedIn',
        'userName',
        'client',
        'user',
        'stateReady',

        'classesCounter'
      ]),
    },

    mounted: function () {

      if (this.stateReady) this.updateCalendar()

      this.debouncedAdjustCalendarItemHeightsFunction = _debounce(this.adjustCalendarItemHeights, 100)
      window.addEventListener('resize', this.debouncedAdjustCalendarItemHeightsFunction)
      this.adjustCalendarItemHeights()
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.debouncedAdjustCalendarItemHeightsFunction)
      window.removeEventListener('resize', this.debouncedGetCalendarParentWidth)
    },

    methods: {
      ...mapActions('calendarFilters', ['setFilter']),
      toggle() {
        this.active = !this.active
      },



      getCalendarItemInlineStyle(calendarItem) {
        if (calendarItem.class_type) {
          return this.getClassInlineStyle(calendarItem)
        } else {
          return this.getEventTimeSlotInlineStyle(calendarItem)
        }
      },

      getClassInlineStyle(classItem, rowIdx) {
        let style = {}
        if (classItem.cancelled) {
          style.opacity = '.5'
        }
        style['min-height'] = (this.rowMinHeights[rowIdx] ? this.rowMinHeights[rowIdx] : 0) + 'px'
        return style
      },

      getClassBarInlineStyle(calendarItem) {
        let style = {}
        if (calendarItem.cancelled) {
          style.background = calendarItem.class_has_started ? this.ColorLuminance(calendarItem.class_type.color, -0.3) : calendarItem.class_type.color
        } else {
          style.background = calendarItem.class_has_started ? this.ColorLuminance(calendarItem.class_type.color, -0.3) : calendarItem.class_type.color
        }
        return style
      },


      getEventTimeSlotInlineStyle(eventTimeSlot) {
        return {
          background: eventTimeSlot.event && eventTimeSlot.event.event_group && eventTimeSlot.event.event_group.color ? eventTimeSlot.event.event_group.color : (
            this.client.settings ? this.client.settings.theme_primary_color : '#FFFFFF'
          ),
        }
      },

      adjustCalendarItemHeights() {
        const calendarItems = []
        let maxCalendarItemsInDay = 0
        let calendar = this.$refs.calendarWidth
        for (let weekdayIdx = 0; weekdayIdx <= 6; weekdayIdx++) {
          calendarItems[weekdayIdx] = this.combinedClassesAndEventsForDay(weekdayIdx)
          maxCalendarItemsInDay = Math.max(maxCalendarItemsInDay, calendarItems[weekdayIdx].length)
        }

        this.rowMinHeights = _fill(Array(maxCalendarItemsInDay), 0)
        this.$forceUpdate()

        if (calendar.clientWidth < 700) return

        for (let rowIdx = 0; rowIdx < maxCalendarItemsInDay; rowIdx++) {
          this.rowMinHeights[rowIdx] = 0
          for (let weekdayIdx = 0; weekdayIdx <= 6; weekdayIdx++) {
            if (calendarItems[weekdayIdx][rowIdx]) {
              this.rowMinHeights[rowIdx] = Math.max(
                this.rowMinHeights[rowIdx],
                this.$refs['calendarItem_' + weekdayIdx + '_' + rowIdx][0].offsetHeight,
              )
            }
          }
        }
        this.$forceUpdate()
      },


      ColorLuminance(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '')
        if (hex.length < 6) {
          hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
        }
        lum = lum || 0

        // convert to decimal and change luminosity
        var rgb = "#", c, i
        for (i = 0; i < 3; i++) {
          c = parseInt(hex.substr(i * 2, 2), 16)
          c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
          rgb += ("00" + c).substr(c.length)
        }

        return rgb
      },

    },


  }
</script>

<style lang="stylus">

  .cd-schedule--loading
    opacity .3

  .fadein-enter-active
    transition .5s

  .fadein-enter
    opacity 0

  .md-datepicker.calendar-datepicker
    .md-clear
      display none !important

</style>

<style lang="scss" scoped>

  .calendar {
    margin-top: 30px;
  }

  .cal-top {
    background: #f4f4f4;
    text-transform: uppercase;
    padding: 10px;
    line-height: 14.4px;
    z-index: 2;
    @media (min-width: 768px) {
      margin-right: 10px;
    }

    span {
      font-size: 12px;
    }
  }

  .class-items {
    display: flex
  }

  .class-item {
    padding: 20px 10px !important;
    border-bottom: 1px solid #cecece;
    font-size: 12px;
    cursor: pointer;
    overflow: hidden;
    line-height: 16.8px;
    transition: background .15s;
    -webkit-font-smoothing: antialiased;
     display: flex;
    @media (min-width: 768px) {
      margin-right: 10px;
    }

    p {
      margin: 5px 0;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }

    &:hover {
      background: #e7e7e7 !important;

      .class-color {
        width: 8px;
      }
    }
  }

  .current-day {
    .cal-top {
      background: #555 !important;
      color: #fff;
      @media (min-width: 768px) {
        margin-right: 10px;
      }
    }
  }

  .class-action {
    display: flex;
    flex-direction: column;
  }

  .yogo-calendar-list li a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .class-has-started {
    opacity: .5;
  }

  .class-color {
    display: block;
    height: 8px;
    width: 35px;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: width .3s ease;
  }

  .fadein-enter-active {
    transition: .5s;
  }

  .fadein-enter {
    opacity: 0;
  }

  .cd-schedule--loading {
    opacity: .3;
  }

  .md-datepicker.calendar-datepicker {
    .md-clear {
      display: none !important;
    }
  }


</style>
