<template>
  <div class="yogo-widget">
    <div class="yogo-class-pass-button" data-yogo-parsed>

      <span v-if="loading">...</span>

      <div v-else>
        <button
          disabled
          v-if="errorMessage"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses" ref="button1">
          {{ errorMessage }}
        </button>

        <button
          disabled
          v-else-if="classPassType && classPassType.max_number_per_customer_already_used"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses">
          {{ $t('global.cantBuyMoreOfType') }} {{ classPassType.pass_type === 'fixed_count' ?
          $t('global.classPass') :
          $t('global.seasonPass') }}
        </button>

        <button
          v-else-if="classPassType"
          @click="buttonClick"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses"
        >
          {{ ctaWithDefault }}
        </button>

        <button
          v-else
          disabled
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses">
          Oops, unfamiliar error...
        </button>

      </div>
    </div>
  </div>
</template>

<script>

  import YogoApi from '../gateways/YogoApi'
  import YogoFrontend from '../gateways/YogoFrontend'
  import {mapGetters} from 'vuex'

  export default {
    data: function () {
      return {
        loading: true,

        errorMessage: '',

        classPassType: null,
      }
    },
    props: ['classPassTypeId', 'cta', 'cssClasses'],
    computed: {
      ...mapGetters([
        'user',
        'classesCounter',
      ]),
      normalizedCssClasses() {
        return this.cssClasses.replace(/,/g, ' ').replace(/ {2,}/g, ' ')
      },
      ctaWithDefault() {
        return this.cta || this.$t('global.buy')
      },
    },

    created() {
      this.getClassPassTypeFromApi()
    },

    mounted() {
      this.updateButtonClasses()
    },

    watch: {
      classesCounter() {
        this.getClassPassTypeFromApi()
      },
      user() {
        this.getClassPassTypeFromApi()
      }
    },

    methods: {

      async getClassPassTypeFromApi() {

        if (!this.classPassTypeId) {
          this.errorMessage = this.$t('priceButton.pleaseSpecifyClassPassTypeId')
          return
        }

        this.loading = true

        this.classPassType = (await YogoApi.get(
          '/class-pass-types' +
          '?id=' + this.classPassTypeId +
          '&populate[]=max_number_per_customer_already_used' +
          '&populate[]=user_can_buy',
        ))[0]
        if (!this.classPassType) {
          this.loading = false
          this.errorMessage = this.$t('priceButton.invalidClassPassTypeId')
          return
        }

        this.loading = false

        this.updateButtonClasses()

      },

      async buttonClick() {
        if (this.user) {

          const classPassType = await YogoApi.get('/class-pass-types?id=' + this.classPassTypeId)

          if (parseInt(classPassType.price) === 0) {

            await YogoApi.post('/class-passes', {
              user: this.user.id,
              class_pass_type: classPassType.id,
            })

            this.alertDialogText = this.$t('global.isCreatedOnProfile', {
              classPassTypeName: classPassType.name,
            })
            this.alertDialogConfirmText = 'Ok'
            this.showAlertDialog = true

            return

          }


          let cartItem = {
            item_type: 'class_pass_type',
            item_id: this.classPassTypeId,
            user: this.user.id,
          }

          await YogoApi.post('/cart-items', cartItem)

          YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'))

        } else {

          const cartItem = {
            itemType: 'class_pass_type',
            itemId: this.classPassTypeId,
          }

          const loginWithCartRouteParams = {
            cartItemJson: JSON.stringify(cartItem),
          }
          YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(loginWithCartRouteParams)))
        }
      },

      updateButtonClasses() {
        if (this.cssClasses) {
          this.$nextTick(() => {
            if (this.$refs.button && this.$refs.button.$el) {
              this.$refs.button.$el.className = this.normalizedCssClasses
            }
          })
        }
      },
    },

  }
</script>
