<template>
  <div class="cursor-pointer h-20 svg-icon">
    <svg
      width="auto"
      height="auto" 
      viewBox="0 0 361 790" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <title>yoga</title>
    <g id="Page-1" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="yoga" transform="translate(8.000000, 8.000000)" stroke="currentColor">
            <path d="M154.94,176.11 C114.8,219 85.18,272.81 69.7,330.85 C68.85,334.06 68.19,337.98 70.42,340.33 C72.65,342.68 75.97,342.1 78.87,341.49 L129.47,331 C144.83,327.8 160.79,324.33 173.23,314.3 C190.93,300.04 197.85,275.74 203.75,253.08" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
            <path d="M258.51,176.11 C298.65,219.03 328.27,272.81 343.75,330.85 C344.6,334.06 345.26,337.98 343.03,340.33 C340.8,342.68 337.49,342.1 334.58,341.49 L284,331 C268.64,327.8 252.68,324.33 240.24,314.3 C222.52,300 215.6,275.69 209.7,253" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
            <path d="M159.82,331.16 C163.48,351.57 148.22,362.97 134.72,368.9 C109.31,380.06 83.07,389.42 59.43,404.55 C35.79,419.68 14.53,441.18 3,472.11 C0.88,477.76 -0.51,485.84 3.71,491.32 C6.08,494.41 9.44,495.14 12.38,496.11 C62.14,512.42 131.86,509.19 166.03,488.43 C173.14,512.97 175.79,531.02 173.81,556.59" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
            <path d="M206.42,443.61 C206.16,547.94 205.9,669.27 205.64,773.61" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
            <path d="M275.87,377.66 C281.66,411.92 285.22,485.95 266.43,572.54 C250.67,645.11 243.95,693.54 242.67,773.61" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
            <path d="M107.7,292.89 C123.89,290.11 137.04,288.31 151.7,283.39 L151.33,282.55 C155.2,257.4 170.19,234.41 191.07,221.61" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
            <path d="M306.54,292.89 C290.18,290.11 277.19,288.31 262.54,283.39 L262.91,282.55 C259,257.4 244,234.41 223.15,221.61" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
            <ellipse id="Oval" stroke-width="15.840792" transform="translate(208.569700, 67.602300) rotate(-0.572939) translate(-208.569700, -67.602300) " cx="208.5697" cy="67.6023" rx="48.2024099" ry="67.0533524"></ellipse>
            <path d="M62.42,467.590077 C89.0803989,467.61792 115.198002,460.056648 137.72,445.79" id="Path" stroke-width="15.84" stroke-linecap="square" stroke-linejoin="round"></path>
        </g>
    </g>
</svg>
  </div>
</template>

<script>

  export default {}

</script>

<style lang="scss">
  .svg-icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }
</style>