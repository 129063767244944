<template>
  <div></div>
</template>

<script>

import { encode } from 'he';

export default {
  methods: {
    encodeHtmlEntities(string) {
      console.log(string);
      console.log(encode(string));
      return encode(string);
    },
  },
};

</script>
