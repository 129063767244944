<template>
  <div class="header__logo--client" v-if="user">
    <img :src="Imgix(user.image.filename, {w:100, h:100, fit:'crop'})" alt="" v-if="user.image"/>
    <div class="flex--column">
      <p>{{ $t('global.loggedInAs') }} {{ user.first_name + ' ' + user.last_name }}</p>
      <p>
        <span class="text--gray pointer" @click="openProfile">{{ $t('global.myProfile') }}</span> |
        <span class="text--gray pointer" @click="logout">{{ $t('global.signOut') }}</span>
      </p>
    </div>
  </div>
</template>


<script>

    import YogoFrontend from '../gateways/YogoFrontend'
    import {mapGetters, mapActions} from 'vuex'
    import Imgix from '../services/Imgix'

    export default {
      mixins: [Imgix],
        computed: {
            ...mapGetters([
                'userIsLoggedIn',
                'userName',
                'client',
                'user',
                'stateReady'
            ])
        },
        methods: {
            openProfile() {
                YogoFrontend.open('/system-route/MyProfile/' + encodeURIComponent('{}'))
            },
            logout() {
                this.$store.dispatch('logout')
            }
        }
    }
</script>
