<template>
  <div>
    <div ref="textContent" :class="{ expanded: isExpanded, collapsed: !isExpanded }"
         class="text-content">
      <nl2br :text="text" tag="div"/>
    </div>
    <button
        v-if="isOverflowing"
        @click="toggleExpanded"
        class="yogo-btn yogo-btn-readmore"
    >
      {{ isExpanded ? $t('global.readLess') : $t('global.readMore') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      isOverflowing: false,
      intersectionObserver: null,
    };
  },
  mounted() {
    this.checkOverflow();

    // Check text height again if prices are originally hidden, for instance with display:none, but then shown.
    try {
      this.intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.checkOverflow();
          }
        });
      });

      this.intersectionObserver.observe(this.$refs.textContent);
    } catch (e) {
    }
  },
  methods: {
    checkOverflow() {
      const textContent = this.$refs.textContent;
      this.isOverflowing = textContent.scrollHeight > 310;
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
.text-content {
  max-height: 310px;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.text-content.expanded {
  max-height: none;
}

.text-content.collapsed {
  max-height: 310px;
}

.yogo-btn-readmore {
  text-decoration: underline;
}
</style>
