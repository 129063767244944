<template>
  <div class="yogo-widget">
    <div class="theme--frame-box">

      <widgetPadding>

        <loadingspinner v-if="loading"></loadingspinner>

        <div v-else>

          <div class="space8"></div>
          <loginstatus></loginstatus>

          <div class="yogo-flex yogo-flex-wrap">

            <div v-for="(teacher, teacherIdx) in teachers" class="yogo-teacher yogo-w-full" :key="'teacher_' + teacher.id">
              <div class="yogo-py-8 yogo-border-b">
                <div class="yogo-mb-4">
                <div class="yogo-flex-md yogo-px-4">
                  <div class="yogo-w-6-12 yogo-m-auto yogo-w-2-12-lg yogo-pr-8-md yogo-mb-4">
                    <div v-if="teacher.image">
                    <img
                      class="yogo-rounded-full yogo-mb-4 yogo-mb-0-md"
                      :src="Imgix(teacher.image.filename, {h:960,w:960, fit:'crop'})"
                      alt=""
                      v-if="teacher.image"
                      >
                    </div>
                    <div class="yogo-mb-4 yogo-mb-0-md yogo-p-8" v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="yogo-w-full yogo-h-full">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                      </svg>
                    </div>
                  </div>

                  <div class="yogo-w-full yogo-w-9-12-lg yogo-pr-8-md yogo-text-center yogo-text-left-md">
                    <div class="yogo-flex yogo-flex-col">
                      <div class="yogo-mb-4">
                        <h3 class="yogo-font-bold yogo-mb-2">
                          {{ teacher.first_name }} {{ teacher.last_name}}
                        </h3>
                        <nl2br tag="div" :text="teacher.teacher_description"></nl2br>
                      </div>

                    <div
                      @click="toggleScheduleForTeacher(teacherIdx)"
                      class="yogo-font-bold yogo-underline-offset-4 yogo-underline yogo-cursor-pointer"
                      >
                      {{ calendars[teacherIdx] ? $t('global.HideSchedule') : $t('global.ShowSchedule') }}
                    </div>
                  </div>
                </div>

                </div>
                <div class="yogo-m-4-minus">
                  <component
                    :is="calendars[teacherIdx]"
                    :widgetTeachers="teacher.id"
                    :widgetStartDate="widgetStartDate"
                  />
                </div>
              </div>
              </div>

            </div>

          </div>
        </div>

      </widgetPadding>

    </div>
  </div>
</template>

<script>

import LoadingSpinner from './LoadingSpinner.vue';
import YogoApi from '../gateways/YogoApi';
import YogoFrontend from '../gateways/YogoFrontend';
import { mapGetters } from 'vuex';
import WidgetPadding from './WidgetPadding';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

import LoginStatus from './LoginStatus.vue';
import Imgix from '../services/Imgix';
import currencyFormatters from '@/mixins/currencyFormatters';
import Schedule from '@/components/Schedule';
import qs from 'qs';


export default {
  name: 'Teachers',
  components: {
    loadingspinner: LoadingSpinner,
    loginstatus: LoginStatus,
    widgetPadding: WidgetPadding,
    Schedule,
  },
  mixins: [Imgix],
  props: ['widgetStartDate', 'teacherIds'],
  data() {
    return {
      teachers: [],
      calendars: [],
      loading: true,
      initialized: false,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'client',
      'stateReady',
      'productResetCounter',
    ]),
  },
  mounted: function () {
    this.fetchTeachers();
  },
  watch: {
    stateReady(newReadyState) {
      if (newReadyState && this.initialized) {
        this.loading = false;
      }
    },
  },
  methods: {
    async fetchTeachers() {

      this.loading = true;

      const query = {
        teacherWidget: 1,
        populate: ['image'],
      };

      if (this.teacherIds && this.teacherIds.length > 0) {
        query.id = this.teacherIds;
      }

      const queryString = qs.stringify(query);

      this.teachers = await YogoApi.get(`/users?${queryString}`);
      this.teachers = _sortBy(this.teachers, ['first_name', 'last_name']);
      this.calendars = Array(this.teachers.length);

      this.initialized = true;
      if (this.stateReady) {
        this.loading = false;
      }
    },
    toggleScheduleForTeacher(idx) {
      if (this.calendars[idx]) {
        this.$set(this.calendars, idx, null);
      } else {
        this.$set(this.calendars, idx, 'Schedule');
      }
    },

  },
};

</script>
