<template>
  <div class="yogo-widget">
    <div class="theme--frame-box">

      <widgetPadding>

        <loginstatus></loginstatus>

        <loadingspinner v-if="loading"></loadingspinner>

        <div v-else>
          <div v-for="eventGroup in eventGroups" :key="'event_group_' + eventGroup.id">
            <template v-if="eventGroup.events.length">
              <div class="space8"></div>

              <div class="eventsection">

                <div class="flex__grid--thirds align--top">
                  <div class="flex__1-3--margin">
                    <img :src="Imgix(eventGroup.image.filename, {w:320, h:320, fit: 'crop'})" alt=""
                         v-if="eventGroup.image">
                  </div>
                  <div class="space4"></div>
                  <div class="flex__1-3--margin">
                    <div class="yogo--h3">{{ eventGroup.name }}</div>
                    <nl2br tag="div" :text="eventGroup.description"></nl2br>
                  </div>
                  <div class="space4"></div>
                  <div class="flex__1-3--margin">
                    <ul class="widget__list--standard">
                      <li v-for="event in eventGroup.events" :key="'event_' + event.id">

                        <div class="upper bold modal-trigger pointer"
                             @click.prevent="openEventDialog(event)">
                          {{ event.name }}
                        </div>

                        <template v-if="event.use_time_slots">
                          <p v-for="timeSlot in event.time_slots" :key="'time_slot_'+ timeSlot.id">
                            {{
                              formatDate(timeSlot.date + ' ' + timeSlot.start_time,
                                  { weekday: true, time: true, capitalize: true })
                            }}
                            - {{ formatTime(timeSlot.end_time) }}
                          </p>
                        </template>

                        <nl2br
                            v-if="!event.use_time_slots"
                            tag="p"
                            :text="event.time_freetext"
                        />

                        <div v-if="event.room">
                          {{ event.room.name }}
                        </div>

                        <p :class="{bold: !event.has_member_discount, strikethrough: event.has_member_discount}">
                          {{ $t('global.price') }}: {{ formatCurrency(event.price) }}
                        </p>
                        <p v-if="event.has_member_discount" class="bold">
                          {{ $t('global.MemberPrice') }}:
                          {{ formatCurrency(event.price_with_member_discount) }}
                        </p>

                        <div class="space2"></div>
                        <div
                            class="yogo-flex yogo-justify-between yogo-items-center yogo-w-full yogo-flex-wrap">
                          <a href="#" class="bold yogo-mb-2 yogo-mr-2"
                             @click.prevent="openEventDialog(event)">
                            {{ $t('global.readMore') }}
                          </a>
                          <div
                              class="yogo-flex yogo-mb-2 yogo-justify-between yogo-w-full yogo-flex-wrap">
                            <div :class="{'yogo-w-full': showInstallmentPaymentOption(event)}">
                              <YogoNumberInput
                                  v-if="client.settings.customer_can_buy_multiple_seats_for_event && event.price"
                                  class="yogo-mr-4 yogo-max-w-100 yogo-mb-2"
                                  v-model="eventBuyCountMap[event.id]"
                                  :max="event.seats - event.signup_count"
                              ></YogoNumberInput>
                            </div>
                            <button
                                class="yogo-btn yogo-btn-primary yogo-mb-2"
                                disabled
                                v-if="isSignedUpForEvent(event)">
                              {{ $t('global.youAreSignedUp') }}
                            </button>
                            <button
                                class="yogo-btn yogo-btn-primary yogo-mb-2"
                                disabled
                                v-else-if="event.signup_count >= event.seats">
                              {{ $t('global.fullyBooked') }}
                            </button>
                            <button
                                class="yogo-btn yogo-btn-primary yogo-mb-2"
                                v-else
                                @click.prevent="signUpForEvent(event, eventBuyCountMap[event.id])">
                              {{ $t('global.signup') }}
                            </button>
                            <button
                                v-if="showInstallmentPaymentOption(event)"
                                class="yogo-btn yogo-btn-primary yogo-mb-2"
                                @click.prevent="signUpForEvent(event, 1, true)"
                            >
                              {{ $t('event.SignUpPayInInstallments') }}
                            </button>
                          </div>
                          <div>
                            <a
                                v-if="showInstallmentPaymentOption(event)"
                                href="#"
                                @click.prevent="showInstallments(event)"
                                class="yogo-underline"
                            >
                              {{ $t('event.ShowInstallments') }}
                            </a>
                          </div>
                        </div>
                        <div class="space4"></div>
                      </li>
                    </ul>
                    <div class="space4"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="space8"></div>
        </div>

      </widgetPadding>


      <div class="space8"></div>

      <md-dialog :md-active.sync="showEventDialog" v-if="dialogEvent">
        <a class="close-signup-modal" @click="showEventDialog = false">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                 stroke-linejoin="round" class="feather feather-x">
                 <line x1="18" y1="6" x2="6"
                       y2="18"></line><line x1="6"
                                            y1="6"
                                            x2="18"
                                            y2="18">
                </line>
            </svg>
          </span>
        </a>
        <div class="yogo-modal-content">
          <div class="yogo-flex-md yogo-pt-2">
            <div class="yogo-flex-1">
              <div class="yogo--h1 yogo-mb-2 yogo-mr-2">{{ dialogEvent.name }}</div>
              <DialogTeacherInfo :teachers="getTeachers(dialogEvent)"></DialogTeacherInfo>
            </div>

            <div class="yogo-flex yogo-flex-1 yogo-items-start">
              <img
                  :src="Imgix(dialogEvent.image.filename, {w:900, h:600, fit: 'crop'})"
                  alt=""
                  v-if="dialogEvent.image"
                  class="modal__image--standard"
              />
            </div>
          </div>

          <div class="yogo-line yogo-my-4"></div>
          <template v-if="dialogEvent.use_time_slots">
            <div v-for="timeSlot in dialogEvent.time_slots"
                 :key="'dialog_time_slot_' + timeSlot.id" class="mb-1">
              <span class="bold">{{
                formatDate(timeSlot.date + ' ' + timeSlot.start_time,
                    { weekday: true, time: true, capitalize: true })
              }} - {{
                formatTime(timeSlot.end_time)
                }}</span>
              <span v-if="dialogEvent.use_time_slots && dialogEvent.time_slots_have_separate_rooms_and_teachers"
              class="block">
                <span
                  v-if="timeSlot.room"
                  class="yogo-flex yogo-items-center"
                >
                  <LocationIcon class="mr-1 pr-1 inline-block"></LocationIcon>
                  {{ timeSlot.room.name }} <span v-if="timeSlot.room.branch">({{ timeSlot.room.branch.name }})</span>
                  - {{ timeSlot.teachers.map(t => `${t.first_name} ${t.last_name}`.trim()).join(', ') }}
                </span>
              </span>
            </div>
          </template>
          <div class="bold" v-else>{{ dialogEvent.time_freetext }}</div>
          <div class="yogo-line yogo-my-4"></div>
          <nl2br tag="div" :text="dialogEvent.description ? dialogEvent.description : ''"></nl2br>

          <div class="yogo-line yogo-my-4"></div>

        </div>
        <md-dialog-actions>
          <button
              class="yogo-btn"
              disabled v-if="isSignedUpForEvent(dialogEvent)"
          >
            {{ $t('global.youAreSignedUp') }}
          </button>
          <div
              class="yogo-flex yogo-items-center"
              v-else-if="dialogEvent.signup_count < dialogEvent.seats"
          >
            <YogoNumberInput
                v-if="client.settings.customer_can_buy_multiple_seats_for_event && dialogEvent.price"
                class="yogo-mr-4"
                v-model="eventBuyCountMap[dialogEvent.id]"
                :max="dialogEvent.seats - dialogEvent.signup_count"
            ></YogoNumberInput>
            <button
                class="yogo-btn yogo-btn-primary"
                @click="signUpForEvent(dialogEvent)"
            >
              {{ $t('global.signup') }}
            </button>
            <button
                v-if="dialogEvent.installment_payments_enabled && dialogEvent.number_of_installments_left >= 1"
                class="yogo-btn yogo-btn-primary"
                @click.prevent="signUpForEvent(dialogEvent, 1, true)"
            >
              {{ $t('event.SignUpPayInInstallments') }}
            </button>
          </div>
          <button
              class="yogo-btn yogo-btn-primary"
              disabled v-else
          >
            {{ $t('global.fullyBooked') }}
          </button>

        </md-dialog-actions>

      </md-dialog>

      <md-dialog :md-active.sync="showInstallmentDialog" v-if="installmentDialogEvent">
        <md-dialog-title>{{
            installmentDialogEvent ? installmentDialogEvent.name : ''
          }}
        </md-dialog-title>
        <md-dialog-content>
          <div>
            <h3>{{ $t('event.Installments') }}</h3>
            <div class="inline-block min-w-full py-2 align-middle">
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                  <tr>
                    <th class="py-3.5 pl-4 yogo-pr-4 yogo-text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      {{ $t('global.Date') }}
                    </th>
                    <th class="px-3 py-3.5 yogo-text-right text-sm font-semibold text-gray-900">{{
                        $t('global.Amount')
                      }}
                    </th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="(row, idx) in dialogInstallmentRows" :key="'installment_' + idx">
                    <td class="whitespace-nowrap yogo-text-left py-4 pl-4 yogo-pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                      {{ row.date }}
                    </td>
                    <td class="whitespace-nowrap yogo-text-right py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <span :class="{strikethrough: row.has_member_discount}">{{
                          row.amount
                        }}</span>
                      <span v-if="row.has_member_discount"><br>{{ row.amount_with_member_discount }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </md-dialog-content>
      </md-dialog>

      <md-snackbar md-position="center" :md-duration="4000"
                   :md-active.sync="showSignedUpForFreeEventSnackbar" md-persistent
      >
        {{ $t('global.signup') }}
        {{ signedUpForFreeEventSnackbarEvent ? signedUpForFreeEventSnackbarEvent.name : '' }}
      </md-snackbar>


    </div>
  </div>
</template>

<script>

import moment from 'moment';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _flatten from 'lodash/flatten';
import _uniqBy from 'lodash/uniqBy';
import YogoApi from '../gateways/YogoApi';
import YogoFrontend from '../gateways/YogoFrontend';
import { mapGetters } from 'vuex';

import LoginStatus from './LoginStatus.vue';
import LoadingSpinner from './LoadingSpinner';
import WidgetPadding from './WidgetPadding';
import Imgix from '../services/Imgix';

import dateTimeFunctions from '../mixins/dateTimeFunctions';
import qs from 'qs';
import ProfileImage from '@/components/ProfileImage';
import currencyFormatters from '@/mixins/currencyFormatters';
import YogoNumberInput from '@/components/ui/YogoNumberInput.vue';
import DialogTeacherInfo from '@/components/DialogTeacherInfo';
import LocationIcon from '@/graphics/icons/LocationIcon.vue';

export default {
  mixins: [Imgix, dateTimeFunctions, currencyFormatters],
  components: {
    ProfileImage,
    loginstatus: LoginStatus,
    loadingspinner: LoadingSpinner,
    widgetPadding: WidgetPadding,
    YogoNumberInput,
    DialogTeacherInfo,
    LocationIcon,
  },
  props: ['eventGroup'],
  data: function () {
    return {
      showTeacherInfo: [{ show: false }, { show: false }, { show: false }, { show: false }],
      eventGroups: [],
      eventBuyCountMap: {},
      loading: false,
      showEventDialog: false,
      dialogEvent: {},

      showInstallmentDialog: false,
      installmentDialogEvent: null,

      showSignedUpForFreeEventSnackbar: false,
      signedUpForFreeEventSnackbarEvent: null,

    };
  },
  computed: {
    ...mapGetters([
      'user',
      'eventSignups',
      'stateReady',
    ]),
    dialogInstallmentRows() {
      // If no event selected, return empty array
      if (!this.installmentDialogEvent) return [];

      // First payment (now)
      const rows = [{
        date: this.$t('global.Now'),
        amount: this.formatCurrency(this.installmentDialogEvent.current_required_paid_amount),
        has_member_discount: this.installmentDialogEvent.has_member_discount,
        amount_with_member_discount: this.installmentDialogEvent.has_member_discount
            ? this.formatCurrency(this.installmentDialogEvent.current_required_paid_amount_with_member_discount)
            : null,
      }];

      // Future payments
      for (
          let i = this.installmentDialogEvent.installments.length - this.installmentDialogEvent.number_of_installments_left;
           i < this.installmentDialogEvent.installments.length;
           i++
      ) {
        rows.push({
          date: this.formatDate(this.installmentDialogEvent.installments[i].date),
          amount: this.formatCurrency(this.installmentDialogEvent.installments[i].amount),
          has_member_discount: this.installmentDialogEvent.installments[i].has_member_discount,
          amount_with_member_discount: this.installmentDialogEvent.installments[i].has_member_discount
              ? this.formatCurrency(this.installmentDialogEvent.installments[i].amount_with_member_discount)
              : null,
        });
      }
      return rows;
    },
  },
  watch: {
    stateReady(readyState) {
      if (readyState) {
        this.updateContentFromApi();
      }
    },
  },
  methods: {
    updateContentFromApi: async function () {

      if (!this.stateReady) return;

      this.loading = true;

      const eventQueryParams = {
        startDate: moment().format('YYYY-MM-DD'),
        populate: [
          'image',
          'teachers',
          'teachers.image',
          'time_slots',
          'time_slots.teachers',
          'time_slots.room',
          'signup_count',
          'room',
          'event_group',
          'number_of_installments_left',
          'current_required_paid_amount',
          'current_required_paid_amount_with_member_discount',
          'price_with_member_discount',
          'installments.amount_with_member_discount',
        ],
      };
      if (this.eventGroup) {
        eventQueryParams.eventGroup = this.eventGroup;
      }
      const eventsQueryString = qs.stringify(eventQueryParams);

      let [events, eventGroups] = await YogoApi.get([
        `/events?${eventsQueryString}`,

        '/event-groups' +
        '?populate[]=image' +
        '&populate[]=teachers',
      ]);

      _each(eventGroups, (eventGroup) => {
            eventGroup.events = _sortBy(
                _filter(
                    events,
                    event => event.event_group && event.event_group.id === eventGroup.id,
                ),
                'start_date',
            );
          },
      );


      this.eventGroups = eventGroups;
      this.loading = false;

    },
    openEventDialog(e) {
      this.dialogEvent = e;
      this.showEventDialog = true;
    },
    async signUpForEvent(event, count = 1, payInInstallments = false) {
      if (this.user) {

        if (parseInt(event.price) === 0) {
          const response = await YogoApi.post('/event-signups', {
            user: this.user.id,
            event: event.id,
          });
          if (response === 'E_EVENT_IS_FULL') {
            alert(this.$t('global.fullyBooked'));
            this.$store.dispatch('updateUser');
            this.updateContentFromApi();
            return;
          }
          if (response === 'E_USER_IS_ALREADY_SIGNED_UP_FOR_EVENT') {
            alert(this.$t('global.youAreAlreadySignedUp'));
            this.$store.dispatch('updateUser');
            return;
          }
          this.showSignedUpForFreeEventSnackbar = true;
          this.signedUpForFreeEventSnackbarEvent = event;
          this.$store.dispatch('updateUser');
          this.updateContentFromApi();
          return;
        }

        Promise.all([...Array(count).keys()].map(async () => {
          // console.log('--------------------------\n', this.user)
          await YogoApi.post('/cart-items', {
            user: this.user.id,
            item_type: payInInstallments ? 'event_first_installment' : 'event',
            item_id: event.id,
          });
        }));
        YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'));

      } else {
        // console.log('----------else----------\n', this.user)

        const loginWithCartRouteParams = {
          cartItemJson: JSON.stringify({
            itemType: payInInstallments ? 'event_first_installment' : 'event',
            itemId: event.id,
            itemCount: count,
          }),
        };
        YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(
            loginWithCartRouteParams)));
      }
    },
    isSignedUpForEvent(event) {
      if (!this.eventSignups) return false;
      // Temporary workaround: (signup.event_id || signup.event) until api is deployed.
      return !!_find(this.eventSignups, signup => (signup.event_id || signup.event) === event.id);
    },
    showInstallmentPaymentOption(event) {
      return event.installment_payments_enabled && event.number_of_installments_left >= 1;
    },
    showInstallments(event) {
      this.installmentDialogEvent = event;
      this.showInstallmentDialog = true;
    },
    getTeachers(event) {
      if (event.use_time_slots && event.time_slots_have_separate_rooms_and_teachers) {
        return _sortBy(_uniqBy(_flatten(event.time_slots.map(timeSlot => timeSlot.teachers)), 'id'), ['first_name','last_name']);
      }
      return event.teachers;
    },
    moment: moment,
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize);
    //this.$forceUpdate();
    this.updateContentFromApi();
  },
};
</script>
