<template>
  <div class="yogo-widget">
    <div class="yogo-event-button" data-yogo-parsed>

      <span v-if="loading">...</span>

      <div v-else>
        <button
          disabled
          v-if="isSignedUpForEvent(event)"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses" ref="button1">
          {{ $t('global.youAreSignedUp') }}
        </button>
        <button
          disabled
          v-else-if="event.signup_count >= event.seats"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses" ref="button2">
          {{ $t('global.fullyBooked') }}
        </button>
        <button
          class="yogo-btn yogo-btn-primary"
          v-else
          @click.prevent="signUpForEvent(event)"
          :class="normalizedCssClasses"
          ref="button3">
          {{ ctaWithDefault }}
        </button>
      </div>

      <md-snackbar md-position="center" :md-duration="4000"
                   :md-active.sync="showSignedUpForFreeEventSnackbar" md-persistent
      >
        {{ $t('global.youAreSignedUp') }} {{ signedUpForFreeEventSnackbarEvent ? signedUpForFreeEventSnackbarEvent.name
        : '' }}
      </md-snackbar>
    </div>
  </div>
</template>

<script>

  import moment from 'moment'
  import _find from 'lodash/find'
  import YogoApi from '../gateways/YogoApi'
  import YogoFrontend from '../gateways/YogoFrontend'
  import {mapGetters} from 'vuex'

  export default {
    name: 'eventbutton',
    data: function () {
      return {
        loading: true,

        event: null,

        showSignedUpForFreeEventSnackbar: false,
        signedUpForFreeEventSnackbarEvent: null,

      }
    },
    props: ['eventId', 'cta', 'cssClasses'],
    computed: {
      ...mapGetters([
        'user',
        'eventSignups',
      ]),
      normalizedCssClasses() {
        return this.cssClasses.replace(/,/g, ' ').replace(/ {2,}/g, ' ')
      },
      ctaWithDefault() {
        return this.cta || this.$t('global.signup')
      },
    },

    created() {
      this.getEventFromApi()
    },

    mounted() {
      this.updateButtonClasses()
    },

    methods: {

      async getEventFromApi() {

        this.loading = true

        this.event = await YogoApi.get(
          '/events/' + this.eventId +
          '?populate[]=time_slots' +
          '&populate[]=signup_count',
        )
        this.loading = false

        this.updateButtonClasses()

      },

      async signUpForEvent(event) {
        if (this.user) {

          if (parseInt(event.price) === 0) {
            const response = await YogoApi.post('/event-signups', {
              user: this.user.id,
              event: event.id,
            })
            if (response === 'E_EVENT_IS_FULL') {
              alert(this.$t('global.fullyBooked'))
              this.$store.dispatch('updateUser')
              this.getEventFromApi()
              return
            }
            if (response === 'E_USER_IS_ALREADY_SIGNED_UP_FOR_EVENT') {
              alert(this.$t('global.youAreAlreadySignedUp'))
              this.$store.dispatch('updateUser')
              return
            }
            this.showSignedUpForFreeEventSnackbar = true
            this.signedUpForFreeEventSnackbarEvent = event
            this.$store.dispatch('updateUser')
            this.getEventFromApi()
            return
          }

          this.event = await YogoApi.get('/events/' + event.id + '?populate[]=signup_count')
          if (this.event.signup_count >= this.event.seats) {
            alert(this.$t('global.fullyBooked'))
            return
          }

          await YogoApi.post('/cart-items', {
            user: this.user.id,
            item_type: 'event',
            item_id: event.id,
          })
          YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'))

        } else {

          const loginWithCartRouteParams = {
            cartItemJson: JSON.stringify({
              itemType: 'event',
              itemId: event.id,
            }),
          }
          YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(loginWithCartRouteParams)))
        }
      },
      isSignedUpForEvent(event) {
        if (!this.eventSignups) return false
        return !!_find(this.eventSignups, signup => signup.event === event.id)
      },
      updateButtonClasses() {
        if (this.cssClasses) {
          this.$nextTick(() => {
            for (var i = 1; i <= 3; i++) {
              if (this.$refs['button' + i] && this.$refs['button' + i].$el) {
                this.$refs['button' + i].$el.className = this.normalizedCssClasses
              }
            }
          })
        }
      },
      moment: moment,
    },

  }
</script>

<style lang="stylus">

  .flex__1-3--margin
    img
      width 100%
      height 100%
    object-fit contain
</style>
