<template>
  <div>
    <div class="yogo-widget">
      <div class="yogo-book-appointment-button" data-yogo-parsed>

        <div>
          <button
              disabled
              v-if="errorMessage"
              class="yogo-btn yogo-btn-primary"
              :class="normalizedCssClasses" ref="button"
          >
            {{ errorMessage }}
          </button>

          <button
              v-else
              @click.prevent="buttonClick"
              class="yogo-btn yogo-btn-primary"
              :class="normalizedCssClasses"
              ref="button"
          >
            {{ ctaWithDefault }}
          </button>

        </div>
      </div>
    </div>


    <Modal
        :show="modalIsOpen"
        @close="closeModal">
      <template slot="body">
        <loading-spinner v-if="loading">...</loading-spinner>
        <div v-else>
          <div class="yogo-flex-md justify-between yogo-pt-2 yogo-mb-4">
            <div class="yogo-flex-1 yogo-mr-2-md yogo-mb-2">
              <div class="font-semibold yogo-mb-2">{{ $t('global.SelectService') }}</div>
              <yogo-select v-model="selectedAppointmentType" @input="onAppointmentTypeChange">
                <option v-if="!appointmentTypeOptions.length" disabled>{{
                    $t('global.NoAvailableServices')
                  }}
                </option>
                <option v-for="appointmentType in appointmentTypeOptions"
                        :value="appointmentType.id"
                        :key="appointmentType.id">
                  {{ appointmentType.name }}
                </option>
              </yogo-select>
            </div>

            <div class="yogo-flex-1 yogo-mr-2-md yogo-mb-2"
                 v-if="client && client.branches.length > 1">
              <div class="font-semibold yogo-mb-2">{{ $t('global.Branch') }}</div>
              <yogo-select v-model="selectedBranch">
                <option value="0">
                  - - {{ $t('global.AnyBranch') }} - -
                </option>
                <option v-for="branch in client.branches" :value="branch.id"
                        :key="branch.id">
                  {{ branch.name }}
                </option>
              </yogo-select>
            </div>

            <div class="yogo-flex yogo-flex-1 yogo-items-start">
              <div class="yogo-w-full yogo-flex yogo-flex-1 yogo-flex-col">
                <div class="font-semibold yogo-mb-2">{{ $t('global.SelectTeacher') }}</div>
                <yogo-select v-model="selectedTeacher">
                  <option value="0" v-if="!widgetTeacherId">
                    - - {{ $t('global.anyTeacher') }} - -
                  </option>
                  <option v-for="teacher in teacherOptions" :value="teacher.id"
                          :key="teacher.id">
                    {{ teacher.first_name + ' ' + teacher.last_name }}
                  </option>
                </yogo-select>
              </div>
            </div>
          </div>
          <div class="yogo-flex-md justify-between yogo-pt-2"
               v-if="showDatepickerAndAvailableTimes">
            <div class="datepicker-container yogo-flex-1 yogo-mb-4">
              <datepicker
                  :highlighted="{customPredictor: calendarHighlightedFn}"
                  :monday-first="true"
                  :inline="true"
                  v-model="selectedDate"
                  @changedMonth="datepickerChangedMonth"
              >
              </datepicker>

            </div>

            <div class="yogo-flex yogo-flex-1 yogo-ml-4-md">
              <div class="yogo-w-full">
                <div class="yogo-flex-md">
                  <div class="date-format"> {{ this.selectedDateFormat }}</div>
                </div>

                <div class="yogo-flex-md flex-wrap yogo-justify-center yogo-text-center">
                  <div v-if="visibleTimes.length > 0">
                    <button
                        :class="{'book-time':true, 'selected-time':(time === selectedTime)}"
                        v-for="time in visibleTimes" :key="time"
                        @click="selectBookTime(time)"
                    >
                      {{ time }}
                    </button>
                  </div>
                  <span v-else>{{ $t('global.NoAvailableTimesThisDate') }}</span>
                </div>

              </div>
            </div>
          </div>

          <!--<div v-if="showQuestionsAndBookButton">
            <div class="md:flex pt-2" v-for="item in questions" :key="item.id">
              <md-field class="flex--50">
                <label>{{ item.text }}</label>
                <md-input v-model="item.answer" type="text" :required="item.required"></md-input>
              </md-field>
            </div>
          </div>-->

          <button
              v-if="showQuestionsAndBookButton"
              :disabled="!allowBook"
              @click.prevent="bookAppointment"
              class="yogo-btn yogo-btn-primary book mt-2 yogo-w-full"
              :class="normalizedCssClasses"
              ref="button"
          >
            {{ $t('global.Continue') }}
          </button>
        </div>
      </template>
    </Modal>


  </div>
</template>

<script>

import YogoApi from '../gateways/YogoApi';
import YogoFrontend from '../gateways/YogoFrontend';
import { mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import qs from 'qs';
import Modal from '@/components/Modal';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import YogoSelect from '@/components/ui/YogoSelect';
import LoadingSpinner from '@/components/LoadingSpinner';
import { DateTime } from 'luxon';

export default {
  name: 'BookAppointmentButton',
  mixins: [dateTimeFunctions],
  components: {
    LoadingSpinner,
    YogoSelect,
    Modal,
    Datepicker,
  },
  data: function () {
    return {
      loading: true,

      appointmentTypes: [],
      upcomingAppointmentTypes: [],

      appointmentTypeOptions: [],
      selectedAppointmentType: null,

      selectedBranch: '0',

      teachers: [],
      visibleTeacherIds: [],
      teacherOptions: [],
      selectedTeacher: '0',

      errorMessage: '',

      selectedDate: new Date(),

      modalIsOpen: false,

      appointmentAvailabilities: [],

      showDatepickerAndAvailableTimes: false,

      times: [],
      visibleTimes: [],
      selectedTime: '',
      noTimeSelected: false,

    };
  },

  props: {
    widgetAppointmentTypeId: Number,
    widgetTeacherId: Number,
    widgetBranchId: Number,
    buttonText: String,
    cta: String,
    cssClasses: String,
  },

  computed: {
    ...mapGetters([
      'user',
      'classesCounter',
      'client',
    ]),
    normalizedCssClasses() {
      return this.cssClasses.replace(/,/g, ' ').replace(/ {2,}/g, ' ');
    },
    ctaWithDefault() {
      return this.cta || this.buttonText || this.$t('global.book');
    },
    selectedDateFormat() {
      return this.formatDate(this.selectedDate, {weekday: true});
    },
    showQuestionsAndBookButton() {
      return !!this.selectedTime;
    },
    /*questions() {
      // return questions from the selected appointment type, if there is one
      return this.appointmentTypes
      && this.selectedAppointmentType
          ? _find(
              this.appointmentTypes,
              { id: this.selectedAppointmentType },
          ).questions
          : [];
    },*/
    allowBook() {
      if (!this.showDatepickerAndAvailableTimes) return false;
      return !!this.selectedTime;

      /*const requiredQuestions = _filter(
          this.questions,
          {required: true}
      );
      return !_find(requiredQuestions, q => !q.answer);*/
    },
  },

  async created() {
  },

  mounted() {
    this.updateButtonClasses();
  },

  watch: {
    selectedAppointmentType() {
      this.updateTeacherOptions();
    },
    selectedTeacher() {
      this.getAppointmentAvailableTimes();
      this.selectedTime = '';
    },
    selectedDate() {
      this.updateTimes();
      this.selectedTime = '';
    },
    selectedBranch(newSelectedBranch) {
      this.getAppointmentAvailableTimes();
      if (newSelectedBranch !== '0') {
        this.selectedTime = '';
      }
    },
  },

  methods: {

    async init() {
      this.loading = true;
      this.selectedAppointmentType = null;
      this.selectedBranch = this.widgetBranchId || '0';
      this.selectedTeacher = this.widgetTeacherId || '0';
      this.selectedTime = '';

      // Get all initial data in parallel
      [
        this.appointmentTypes,
        this.teachers,
        this.upcomingAppointmentTypes,
      ] = await Promise.all([
        YogoApi.get('/appointment-types?populate[]=questions'),
        YogoApi.get('/users?teacher=1'),
        YogoApi.get('/appointment-available-intervals/upcoming-appointment-types'),
      ]);


      // If the widget is for a particular teacher,
      // we should only show appointment types that this teacher offers
      if (this.widgetTeacherId) {
        this.upcomingAppointmentTypes = _filter(
            this.upcomingAppointmentTypes,
            at => _includes(at.teacher_ids, this.widgetTeacherId),
        );
      }

      // If the widget is for a particular appointment type, we should show that appointment type,
      // even if there are no available times or teacher
      if (
          this.widgetAppointmentTypeId
          && !_find(
              this.upcomingAppointmentTypes,
              { appointment_type_id: this.widgetAppointmentTypeId },
          )
      ) {
        this.upcomingAppointmentTypes.push({ appointment_type_id: this.widgetAppointmentTypeId });
      }

      // Create appointment type dropdown options
      this.appointmentTypeOptions = _filter(
          this.appointmentTypes,
          at => _find(
              this.upcomingAppointmentTypes,
              { appointment_type_id: at.id },
          ),
      );
      this.appointmentTypeOptions = _sortBy(
          this.appointmentTypeOptions,
          'name',
      );

      // If the widget is for at particular appointment type, that should be selected initially.
      // Otherwise, we select the first option.
      if (this.widgetAppointmentTypeId) {
        this.selectedAppointmentType = this.widgetAppointmentTypeId;
      } else if (this.appointmentTypeOptions.length > 0) {
        // First appointment type in the dropdown
        this.selectedAppointmentType = this.appointmentTypeOptions[0].id;
      }

      await this.updateTeacherOptions();

      /*this.appointmentTypes.map(item => {
        if (item.id == this.appointmentType) {
          this.questions = item.questions;
        }
      });*/
      /*console.log("aaa", this.questions);
      var selectedAppointmentType = null;
      this.appointmentTypes.map(item => {
        if (item.id == this.appointmentType) {
          selectedAppointmentType = item;
        }
      });
*/
      await this.getAppointmentAvailableTimes();

      this.loading = false;

    },

    async updateTeacherOptions() {
      // If widget is for a specific teacher, only show this teacher
      // Otherwise show teachers that offer the selected appointment type
      if (
          this.widgetTeacherId
      ) {
        this.visibleTeacherIds = [this.widgetTeacherId];
      } else {
        const upcomingAppointmentType = _find(
            this.upcomingAppointmentTypes,
            at => parseInt(at.appointment_type_id) === parseInt(this.selectedAppointmentType),
        );
        this.visibleTeacherIds = upcomingAppointmentType
            ? upcomingAppointmentType.teacher_ids
            : [];
      }

      // Create teacher dropdown options
      this.teacherOptions = _filter(
          this.teachers,
          t => _includes(this.visibleTeacherIds, t.id),
      );
      this.teacherOptions = _sortBy(
          this.teacherOptions,
          ['first_name', 'last_name'],
      );


      // If widget is for a particular teacher, select that teacher
      if (this.widgetTeacherId) {
        this.selectedTeacher = this.widgetTeacherId;
      }
    },

    async getAppointmentAvailableTimes() {
      if (this.selectedAppointmentType) {
        const start_date = DateTime.now().setZone(this.client.timezone).toFormat('YYYY-MM') === DateTime.fromJSDate(this.selectedDate).toFormat('YYYY-MM')
            ? DateTime.fromJSDate(this.selectedDate).toISODate()
            : DateTime.fromJSDate(this.selectedDate).startOf('month').toISODate();
        const queryString = qs.stringify({
          start_date: start_date,
          end_date: DateTime.fromJSDate(this.selectedDate).endOf('month').toISODate(),
          appointment_type_id: this.selectedAppointmentType,
          teacher_id: this.selectedTeacher || undefined,
          branch_id: this.selectedBranch || undefined,
        });
        this.showDatepickerAndAvailableTimes = false;
        this.times = await YogoApi.get(`/appointment-available-timeslots?${queryString}`);
        this.showDatepickerAndAvailableTimes = true;
      } else {
        this.times = [];
      }

      this.updateTimes();

    },

    calendarHighlightedFn(date) {
      const isoDate = DateTime.fromJSDate(date).toISODate();
      const todayIso = DateTime.now().setZone(this.client.timezone).toISODate();
      return isoDate >= todayIso
          && this.times
          && this.times[isoDate]
          && !!this.times[isoDate].length;
    },

    async datepickerChangedMonth(param1) {
      if (param1.timestamp) {
        this.selectedDate = DateTime.fromMillis(param1.timestamp, {zone: this.client.timezone}).toISODate();
      } else {
        this.selectedDate = param1;
      }
      await this.getAppointmentAvailableTimes();
      return false;
    },

    selectBookTime(time) {
      this.selectedTime = time;
    },

    async updateTimes() {
      console.log(this.selectedDate);
      const dateISO = DateTime.fromJSDate(this.selectedDate).toISODate();

      const nowLuxon = DateTime.now().setZone(this.client.timezone);

      // Don't show available times before now
      if (dateISO < nowLuxon.toISODate()) {
        this.visibleTimes = [];
      }

      this.visibleTimes = this.times[dateISO];

      // We only need the time point itself
      this.visibleTimes = _map(
          this.visibleTimes,
          t => t.start_time.substr(0, 5),
      );

      // Only show one item per time
      this.visibleTimes = _uniq(
          this.visibleTimes,
      );

      // If today is selected, only show time slots later than now
      if (dateISO === nowLuxon.toISODate()) {
        const nowTimeShort = nowLuxon.toFormat('HH:mm');
        this.visibleTimes = _filter(
            this.visibleTimes,
            time => time > nowTimeShort,
        );
      }

      // Sort time slots
      this.visibleTimes.sort();

    },

    async onAppointmentTypeChange() {
      /*this.appointmentTypes.map(item => {
        if (item.id == this.appointmentType) {
          this.questions = item.questions;
        }
      });*/
      await this.getAppointmentAvailableTimes();
    },

    async onTeacherChange() {

    },

    async buttonClick() {
      this.modalIsOpen = true;
      await this.init();
      this.loading = false;
    },

    closeModal() {
      this.modalIsOpen = false;
    },

    async bookAppointment() {
      if (this.allowBook) {
        const cartItem = {
          itemType: 'appointment',
          appointment_type_id: this.selectedAppointmentType,
          date: DateTime.fromJSDate(this.selectedDate).toISODate(),
          start_time: this.selectedTime + ':00',
          //answers: _map(this.questions, 'answer'),
        };
        if (parseInt(this.selectedTeacher)) {
          cartItem.teacher_id = this.selectedTeacher;
        }
        if (parseInt(this.selectedBranch)) {
          cartItem.branch_id = this.selectedBranch;
        }

        console.log(cartItem);
        const loginWithCartRouteParams = {
          cartItemJson: JSON.stringify(cartItem),
        };
        YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(
            loginWithCartRouteParams)));
        this.modalIsOpen = false;
      } else {
        this.noTimeSelected = true;
      }
    },

    updateButtonClasses() {
      if (this.cssClasses) {
        this.$nextTick(() => {
          if (this.$refs.button && this.$refs.button.$el) {
            this.$refs.button.$el.className = this.normalizedCssClasses;
          }
        });
      }
    },

  },
};
</script>

<style lang="scss" scoped>

.datepicker-container /deep/ .vdp-datepicker__calendar {
  border: none;
  width: 100% !important;
  font-weight: bold;

  header { /* Will often be overwritten by host CSS */
    height: auto;
    border: none;
    background: none;
    z-index: auto;
    position: static;
    left:0;
    top:0;
  }

  .highlighted {
    background: transparent;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -2px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #2563eb;
    }
  }

  .selected {
    background: #ccc;
  }

  .cell {
    font-size: 12px;
    border: 2px solid transparent;
    border-radius: 5px;
    line-height: 3;
    @media screen and (min-width: 768px) {
      line-height: 2.3;
      font-size: 16px;
    }
  }

  .cell:hover {
    border: 2px solid #2563eb;
  }

  .cell.selected:hover {
    color: #2563eb;
    background: #dceafe;
  }

  .cell:not(.blank):not(.disabled).day:hover,
  .cell:not(.blank):not(.disabled).month:hover,
  .cell:not(.blank):not(.disabled).year:hover {
    border: 2px solid transparent;
  }

  .selected.highlighted {
    color: #fff;
    background: #2563eb;
    border: 2px solid transparent;

  }
}

@media screen and (min-width: 1368px) {
  .yogo-modal-content {
    overflow: initial;
  }
}

.book-time {
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 8px;
  font-weight: 600;
  background: #dbeafe;
  color: #2563eb;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid #2563eb;
  }
}

.selected-time {
  background-color: #2563eb;
  color: #fff;
}

.date-format {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
}

</style>
