<template>
  <div>
    <md-dialog :md-active="showEventDialog" v-on:update:mdActive="updateShowEventDialog">
      <a class="close-signup-modal" @click="$emit('update:showEventDialog', false)">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
               stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6"
                                                                       y2="18"></line><line x1="6"
                                                                                            y1="6"
                                                                                            x2="18"
                                                                                            y2="18"></line></svg>
        </span>
      </a>

      <div class="yogo-modal-content">

        <div class="yogo-flex-md yogo-pt-2">
          <div class="yogo-flex-1 yogo-mr-2">
            <div class="yogo--h1 yogo-mb-2">{{ dialogEventTimeSlot.event.name }}</div>
            <p class="ttu">
              {{ formatDate(dialogEventTimeSlot.date) }} | {{
                dialogEventTimeSlot.start_time + '-' +
                dialogEventTimeSlot.end_time
              }}
            </p>

            <p class="bold ttu">
              <span v-if="dialogEventTimeSlot.event.price === 0">
               {{ $t('global.Free') }}
             </span>
              <span v-else>
               {{ $t('global.price') }}: {{ formatCurrency(dialogEventTimeSlot.event.price) }}
             </span>
            </p>

            <p class="bold mb-0 leading-loose" v-if="dialogEventTimeSlot.branchName">
              {{ dialogEventTimeSlot.branchName }}
            </p>
            <div v-if="getRoom()">
              {{ getRoom().name }}
            </div>
            <DialogTeacherInfo :teachers="getTeachers()"></DialogTeacherInfo>
          </div>

          <div class="yogo-flex yogo-flex-1 yogo-items-start">
            <img
                class="modal__image--standard"
                :src="Imgix(dialogEventTimeSlot.event.image.filename, {w:900, h:600, fit:'crop'})"
                alt=""
                v-if="dialogEventTimeSlot.event.image"
            >
          </div>
        </div>
        <div class="yogo-line yogo-my-4"></div>
        <nl2br tag="div" :text="dialogEventTimeSlot.event.description" className="my-2"></nl2br>

      </div>

      <md-dialog-actions>

        <button
            class="yogo-btn yogo-btn-primary"
            @click="signUpForEvent(dialogEventTimeSlot.event)"
            v-if="dialogEventTimeSlot.user_can_sign_up_for_event"
        >
          {{ $t('global.signup') }}
        </button>

        <button
            class="yogo-btn yogo-btn-primary"
            disabled="disabled"
            v-if="dialogEventTimeSlot.user_is_signed_up_for_event"
        >
          {{ $t('global.youAreSignedUp') }}
        </button>

        <button
            class="yogo-btn yogo-btn-primary"
            @click="signOffFromEvent(dialogEventTimeSlot)"
            v-if="dialogEventTimeSlot.can_sign_off_from_event"
        >
          {{ $t('global.cancel') }}
        </button>

      </md-dialog-actions>
    </md-dialog>

    <EventSignup
        :user="user"
        ref="eventSignupComponent"
        @eventSignupSuccess="eventSignupSuccess"
        @eventSignoffSuccess="eventSignoffSuccess"
    ></EventSignup>

  </div>

</template>


<script>

import EventSignup from '@/components/EventSignup';
import DialogTeacherInfo from "./DialogTeacherInfo";
import Imgix from '@/services/Imgix';
import currencyFormatters from '@/mixins/currencyFormatters';

import { mapGetters } from 'vuex';
import dateTimeFunctions from '@/mixins/dateTimeFunctions.vue';

import _get from 'lodash/get';


export default {
  mixins: [Imgix, currencyFormatters, dateTimeFunctions],
  props: [
    'showEventDialog',
    'dialogEventTimeSlot',
  ],
  computed: {
    ...mapGetters([
      'user',
    ]),
  },
  components: {
    EventSignup,
    DialogTeacherInfo,
  },

  methods: {

    updateShowEventDialog(showEventDialog) {
      this.$emit('update:showEventDialog', showEventDialog);
    },

    signUpForEvent(event) {
      this.$emit('update:showEventDialog', false);
      this.$refs.eventSignupComponent.$emit('signUpForEvent', event);
    },
    signOffFromEvent(eventTimeSlot) {
      this.$emit('update:showEventDialog', false);
      this.$refs.eventSignupComponent.$emit('signOffFromEvent', eventTimeSlot);
    },
    eventSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showEventDialog', false);
    },
    eventSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showEventDialog', false);
    },
    getRoom() {
      return this.dialogEventTimeSlot.event.time_slots_have_separate_rooms_and_teachers
          ? this.dialogEventTimeSlot.room
          : _get(this.dialogEventTimeSlot, 'event.room');
    },
    getTeachers() {
      return this.dialogEventTimeSlot.event.time_slots_have_separate_rooms_and_teachers
          ? this.dialogEventTimeSlot.teachers
          : _get(this.dialogEventTimeSlot, 'event.teachers');
    }
  },
};


</script>
