import YOGO_CLIENT_DOMAIN from '../services/YOGO_CLIENT_DOMAIN';
import qs from 'qs';
import _isObject from 'lodash/isObject';
import _includes from 'lodash/includes';
import store from '../store';
import VALID_CLIENT_DOMAINS from '../constants/VALID_CLIENT_DOMAINS';

let popupIframe;
let popupCurtain;
let bodyOverflowStyle;

window.addEventListener('message', receivedMessage);

function receivedMessage(event) {

  if (!event.data) return;

  let commandObject = event.data;

  try {
    if (!_isObject(commandObject)) {
      commandObject = JSON.parse(event.data);
    }
  } catch (err) {
    return;
  }


  if (commandObject.command) {
    switch (commandObject.command) {

      case 'closeYogoFrontend':
        closePopup();
        break;

      case 'yogoGetTopUrl':
        sendTopUrlToYogoFrontend();
        break;
    }
  }

}

function closePopup() {
  document.body.style.overflow = bodyOverflowStyle;
  document.body.removeChild(popupIframe);
  document.body.removeChild(popupCurtain);

  store.dispatch('updateUser');
  store.commit('incrementClassesCounter');
}

function sendTopUrlToYogoFrontend() {
  //console.log('sendTopUrlToYogoFrontend');
  popupIframe.contentWindow.postMessage({
    command: 'replyWithTopUrl',
    topUrl: window.location.href,
  }, '*');
}

function openPopupFromUrlParameter() {
  //console.log('Before open popup');
  const url = new URL(document.location);
  //console.log(url);
  if (url.searchParams.get('yogoPopupPath')) {
    //console.log('url.searchParams.yogoPopupPath:', url.searchParams.get('yogoPopupPath'));
    openPopup(url.searchParams.get('yogoPopupPath'));
  }
}


function openPopup(endpoint, queryParams) {

  console.log('YogoFrontend endpoint', endpoint);

  let frontendUrl;

  if (endpoint.match(/^https?:\/\//)) {
    // Full URL
    const url = new URL(endpoint);
    if (_includes(VALID_CLIENT_DOMAINS, url.hostname) || url.hostname === 'localhost') {
      frontendUrl = endpoint;
    }
  } else {
    queryParams = queryParams || {};

    // Add current URL as query parameter (if YOGO_ALWAYS_OPEN_IN_NEW_WINDOW is not set)
    if (!window.YOGO_ALWAYS_OPEN_IN_NEW_WINDOW) {
      queryParams.clientWebsiteReferrerUrl = window.location.href;
    }

    // Endpoint + optional query parameters
    const query = '?' + qs.stringify(queryParams);

    if (process.env.VUE_APP_FRONTEND_URL) {
      frontendUrl = process.env.VUE_APP_FRONTEND_URL + query + '#' + endpoint;
    } else if (YOGO_CLIENT_DOMAIN) {
      frontendUrl = 'https://' + YOGO_CLIENT_DOMAIN + '/frontend/index.html' + query + '#' + endpoint;
    }
  }


  console.log('frontendUrl', frontendUrl);

  if (document.querySelector('.yogo-frontend-iframe')) {
    return;
  }

  // In some cases, we want to always open YOGO in a new window. For instance, when the website
  // builder does not allow top level navigation and the payment provider is Stripe.
  if (window.YOGO_ALWAYS_OPEN_IN_NEW_WINDOW) {
    window.open(frontendUrl, '_blank');
    return;
  }

  // On mobile, we don't want to open the frontend in an iframe.
  // Unless the query parameter "openInPopupOnMobile" is set.
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  if (isMobile && !queryParams.openInPopupOnMobile) {
    document.location = frontendUrl;
    return;
  }

  popupIframe = document.createElement('iframe');
  popupIframe.className = 'yogo-frontend-iframe';
  popupIframe.setAttribute('allowfullscreen', '');
  //popupIframe.setAttribute('sandbox', 'allow-scripts allow-top-navigation allow-same-origin');

  const popupIframeStyle = popupIframe.style;
  popupIframeStyle.position = 'fixed';
  if (store.getters.client.settings.frontend_overlay_size === 'full_screen') {
    popupIframeStyle.left = '0';
    popupIframeStyle.top = '0';
    popupIframeStyle.width = '100%';
    popupIframeStyle.height = '100%';
    popupIframeStyle.border = 'none';
  } else {
    popupIframeStyle.left = '30px';
    popupIframeStyle.top = '30px';
    popupIframeStyle.width = 'calc(100% - 60px)';
    popupIframeStyle.height = 'calc(100% - 60px)';
    popupIframeStyle.border = '1px solid #ccc';
    if (store.getters.client.settings.theme === 'framed') {
      popupIframeStyle.boxShadow = '1px 2px 10px -2px #979797';
    }
  }

  popupIframeStyle.zIndex = 2000000001;
  popupIframeStyle.overflow = 'auto';
  popupIframeStyle.background = 'rgba(255,255,255,0.9)';

  bodyOverflowStyle = document.body.style.overflow;
  document.body.style.overflow = 'hidden';

  popupIframe.src = frontendUrl;
  document.body.appendChild(popupIframe);

  popupCurtain = document.createElement('div');
  const popupCurtainStyle = popupCurtain.style;
  popupCurtainStyle.position = 'fixed';
  popupCurtainStyle.left = '0';
  popupCurtainStyle.top = '0';
  popupCurtainStyle.zIndex = 2000000000;
  popupCurtainStyle.width = '100%';
  popupCurtainStyle.height = '100%';
  popupCurtainStyle.border = 'none';
  popupCurtainStyle.background = 'rgba(255,255,255,0.5)';
  popupCurtain.addEventListener('click', closePopup);
  document.body.appendChild(popupCurtain);

}

export default {
  open: openPopup,
  openPopupFromUrlParameter,
};
