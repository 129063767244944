<template>
  <div :style="{color: fontColor}" class="class-action">
    <div
        class="yogo-btn-secondary"
        :class="buttonClass"
        :style="{color: fontColor}"
        v-if="showSignupButton"
        @click.prevent.stop="signupButtonClick(classItem)"
    >
      {{ $t('global.signup') }}
    </div>
    <div
        class="yogo-btn-secondary mt-2"
        :class="buttonClass"
        :style="{color: fontColor}"
        v-if="showLivestreamSignupButton"
        @click.prevent.stop="$emit('signUpForLivestream', classItem)"
    >
      {{ $t('global.signUpForLivestream') }}
    </div>
    <div
        class="yogo-btn-secondary mt-2"
        :class="buttonClass"
        :style="{color: fontColor}"
        v-if="showWaitingListSignupButton"
        @click.prevent.stop="$emit('signUpForWaitingList', classItem)"
    >
      {{ $t('global.joinWaitlist') }}
    </div>

    <SignedUpCheckmark
        :align="align"
        :color="color"
        v-if="classItem.user_is_signed_up_for_class"
        :class="customClass"
        :signup-count="classItem.user_signup_count"
    >
    </SignedUpCheckmark>
    <SignedUpCheckmark
        :align="align"
        :color="color"
        v-if="classItem.user_is_signed_up_for_livestream"
        :class="customClass"
    >
    </SignedUpCheckmark>

    <div
        v-if="classItem.user_is_signed_up_for_waiting_list"
        :style="{display:'flex', alignItems: 'center', justifyContent: align, flexShrink:0}"
        :class="customClass"
    >
      <SignedUpForWaitingListIcon :align="align" :color="fontColor"></SignedUpForWaitingListIcon>
      <span class="waiting-list-count"> #{{ classItem.user_number_on_waiting_list }} </span>
      <md-tooltip>{{
          $t('global.youAreNumberXOnTheWaitlist',
              { number: classItem.user_number_on_waiting_list })
        }}
      </md-tooltip>
    </div>

    <a v-if="classItem.user_can_start_livestream"
       @click.prevent.stop="$emit('startLivestream', classItem)">
      {{ $t('global.startLivestream') }}
    </a>


  </div>
</template>

<script>

import SignedUpCheckmark from "../../graphics/SignedUpCheckmark";
import SignedUpForWaitingListIcon from "../../graphics/SignedUpForWaitingListIcon";

import { mapGetters } from 'vuex';

export default {
  components: { SignedUpCheckmark, SignedUpForWaitingListIcon },
  props: {
    classItem: Object,
    color: String,
    align: String,
    customClass: String,
    buttonClass: String,
    widgetOnlyLivestream: Boolean,
  },
  computed: {
    ...mapGetters([
      'user',
      'client',
    ]),
    fontColor() {
      return {
        white: 'rgba(255,255,255,0.85)',
        black: 'rgba(0,0,0,0.85)',
      }[this.color];
    },
    showSignupButton() {
      return (
              this.classItem.class_accepts_customer_signups
              || !!this.classItem.class_series_types.length
              || !!this.classItem.class_series_types_livestream.length
          )
          && !this.classItem.user_is_signed_up_for_class
          && !this.classItem.user_is_signed_up_for_livestream
          && !this.widgetOnlyLivestream;
    },
    showLivestreamSignupButton() {
      return this.classItem.class_accepts_livestream_signups
          && !this.classItem.user_is_signed_up_for_class
          && !this.classItem.user_is_signed_up_for_waiting_list
          && !this.classItem.user_is_signed_up_for_livestream;
    },
    showWaitingListSignupButton() {
      return this.classItem.class_accepts_waiting_list_signups
          && !this.classItem.user_is_signed_up_for_class
          && !this.classItem.user_is_signed_up_for_waiting_list
          && !this.classItem.user_is_signed_up_for_livestream
          && !this.widgetOnlyLivestream;
    },
  },
  methods: {
    signupButtonClick(classItem) {
      if (classItem.class_series_types.length || classItem.class_series_types_livestream.length || this.client.settings.customer_can_reserve_multiple_seats_for_class) {
        this.$emit('openClassDialog', classItem);
      } else {
        this.$emit('signUpForClass', classItem);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.class-action {
  padding-top: 10px;
}

.waiting-list-count {
  font-size: 20px;
  margin-left: 5px;
}
</style>
