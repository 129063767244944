<template>
  <div>

    <h3
      class="yogo-flex yogo-text-base yogo-font-bold yogo-leading-6 yogo-text-gray-900 yogo-pointer"
      @click="show = !show"
    >
      {{ $t('global.Attendees') }}:
      <div class="yogo-flex yogo-items-center yogo-pointer yogo-ml-2">
        <span :class="{ 'rotate': show }" class="block icon-arrowdown yogo-ml-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
        </span>
      </div>
    </h3>

    <!-- dropdown content -->
    <transition v-if="show" name="slide-fade" mode="out-in">
      <div class="yogo-flex yogo-flex-wrap yogo-my-2">
      <div
        v-for="(attendee) in attendees"
        v-bind:key="attendee.id"
        class="yogo-w-6-12 yogo-w-3-12-md yogo-flex yogo-items-center yogo-mb-2"
      >
        <div class="yogo-w-3-12">
          <profile-image
  :image="attendee.image_filename" :options="{w: 200, h:200, fit:'crop'}"
  style="width:100%;height:auto;"
            alt=""
          />
        </div>
        <p class="yogo-mr-2 yogo-ml-2 yogo-overflow-hidden">
          {{ attendee.first_name }} {{ attendee.last_name }}
        </p>
      </div>
      </div>
    </transition>
    <!-- end dropdown content -->

  </div>

</template>

<script>

  import Imgix from "../services/Imgix"
  import { mapGetters } from "vuex";
  import ProfileImage from '@/components/ProfileImage.vue';

  export default {
    components: { ProfileImage},
    props: ['attendees'],
    mixins: [Imgix],
    data() {
      return {
        show: false,
      }
    },
  }

</script>

<style lang="scss" scoped>

  .slide-fade-enter-active {
    transition: all .3s ease;
    z-index: 2;
    position: relative;
  }

  .slide-fade-leave-active {
    transition: all .2s ease;
    z-index: 2;
    position: relative;
  }

  .slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
    z-index: 2;
    position: relative;
  }

  .slide-fade-enter {
    opacity: 0;
    transform: translateY(-30px);
    z-index: 2;
    position: relative;
  }

</style>
