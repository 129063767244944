import qs from 'qs';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Schedule from "./components/Schedule";
import Prices from '@/components/Prices.vue';
import Events from '@/components/Events.vue';
import EventButton from '@/components/EventButton.vue';
import MembershipButton from "./components/MembershipButton";
import BookAppointmentButton from "./components/BookAppointmentButton";
import ClassPassButton from "./components/ClassPassButton";
import VideoGroup from "./components/VideoGroup";
import VideoExtended from "./components/VideoExtended";
import Products from '@/components/Products.vue';
import Teachers from '@/components/Teachers.vue';
import store from './store';
import YogoFrontend from './gateways/YogoFrontend';
import i18n from './inc/i18n';
// Vue Material
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
// Nl2br
import Nl2br from 'vue-nl2br';
import './filters/index';
import VueClipboard from 'vue-clipboard2';
import axios from 'axios';
import { DateTime } from 'luxon';

// Use to prevent multiple reload of the widget in some cases
if (window.YOGO_WIDGETS_LOADED) throw new Error('YOGO_WIDGETS_LOADED');
window.YOGO_WIDGETS_LOADED = true;


Vue.use(VueMaterial);
window.Vue = Vue;


Vue.use(VueClipboard);

import PortalVue, { PortalTarget } from 'portal-vue';

Vue.use(PortalVue);


// Global styles
require('./assets/styl/style.styl');
require('./assets/scss/style.scss');

Vue.component('nl2br', Nl2br);


Vue.config.productionTip = false;

function createPortalTarget() {
  // INSERT PORTAL TARGET FOR MODAL
  const yogoModalElement = document.createElement('portal-target');
  yogoModalElement.setAttribute('name', 'yogomodal');
  document.body.appendChild(yogoModalElement);
  new Vue({
    render: createElement => {
      const context = {
        props: {
          name: 'yogomodal',
        },
      };
      return createElement(PortalTarget, context);
    },
  }).$mount(yogoModalElement);
}

function parseHtml() {

  if (window.YOGO_CONVERT_TEXT_TAGS_TO_HTML) {
    const tagNodes = document.querySelectorAll('span,div,p');
    Array.from(tagNodes)
      .filter(node => node.innerHTML.match(
        /^\s*&lt;div class="(yogo-[\w-]+)"(\s*data-[\w-]+="[\d\wæøåÆØÅ ,\/-]+"\s*)*&gt;&lt;\/div&gt;\s*$/g,
      ))
      .forEach((node) => {
          // console.log('node.innerHTML:', node.innerHTML);
          node.innerHTML = node.innerText;
        },
      );
  }

  // PARSE WIDGETS AND YOGO LINKS
  let yogoLinks = document.querySelectorAll('a[href*=".yogo.dk"]:not([data-yogo-parsed])');
  if (!yogoLinks || !yogoLinks.length) {
    yogoLinks = document.querySelectorAll('a[href*=".yogo.no"]:not([data-yogo-parsed])');
  }
  if (!yogoLinks || !yogoLinks.length) {
    yogoLinks = document.querySelectorAll('a[href*=".yogobooking.com"]:not([data-yogo-parsed])');
  }
  if (!yogoLinks || !yogoLinks.length) {
    yogoLinks = document.querySelectorAll('a[href*=".yogobooking.se"]:not([data-yogo-parsed])');
  }
  if (!yogoLinks || !yogoLinks.length) {
    yogoLinks = document.querySelectorAll('a[href*=".yogobooking.de"]:not([data-yogo-parsed])');
  }
  if (!yogoLinks || !yogoLinks.length) {
    yogoLinks = document.querySelectorAll('a[href*=".yogobooking.pt"]:not([data-yogo-parsed])');
  }

  if (yogoLinks) {
    for (let i = 0; i < yogoLinks.length; i++) {
      yogoLinks[i].setAttribute('data-yogo-parsed', '');
      yogoLinks[i].addEventListener('click', yogoLinkClick);
    }
  }

  const yogoVideoExtendedElements = document.querySelectorAll('.yogo-video-extended');

  Array.from(yogoVideoExtendedElements).forEach(yogoVideoExtendedElement => {

    if (yogoVideoExtendedElement.querySelector('[data-yogo-parsed]')) {
      return;
    }

    const innerDiv1 = document.createElement('div'),
      innerDiv2 = document.createElement('div');
    innerDiv1.setAttribute('data-yogo-parsed', '');
    yogoVideoExtendedElement.appendChild(innerDiv1);
    innerDiv1.append(innerDiv2);

    new Vue({
      render: h => h(VideoExtended),
      store,
      i18n,
    }).$mount(innerDiv2);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'VideoExtended',
      },
    });
  });


  const yogoCalendarElements = document.querySelectorAll('#yogo-calendar, .yogo-calendar');

  Array.from(yogoCalendarElements).forEach(yogoCalendarElement => {

    if (yogoCalendarElement.querySelector('[data-yogo-parsed]')) {
      return;
    }

    const innerDiv1 = document.createElement('div'),
      innerDiv2 = document.createElement('div');
    innerDiv1.setAttribute('data-yogo-parsed', '');
    yogoCalendarElement.append(innerDiv1);
    innerDiv1.append(innerDiv2);

    const branch = yogoCalendarElement.getAttribute('data-branch');
    const branchName = branch ? branch : '';
    let startDate = yogoCalendarElement.getAttribute('data-start-date') || null;
    if (startDate) {
      startDate = startDate.replaceAll(/[\.\/]/g, '-');
      startDate = DateTime.fromFormat(startDate, 'd-M-yyyy').toISODate();

    }

    let classTypes = yogoCalendarElement.getAttribute('data-class-type')
      || yogoCalendarElement.getAttribute('data-class-types')
      || null;
    if (classTypes) {
      // Convert comma-separated string to array
      classTypes = classTypes.split(',').map(classTypeId => parseInt(classTypeId.trim()));
    }
    let teachers = yogoCalendarElement.getAttribute('data-teacher')
      || yogoCalendarElement.getAttribute('data-teachers')
      || null;
    if (teachers) {
      // Convert comma-separated string to array
      teachers = teachers.split(',').map(teacherId => parseInt(teacherId.trim()));
    }

    let onlyLivestream = !!yogoCalendarElement.getAttribute('data-only-livestream');

    new Vue({
      render: createElement => {
        const context = {
          props: {
            branchName,
            widgetStartDate: startDate,
            widgetClassTypes: classTypes,
            widgetTeachers: teachers,
            widgetOnlyLivestream: onlyLivestream,
          },
        };
        return createElement(Schedule, context);
      },
      store,
      i18n,
    }).$mount(innerDiv2);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'Schedule',
      },
    });
  });


  const yogoPricesElements = document.querySelectorAll('.yogo-prices, #yogo-prices');
  Array.from(yogoPricesElements).forEach(yogoPricesElement => {

    if (yogoPricesElement.querySelector('[data-yogo-parsed]')) {
      return;
    }

    const innerDiv1 = document.createElement('div'),
      innerDiv2 = document.createElement('div');
    innerDiv1.setAttribute('data-yogo-parsed', '');
    yogoPricesElement.append(innerDiv1);
    innerDiv1.append(innerDiv2);

    const priceGroup = yogoPricesElement.getAttribute('data-price-group');
    const priceGroupParam = priceGroup ? priceGroup : '';
    new Vue({
      render: createElement => {
        const context = {
          props: {
            showSinglePriceGroup: priceGroupParam,
            showPricesDropdown: yogoPricesElement.getAttribute('data-show-prices-dropdown') === "true",
            showPricesAllOption: yogoPricesElement.getAttribute('data-show-prices-all-option') === "true"
          },
        };
        return createElement(Prices, context);
      },
      store,
      i18n,
    }).$mount(innerDiv2);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'Prices',
      },
    });
  });


  const yogoEventsElements = document.querySelectorAll('#yogo-events, .yogo-events');
  Array.from(yogoEventsElements).forEach(yogoEventsElement => {

    if (yogoEventsElement.querySelector('[data-yogo-parsed]')) {
      return;
    }

    const innerDiv1 = document.createElement('div'),
      innerDiv2 = document.createElement('div');
    innerDiv1.setAttribute('data-yogo-parsed', '');
    yogoEventsElement.append(innerDiv1);
    innerDiv1.append(innerDiv2);

    const eventGroup = yogoEventsElement.getAttribute(('data-event-group'));
    const eventGroupParam = eventGroup || '';
    new Vue({
      render: createElement => {
        const context = {
          props: {
            eventGroup: eventGroupParam,
          },
        };
        return createElement(Events, context);
      },
      store,
      i18n,
    }).$mount(innerDiv2);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'Events',
      },
    });
  });


  const eventButtons = document.querySelectorAll('.yogo-event-button:not([data-yogo-parsed])');
  Array.from(eventButtons).forEach(eventButton => {

    eventButton.setAttribute('data-yogo-parsed', '');

    const eventId = eventButton.getAttribute('data-event');
    if (!eventId) {
      eventButton.innerHTML = 'Event button: Invalid event ID';
      return;
    }

    const cssClasses = eventButton.getAttribute('data-css-classes') || '';
    const cta = eventButton.getAttribute('data-cta') || '';

    new Vue({
      render: createElement => {
        const context = {
          props: {
            eventId: eventId,
            cssClasses: cssClasses,
            cta: cta,
          },
        };
        return createElement(EventButton, context);
      },
      store,
      i18n,
    }).$mount(eventButton);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'EventButton',
      },
    });
  });


  const membershipButtons = document.querySelectorAll(
    '.yogo-membership-button:not([data-yogo-parsed])');
  Array.from(membershipButtons).forEach(membershipButton => {

    membershipButton.setAttribute('data-yogo-parsed', '');

    const membershipTypeId = membershipButton.getAttribute('data-membership');
    const membershipTypePaymentOptionId = membershipButton.getAttribute('data-payment-option') || '';

    const cssClasses = membershipButton.getAttribute('data-css-classes') || '';
    const cta = membershipButton.getAttribute('data-cta') || '';

    new Vue({
      render: createElement => {
        const context = {
          props: {
            membershipTypeId: membershipTypeId,
            membershipTypePaymentOptionId: membershipTypePaymentOptionId,
            cssClasses: cssClasses,
            cta: cta,
          },
        };
        return createElement(MembershipButton, context);
      },
      store,
      i18n,
    }).$mount(membershipButton);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'MembershipButton',
      },
    });
  });


  const bookAppointmentButtons = document.querySelectorAll(
    '.yogo-book-appointment-button:not([data-yogo-parsed])');
  Array.from(bookAppointmentButtons).forEach(bookAppointmentButton => {

    bookAppointmentButton.setAttribute('data-yogo-parsed', '');

    const appointmentTypeId = bookAppointmentButton.getAttribute('data-appointment-type') || '';
    const teacherId = bookAppointmentButton.getAttribute('data-teacher') || '';
    const branchId = bookAppointmentButton.getAttribute('data-branch') || '';
    const cssClasses = bookAppointmentButton.getAttribute('data-css-classes') || '';
    const cta = bookAppointmentButton.getAttribute('data-cta') || '';
    const buttonText = bookAppointmentButton.getAttribute('data-button-text') || '';

    new Vue({
      render: createElement => {
        const context = {
          props: {
            widgetAppointmentTypeId: parseInt(appointmentTypeId),
            widgetTeacherId: parseInt(teacherId),
            widgetBranchId: parseInt(branchId),
            cssClasses: cssClasses,
            cta: cta,
            buttonText: buttonText,
          },
        };
        return createElement(BookAppointmentButton, context);
      },
      store,
      i18n,
    }).$mount(bookAppointmentButton);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'BookAppointmentButton',
      },
    });
  });


  const classPassButtons = document.querySelectorAll(
    '.yogo-class-pass-button:not([data-yogo-parsed])');
  Array.from(classPassButtons).forEach(classPassButton => {

    classPassButton.setAttribute('data-yogo-parsed', '');

    const classPassTypeId = classPassButton.getAttribute('data-class-pass');

    const cssClasses = classPassButton.getAttribute('data-css-classes') || '';
    const cta = classPassButton.getAttribute('data-cta') || '';

    new Vue({
      render: createElement => {
        const context = {
          props: {
            classPassTypeId: classPassTypeId,
            cssClasses: cssClasses,
            cta: cta,
          },
        };
        return createElement(ClassPassButton, context);
      },
      store,
      i18n,
    }).$mount(classPassButton);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'ClassPassButton',
      },
    });
  });


  const yogoVideoGroupElements = document.querySelectorAll('.yogo-video-group');
  Array.from(yogoVideoGroupElements).forEach(videoGroupElement => {

    if (videoGroupElement.querySelector('[data-yogo-parsed]')) {
      return;
    }

    const innerDiv1 = document.createElement('div'),
      innerDiv2 = document.createElement('div');
    innerDiv1.setAttribute('data-yogo-parsed', '');
    videoGroupElement.append(innerDiv1);
    innerDiv1.append(innerDiv2);

    const videoGroupId = videoGroupElement.getAttribute('data-video-group');

    const cssClasses = videoGroupElement.getAttribute('data-css-classes') || '';

    new Vue({
      render: createElement => {
        const context = {
          props: {
            videoGroupId: videoGroupId,
            cssClasses: cssClasses,
          },
        };
        return createElement(VideoGroup, context);
      },
      store,
      i18n,
    }).$mount(innerDiv2);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'VideoGroup',
      },
    });
  });


  const yogoProductWidgets = document.querySelectorAll('.yogo-products');
  Array.from(yogoProductWidgets).forEach(yogoProductWidget => {

    if (yogoProductWidget.querySelector('[data-yogo-parsed]')) {
      return;
    }

    const innerDiv1 = document.createElement('div'),
      innerDiv2 = document.createElement('div');
    innerDiv1.setAttribute('data-yogo-parsed', '');
    yogoProductWidget.append(innerDiv1);
    innerDiv1.append(innerDiv2);

    new Vue({
      render: h => h(Products),
      store,
      i18n,
    }).$mount(innerDiv2);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'Products',
      },
    });
  });


  const yogoTeacherWidgets = document.querySelectorAll('.yogo-teachers');
  Array.from(yogoTeacherWidgets).forEach(yogoTeacherWidget => {

    if (yogoTeacherWidget.querySelector('[data-yogo-parsed]')) {
      return;
    }

    const innerDiv1 = document.createElement('div'),
      innerDiv2 = document.createElement('div');
    innerDiv1.setAttribute('data-yogo-parsed', '');
    yogoTeacherWidget.append(innerDiv1);
    innerDiv1.append(innerDiv2);

    let startDate = yogoTeacherWidget.getAttribute('data-start-date') || null;
    if (startDate) {
      startDate = startDate.replaceAll(/[\.\/]/g, '-');
      startDate = DateTime.fromFormat(startDate, 'd-M-yyyy').toISODate();
    }

    let teacherIds = yogoTeacherWidget.getAttribute('data-teacher')
      || yogoTeacherWidget.getAttribute('data-teachers')
      || null;

    if (teacherIds) {
      teacherIds = teacherIds.split(',').map(teacherId => teacherId.trim());
    }

    new Vue({
      render: createElement => {
        const context = {
          props: {
            widgetStartDate: startDate,
            teacherIds,
          },
        };
        return createElement(Teachers, context);
      },
      store,
      i18n,
    }).$mount(innerDiv2);

    axios.post(process.env.VUE_APP_API_ROOT + '/widget-notifications/notify', {
      payload: {
        event: 'initWidget',
        widget: 'Teachers',
      },
    });
  });


  if (window.YOGO_PARSE_HTML_CONTINUOUSLY) {
    // Some page builders do not reload on page change.
    // We can not even rely on detecting URL change
    // because the content might appear later than the URL change.
    // So in these cases, we just re-parse the HTML every second
    setTimeout(parseHtml, 1000);
  }

}

function onDocumentReady() {
  createPortalTarget();
  parseHtml();
}

if (document.readyState !== 'loading') {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}


function yogoLinkClick(event) {

  let target = event.target;
  while (target.tagName.toLowerCase() !== 'a') {
    target = target.parentElement;
  }
  const href = target.href;

  // Don't intercept links to teacher module.
  if (href.match(/\.yogo\.dk\/teacher/)) return;

  event.preventDefault();
  event.stopPropagation();

  const hashPos = href.indexOf('#');
  if (hashPos === -1) {
    YogoFrontend.open('');
    return;
  }
  const frontendUrl = href.substr(hashPos + 1);
  const queryParamsRegexResult = /\?([^#]+)#/.exec(href);
  const queryParams = queryParamsRegexResult ? qs.parse(queryParamsRegexResult[1]) : null;
  YogoFrontend.open(frontendUrl, queryParams);
}


(async () => {
  await store.dispatch('init');

  const settings = store.getters.client.settings;

  const vueMaterialCssTag = document.createElement('link');
  vueMaterialCssTag.rel = 'stylesheet';
  vueMaterialCssTag.setAttribute('type', 'text/css');
  vueMaterialCssTag.href = process.env.VUE_APP_VUE_MATERIAL_THEME_CSS_SERVER +
    '/vue-material-theme-css-primarycolor-' + settings.theme_primary_color.substr(1) + '-accentcolor-' + settings.theme_primary_color.substr(
      1) + '-widget-prefix.css';
  document.head.appendChild(vueMaterialCssTag);

  const themeFrameBoxBoxShadow = settings.theme === 'framed' ? '1px 2px 10px -2px #979797' : 'none';
  const themeFrameBoxPadding =
    settings.theme === 'framed' ||
    settings.theme_background_color.toLowerCase() !== '#ffffff' ?
      '30px' : '10px 0';

  let customCssTag = document.createElement('style');
  customCssTag.setAttribute('type', 'text/css');
  customCssTag.rel = 'stylesheet';

  const customCssTagTextElement = `

    .yogo-widget .primary-color-border {
      border-color: ${settings.theme_primary_color};
      color: ${settings.theme_primary_color};
    }
    .yogo-widget .primary-color-border:hover {
      background: ${settings.theme_primary_color};
    }
    .yogo-widget .theme--frame-box, .yogo-widget.theme--frame-box {
      box-shadow: ${themeFrameBoxBoxShadow};
      background: white;
      padding: ${themeFrameBoxPadding};
    }
    .yogo-widget a {
      color: ${settings.theme_primary_color};
    }
    .yogo-widget .classpass__clip--inner {
      background: ${settings.theme_primary_color};
    }
    .yogo-widget .square--blue:before {
      background: ${settings.theme_primary_color};
    }
    .yogo-widget .md-menu-content-container .md-list {
      font-family: ${settings.theme_font_family}, Arial, sans-serif;
    }
  `;

  customCssTag.appendChild(document.createTextNode(customCssTagTextElement));

  document.head.appendChild(customCssTag);


  const rootStyleObject = document.documentElement.style;
  rootStyleObject.setProperty('--md-theme-default-primary', settings.theme_primary_color);
  rootStyleObject.setProperty('--md-theme-default-primary-on-background',
    settings.theme_primary_color);
  rootStyleObject.setProperty('--md-theme-default-accent', settings.theme_primary_color);
})();
