<template>
  <div class="yogo-widget">
    <div class="yogo-membership-button" data-yogo-parsed>

      <span v-if="loading">...</span>

      <div v-else>
        <button
          disabled
          v-if="errorMessage"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses" ref="button"
        >
          {{ errorMessage }}
        </button>

        <button
          v-else-if="membershipType && membershipType.user_has_membership_type"
          disabled
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses"
          ref="button"
        >
          {{ $t('global.haveMembership') }}
        </button>

        <button
          v-else-if="membershipType && membershipType.max_number_of_memberships_reached"
          @click.prevent="openWaitlistEmailComposer(membershipType)"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses"
          ref="button"
        >
          {{ $t('global.waitlist') }}
        </button>


        <button
          v-else-if="membershipType"
          @click.prevent="buttonClick"
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses"
          ref="button"
        >
          {{ ctaWithDefault }}
        </button>

        <button
          v-else
          disabled
          class="yogo-btn yogo-btn-primary"
          :class="normalizedCssClasses"
          ref="button"
        >
          Oops, unfamiliar error...
        </button>

      </div>
    </div>
  </div>
</template>

<script>

  import YogoApi from '../gateways/YogoApi'
  import YogoFrontend from '../gateways/YogoFrontend'
  import {mapGetters} from 'vuex'
  import _filter from 'lodash/filter'
  import _find from 'lodash/find'

  export default {
    data: function () {
      return {
        loading: true,

        errorMessage: '',

        membershipType: null,
        membershipTypePaymentOption: null,
      }
    },
    props: ['membershipTypeId', 'membershipTypePaymentOptionId', 'cta', 'cssClasses'],
    computed: {
      ...mapGetters([
        'user',
        'classesCounter',
        'client'
      ]),
      normalizedCssClasses() {
        return this.cssClasses.replace(/,/g, ' ').replace(/ {2,}/g, ' ')
      },
      ctaWithDefault() {
        return this.cta || this.$t('global.buy')
      },
    },

    created() {
      this.getMembershipTypeFromApi()
    },

    mounted() {
      this.updateButtonClasses()
    },

    watch: {
      classesCounter() {
        this.getMembershipTypeFromApi()
      },
      user() {
        this.getMembershipTypeFromApi()
      },
    },

    methods: {

      async getMembershipTypeFromApi() {

        if (!this.membershipTypeId) {
          this.errorMessage = this.$t('priceButton.pleaseSpecifyMembershipTypeId')
          return
        }

        this.loading = true

        this.membershipType = (await YogoApi.get(
          '/membership-types' +
          '?id=' + this.membershipTypeId +
          '&populate[]=payment_options' +
          '&populate[]=user_has_membership_type' +
          '&populate[]=user_membership_id' +
          '&populate[]=max_number_of_memberships_reached',
        ))[0]
        if (!this.membershipType) {
          this.loading = false
          this.errorMessage = this.$t('priceButton.invalidMembershipTypeId')
          return
        }
        this.membershipType.payment_options = _filter(this.membershipType.payment_options, {for_sale: true})
        if (this.membershipType.payment_options.length === 1) {
          this.membershipTypePaymentOption = this.membershipType.payment_options[0]
        } else {
          if (!this.membershipTypePaymentOptionId) {
            this.membershipType = null
            this.loading = false
            this.errorMessage = this.$t('priceButton.pleaseSpecifyMembershipTypePaymentOption')
            return
          }
          this.membershipTypePaymentOption = _find(this.membershipType.payment_options, {id: parseInt(this.membershipTypePaymentOptionId)})
          if (!this.membershipTypePaymentOption) {
            this.membershipType = null
            this.loading = false
            this.errorMessage = this.$t('priceButton.invalidMembershipTypePaymentOptionId')
            return
          }
        }

        this.loading = false

        this.updateButtonClasses()

      },

      async buttonClick() {
        if (this.user) {

          let cartItem = {
            item_type: 'membership_type',
            item_id: this.membershipType.id,
            user: this.user.id,
            payment_option: this.membershipTypePaymentOption.id,
          }

          if (parseInt(this.membershipTypePaymentOption.number_of_months_payment_covers) === 1 && this.membershipType.active_campaign_id) {
            cartItem.membership_campaign = this.membershipType.active_campaign_id
          }

          await YogoApi.post('/cart-items', cartItem)

          YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'))

        } else {
          const cartItem = {
            itemType: 'membership_type',
            itemId: this.membershipType.id,
            paymentOption: this.membershipTypePaymentOption.id,
          }

          if (parseInt(this.membershipTypePaymentOption.number_of_months_payment_covers) === 1 && this.membershipType.active_campaign_id) {
            cartItem.membershipCampaign = this.membershipType.active_campaign_id
          }

          const loginWithCartRouteParams = {
            cartItemJson: JSON.stringify(cartItem),
          }
          YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(loginWithCartRouteParams)))
        }
      },

      openWaitlistEmailComposer(membershipType) {
        const url = 'mailto:' + this.client.email + '?subject=' + encodeURIComponent(this.$t('global.waitlistEmailSubject', {waitlistFor: membershipType.name}))
        window.open(url)
      },

      updateButtonClasses() {
        if (this.cssClasses) {
          this.$nextTick(() => {
            if (this.$refs.button && this.$refs.button.$el) {
              this.$refs.button.$el.className = this.normalizedCssClasses
            }
          })
        }
      },
      openCustomerMembershipForMembershipType(membershipType) {
        const path = '/system-route/Membership/' + encodeURIComponent(JSON.stringify({id: membershipType.user_membership_id}))
        YogoFrontend.open(path)
      },
    },

  }
</script>
