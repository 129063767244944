<template>
  <div class="yogo-heart cursor-pointer" @click="$emit('click',$event)">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        :fill="fill"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-heart"
    >
      <path
          d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Heart',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fill() {
      return this.filled ? this.color : 'none';
    },
  },
};
</script>

<style scoped lang="scss">

.yogo-heart {
  transition: .3s;
}

.yogo-heart:hover {
  transform: scale(1.1, 1.1);
}

</style>
