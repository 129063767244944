<template>
  <div class="yogo-flex yogo-items-center">
    <button class="yogo-p-2 yogo-flex-1 yogo-text-2xl" @click="decrement()">-</button>
    <input
      class="yogo-text-lg yogo-webkit-appearance-none yogo-mb-0 yogo-border-0 yogo-w-14 yogo-flex-1 yogo-font-bold yogo-text-center yogo-outline-none"
      type="number"
      min="0.00"
      disabled
      v-model="value"
    />
    <button class="yogo-p-2 yogo-flex-1 yogo-text-2xl" @click="increment()">+</button>
  </div>
</template>

<script>
export default {
  name: "YogoNumberInput",
  props: {
    value: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    increment() {
      if (this.value >= this.max) {
        return;
      } else {
        this.$emit('input', this.value + 1);
      }
    },
    decrement() {
      if (this.value <= this.min) {
        return;
      } else {
        this.$emit('input', this.value - 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

input[type="number"] {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
  }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
</style>
