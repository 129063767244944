module.exports = [
  'yogo.dk',
  'yogobooking.com',
  'yogobooking.de',
  'yogobooking.se',
  'yogo.no',
  'yogobooking.pt',
  'yogo-staging.dk',
  'yogobooking-staging.com',
];
