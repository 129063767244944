<template>
  <div class="theme__widget--padding" :class="{ 'padding': widgetPadding }">
    <slot></slot>
  </div>
</template>

<!-- <style lang="stylus" scoped>
  .padding
    padding 30px !important
</style> -->

<script>
  import { mapState } from 'vuex'

  export default {
    computed: mapState('themeSettings', [
      'widgetPadding'
    ])
  }
</script>
