import qs from 'qs';
import avatar from '@/assets/img/account.svg'

const buildURL = (filename, options) => {
  if (!filename){
    return ''
  }
  return 'https://' + process.env.VUE_APP_IMAGE_SERVER_IMGIX + '/' + filename + '?' + qs.stringify(options)
}

const profileImageSrc = (image, options) => {
  if (!image) {
    return avatar
  }
  // Accept both image objects and strings
  return buildURL(
    image.filename || image,
    options
  )
}

export default {
  methods: {
    Imgix: buildURL,
    profileImageSrc
  }
}


