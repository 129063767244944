const initialState = {
  widgetPadding: false
}

const state = () => {
  const settings = window && window.yogoWidgetSettings
    ? window.yogoWidgetSettings
    : false;

  if (settings) {
    let values = {}

    for (let key in initialState) {
      values[key] = settings.hasOwnProperty(key) ? settings[key] : initialState[key]
    }

    return values
  }

  return { ...initialState }
}

export default {
  namespaced: true,
  state,
}
