<template>
  <VueJSDatepicker
      v-model="localJSDate"
      v-if="!isMobile"
      :disabled-dates="disabledDates"
      :language="language"
      :monday-first="true"
      class="datepicker"
  >
  </VueJSDatepicker>

  <input
      v-else
      v-model="localISODate"
      type="date"
      :min="todayISO"
  >

</template>
<script>

import device from '@/mixins/device.vue';
import VueJSDatepicker from 'vuejs-datepicker';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { da, en, nbNO, sv, de } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'Datepicker',
  components: { VueJSDatepicker },
  mixins: [device],
  data() {
    return {
      localJSDate: new Date(),
      localISODate: DateTime.now().toISODate(),
    };
  },
  props: ['value', 'disabledDates'],
  computed: {
    ...mapGetters([
      'client',
      'locale',
    ]),
    language() {
      const languages = {
        en, da, sv, nb: nbNO, de
      };
      return languages[this.locale];
    },
    todayISO() {
      return this.client
          ? DateTime.now().setZone(this.client.timezone).toISODate()
          : DateTime.now().toISODate();
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.localJSDate = DateTime.fromISO(newValue).toJSDate();
        this.localISODate = newValue;
      },
      immediate: true,
    },
    localJSDate(newValue) {
      console.log('Date from vuejs-datepicker', newValue);
      const emitDate = DateTime.fromJSDate(newValue).toISODate();
      console.log('<Datepicker> emitting date:', emitDate);
      this.$emit(
          'input',
          emitDate,
      );
    },
    localISODate(newValue) {
      this.$emit(
          'input',
          newValue,
      );
    },
  },

};
</script>
