<template>

  <div>

    <md-dialog :md-active="showClassDialog" v-on:update:mdActive="updateShowClassDialog">
      <loadingspinner v-if="loading"></loadingspinner>
      <template v-else>
        <a class="close-signup-modal" @click="updateShowClassDialog(false)">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                 stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6"
                                                                         y2="18"></line><line x1="6"
                                                                                              y1="6"
                                                                                              x2="18"
                                                                                              y2="18"></line></svg>
          </span>
        </a>
        <div class="yogo-modal-content">

          <div class="yogo-flex-md yogo-pt-2">
            <div class="yogo-flex-1 yogo-mr-2">

              <div
                  class="yogo--h1"
                  :class="{ 'mb2' : !dialogClass.subtitle }"
              >
                {{ dialogClass.class_type.name }}{{
                  dialogClass.cancelled ? ' - ' + $t('global.cancelled')
                      : ''
                }}
              </div>

              <div v-if="dialogClass.subtitle" class="yogo--h2 yogo-mb-2">
                {{ dialogClass.subtitle }}
              </div>

              <div class="yogo--h3">
                {{ formatDate(dialogClass.date, {weekday: true, capitalize: true}) }}<br/>
                {{ formatTime(dialogClass.start_time) }} - {{ formatTime(dialogClass.end_time) }}
              </div>
              <div
                  v-if="client.settings.calendar_show_room && dialogClass.room"
                  class="yogo-font-bold"
              >
                {{ dialogClass.room.name }}
              </div>
              <div class="yogo-mt-2" v-if="dialogClass.branchName">{{ $t('global.Location') }}:
                {{ dialogClass.branchName }}<br/><br/></div>
              <div v-if="dialogClass.capacity_text !== ''">{{ dialogClass.capacity_text }}<br/><br/>
              </div>

              <div
                  v-if="dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream).length"
                  class="yogo-bg-gray-50 yogo-inline-block-md yogo-rounded yogo-p-4 yogo-mt-2"
              >
                <div
                    class="yogo-mb-2 yogo-font-bold yogo-flex yogo-flex-wrap yogo-items-center yogo-text-green-700">
                  <TwoCirclesIcon class="yogo-mr-1"></TwoCirclesIcon>
                  {{ $t('calendar.classIsPartOfClassSeries') }}
                </div>
                <div
                    v-for="classSeriesType in dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream)"
                    :key="dialogClass.id + 'class_series_type_' + classSeriesType.id"
                    class="yogo-mr-2 yogo-mt-1 yogo-mb-2 yogo-text-left"
                >
                  <div
                      v-if="classSeriesType.has_started"
                  >
                    <span class="yogo-font-bold">
                    {{ classSeriesType.name }}
                    </span>
                    <span
                        class="yogo-block"
                        v-if="classSeriesType.has_started && classSeriesType.is_available_for_purchase"
                    >
                     ({{
                        $t('global.ReducedPriceNClassesRemaining',
                            { n: classSeriesType.number_of_remaining_classes_and_livestream_classes })
                      }})
                    </span>
                  </div>
                  <div
                      v-else
                      class="yogo-font-bold"
                  >
                    {{ classSeriesType.name }}
                  </div>
                  <div v-if="classSeriesType.is_available_for_purchase">
                    {{ formatCurrency(classSeriesType.current_price) }}
                  </div>
                  <div
                      v-if="classSeriesType.customer_has_class_series_type"
                  >
                    {{ $t('prices.YouAlreadyHaveThisClassSeries') }}
                  </div>
                </div>
              </div>

              <DialogTeacherInfo :teachers="dialogClass.teachers"></DialogTeacherInfo>
            </div>

            <div class="yogo-flex yogo-flex-1 yogo-items-start">
              <img class="modal__image--standard"
                   :src="Imgix(dialogClass.class_type.image.filename, {w:900,h:600, fit:'crop'})"
                   alt=""
                   v-if="dialogClass.class_type.image"
              >
            </div>

          </div>

          <div class="yogo-line yogo-my-4"></div>

          <DialogAttendeesInfo
            class="my-4"
            v-if="user && client.settings.calendar_show_public_class_attendee_list && dialogClass.studio_attendance_enabled"
            :attendees="dialogClass.public_attendee_list"
          >
          </DialogAttendeesInfo>

          <nl2br tag="div" :text="dialogClass.class_type.description"></nl2br>

        </div>

        <md-dialog-actions class="yogo-flex-wrap yogo-items-end">

          <div
              v-if="dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream).length"
              class="yogo-w-full yogo-w-auto-md yogo-mr-12 yogo-mr-0-md"
          >
            <div
                v-for="classSeriesType in dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream)"
                :key="dialogClass.id + 'class_series_type_' + classSeriesType.id"
            >
              <button
                  @click="buyClassSeriesType(classSeriesType)"
                  v-if="classSeriesType.is_available_for_purchase
                && !(user && !classSeriesType.customer_can_buy_class_series_type)"
                  class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              >
                {{ $t('global.buy') + ' ' + classSeriesType.name }}
              </button>
            </div>
          </div>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              disabled
              v-if="dialogClass.cancelled"
          >
            {{ $t('global.classIsCancelled') }}
          </button>

          <button class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
                  disabled
                  v-else-if="dialogClass.user_is_signed_up_for_class">
            {{ $t('global.youAreSignedUp') }}
          </button>

          <span v-if="currentlyEditingSignupCount" class="yogo-flex yogo-w-full yogo-w-auto-md">
               <YogoNumberInput
                   v-if="currentlyEditingSignupCount"
                   v-model="userSignupCount"
                   :min="0"
                   :max="dialogClass.seats - dialogClass.signup_count + dialogClass.user_signup_count"
               ></YogoNumberInput>
              <button @click.prevent="changeSignupCountSave"
                      class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-mr-2-md">
                {{ $t('global.Ok') }}
              </button>
            </span>

          <span v-else-if="client.settings.customer_can_reserve_multiple_seats_for_class
            && dialogClass.user_is_signed_up_for_class"
                class="yogo-w-full yogo-w-auto-md yogo-mr-2">
              <button @click.prevent="changeSignupCountClick"
                      class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-mr-2-md yogo-w-full yogo-w-auto-md">
                {{
                  $tc('global.NSeats',
                      dialogClass.user_signup_count,
                      { n: dialogClass.user_signup_count })
                }}
                |
                {{ $t('global.Change') }}
              </button>
            </span>

          <button class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
                  disabled
                  v-else-if="dialogClass.user_is_signed_up_for_livestream">
            {{ $t('global.youAreSignedUpForLivestream') }}
          </button>

          <button class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
                  disabled
                  v-else-if="dialogClass.user_is_signed_up_for_waiting_list">
            {{
              $t('global.youAreNumberXOnTheWaitlist',
                  { number: dialogClass.user_number_on_waiting_list })
            }}
          </button>

          <button class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
                  disabled
                  v-else-if="dialogClass.class_has_started"
          >
            {{ $t('global.classHasStarted') }}
          </button>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              disabled
              v-else-if="dialogClass.class_is_fully_booked">
            {{ $t('global.fullyBooked') }}
          </button>


          <div
              class="yogo-mr-2 yogo-w-full yogo-w-auto-md yogo-mb-2 yogo-mt-1 yogo-flex yogo-justify-center">
            <YogoNumberInput
                v-if="!dialogClass.user_is_signed_up_for_class && client.settings.customer_can_reserve_multiple_seats_for_class && dialogClass.class_accepts_customer_signups"
                v-model="userSignupCount"
                :min="1"
                :max="dialogClass.seats - dialogClass.signup_count"
            ></YogoNumberInput>
          </div>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              @click="signUpForClass(dialogClass)"
              v-if="showSignupButton"
          >
            <span
                v-if="dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream).length">
            {{ $t('global.signUpForThisClassOnly') }}
            </span>
            <span v-else>
            {{ $t('global.signup') }}
            </span>
          </button>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              v-if="dialogClass.user_can_sign_off_from_class"
              @click="signOffFromClass(dialogClass)"
          >
            <span v-if="dialogClass.user_signup_count > 1">{{
                $t('global.cancelNBookings',
                    { n: dialogClass.user_signup_count })
              }}</span>
            <span v-else>{{ $t('global.cancelBooking') }}</span>

          </button>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              @click="signUpForWaitingList(dialogClass)"
              v-if="showWaitingListSignupButton"
          >
            {{ $t('global.joinWaitlist') }}
          </button>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              v-if="dialogClass.user_can_sign_off_from_waiting_list"
              @click="signOffFromWaitingList(dialogClass)"
          >
            {{ $t('global.removeFromWaitlist') }}
          </button>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              @click="signUpForLivestream(dialogClass)"
              v-if="showLivestreamSignupButton"
          >
            <span
                v-if="dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream).length">
              {{ $t('global.SignUpForLivestreamThisClassOnly') }}
            </span>
            <span v-else>
              {{ $t('global.signUpForLivestream') }}
            </span>
          </button>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              v-if="dialogClass.user_can_sign_off_from_livestream"
              @click="signOffFromLivestream(dialogClass)"
          >
            {{ $t('global.cancelLivestream') }}
          </button>

          <button
              class="yogo-btn yogo-btn-primary yogo-mb-2 yogo-mt-1 yogo-w-full yogo-w-auto-md"
              v-if="dialogClass.user_can_start_livestream"
              @click="startLivestream(dialogClass)"
          >
            {{ $t('global.startLivestream') }}
          </button>

        </md-dialog-actions>
      </template>
    </md-dialog> <!-- modal -->

    <ClassSignup
        :user="user"
        ref="classSignupComponent"
        @classSignupSuccess="onClassSignupSuccess"
        @classSignoffSuccess="onClassSignoffSuccess"
        @classWaitingListSignupSuccess="onClassWaitingListSignupSuccess"
        @classWaitingListSignoffSuccess="onClassWaitingListSignoffSuccess"
        @classLivestreamSignupSuccess="onClassLivestreamSignupSuccess"
        @classLivestreamSignoffSuccess="onClassLivestreamSignoffSuccess"
    ></ClassSignup>

  </div>
</template>

<script>

import ClassSignup from '@/components/ClassSignup.vue';
import DialogTeacherInfo from "./DialogTeacherInfo";
import DialogAttendeesInfo from "./DialogAttendeesInfo";

import LoadingSpinner from './LoadingSpinner.vue';
import currencyFormatters from '@/mixins/currencyFormatters';

import { mapGetters } from 'vuex';

import Imgix from '../services/Imgix';
import YogoApi from '../gateways/YogoApi';
import YogoFrontend from "../gateways/YogoFrontend";
import TwoCirclesIcon from "@/graphics/icons/TwoCirclesIcon";
import YogoNumberInput from '@/components/ui/YogoNumberInput';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';


export default {
  components: {
    ClassSignup,
    DialogTeacherInfo,
    DialogAttendeesInfo,
    loadingspinner: LoadingSpinner,
    TwoCirclesIcon,
    YogoNumberInput: YogoNumberInput,
  },
  data() {
    return {
      loading: false,
      showTeacherInfo: [],
      userSignupCount: 1,
      currentlyEditingSignupCount: false,
    };
  },
  mixins: [Imgix, currencyFormatters, dateTimeFunctions],
  props: [
    'showClassDialog',
    'dialogClass',
    'widgetOnlyLivestream'
  ],
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userName',
      'client',
      'clientLogoFilename',
      'user',
      'stateReady',
      'classSignoffDeadlineInHumanFormat',
    ]),
    showSignupButton() {
      // If the user is already signed up, there is another counter for increasing number of seats
      return this.dialogClass.class_accepts_customer_signups
          && !this.dialogClass.user_is_signed_up_for_class
          && !this.dialogClass.user_is_signed_up_for_livestream
          && !this.dialogClass.user_is_signed_up_for_waiting_list
          && (
              !this.dialogClass.class_is_part_of_locked_class_series_type
              || this.dialogClass.user_can_sign_up_for_class_locked_by_class_series
          )
          && !this.widgetOnlyLivestream
          ;
    },
    showWaitingListSignupButton() {
      return this.dialogClass.class_accepts_waiting_list_signups
          && !this.dialogClass.user_is_signed_up_for_class
          && !this.dialogClass.user_is_signed_up_for_waiting_list
          && !this.dialogClass.user_is_signed_up_for_livestream
          && !this.widgetOnlyLivestream;
    },
    showLivestreamSignupButton() {
      return this.dialogClass.class_accepts_livestream_signups
          && !this.dialogClass.user_is_signed_up_for_class
          && !this.dialogClass.user_is_signed_up_for_waiting_list
          && !this.dialogClass.user_is_signed_up_for_livestream;
    },
  },
  methods: {
    updateShowClassDialog(showClassDialog) {
      this.$emit('update:showClassDialog', showClassDialog);
    },

    async signUpForClass(classItem) {
      this.$emit('update:showClassDialog', false);
      this.$refs.classSignupComponent.changeNumberOfSignupsForClass(classItem,
          this.userSignupCount);
    },

    async onClassSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    async signOffFromClass(classItem) {
      this.$emit('update:showClassDialog', false);
      if (classItem.user_is_signed_up_for_livestream) {
        this.$refs.classSignupComponent.$emit('signOffFromClass', classItem);
      } else {
        await this.$refs.classSignupComponent.changeNumberOfSignupsForClass(classItem, 0);
      }
    },
    async onClassSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signUpForWaitingList(classItem) {
      this.$refs.classSignupComponent.$emit('signUpForWaitingList', classItem);
    },
    async onClassWaitingListSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signOffFromWaitingList(classItem) {
      this.$refs.classSignupComponent.$emit('signOffFromWaitingList', classItem);
    },
    async onClassWaitingListSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signUpForLivestream(classItem) {
      this.$refs.classSignupComponent.$emit('signUpForLivestream', classItem);
    },
    async onClassLivestreamSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signOffFromLivestream(classItem) {
      this.$refs.classSignupComponent.$emit('signOffFromLivestream', classItem);
    },
    async onClassLivestreamSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    startLivestream(classItem) {
      this.$emit('startLivestream', classItem);
      this.$emit('update:showClassDialog', false);
    },


    openPrices() {
      YogoFrontend.open('/system-route/Prices/' + encodeURIComponent(JSON.stringify({})));
      this.$emit('update:showClassDialog', false);
    },

    async buyClassSeriesType(classSeriesType) {

      if (this.user) {

        this.loading = true;

        let cartItem = {
          item_type: 'class_series_type',
          item_id: classSeriesType.id,
          user: this.user.id,
        };

        await YogoApi.post('/cart-items', cartItem);

        YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'));
        this.loading = false;

      } else {
        const cartItem = {
          itemType: 'class_series_type',
          itemId: classSeriesType.id,
        };
        const loginWithCartRouteParams = {
          cartItemJson: JSON.stringify(cartItem),
        };
        YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(
            loginWithCartRouteParams)));
      }

    },

    changeSignupCountClick() {
      this.currentlyEditingSignupCount = true;
    },

    async changeSignupCountSave() {
      await this.$refs.classSignupComponent.changeNumberOfSignupsForClass(this.dialogClass,
          this.userSignupCount);
    },

  },
  watch: {
    showClassDialog(newShowClassDialog) {
      if (newShowClassDialog) {
        this.currentlyEditingSignupCount = false;
        if (this.dialogClass.user_is_signed_up_for_class) {
          this.userSignupCount = this.dialogClass.user_signup_count;
        } else {
          this.userSignupCount = 1;
        }
      } else {
        this.showTeacherInfo = [];
      }
    },
  },
};

</script>

<style lang="scss">
.no-border {
  box-shadow: none;
}

.slide-fade-enter-active {
  transition: all .3s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-active {
  transition: all .2s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

.modal__teacher--info .icon-arrowdown {
  font-size: 0.8em;
}
</style>
