<template>
  <div class="yogo-widget">
    <div class="theme--frame-box">

      <widgetPadding>

        <loadingspinner v-if="loading"></loadingspinner>

        <div v-else>

          <div class="space8"></div>
          <loginstatus></loginstatus>

          <ul>

            <li v-for="(product, productIdx) in products" class="yogo-product"
                :key="'product_' + product.id">
              <div class="flex__grid--thirds align--top">
                <div class="flex__1-3--margin">
                  <img
                      :src="Imgix(product.image.filename, {h:320,w:320, fit:'crop'})"
                      alt=""
                      v-if="product.image">
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">
                  <h4>{{ product.name }}</h4>
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">

                  <p :class="{bold:!product.has_member_discount, strikethrough: product.has_member_discount}"
                     class="upper">
                    {{ formatCurrency(product.price) }}
                  </p>
                  <p v-if="product.has_member_discount" class="bold upper">
                    {{ formatCurrency(product.price_with_member_discount) }}
                  </p>

                  <select class="yogo-select" v-model="productCount[productIdx]">
                    <option default>0</option>
                    <option v-for="n in 10" :value="n">
                      {{ n }}
                    </option>
                  </select>

                </div>
              </div>
              <div class="space4"></div>
            </li>

          </ul>
          <div class="space8"></div>
          <button
              @click="buyProducts"
              class="yogo-btn yogo-btn-primary w-100">
            {{ $t('global.buy') }}
          </button>
        </div>

        <div class="space8"></div>

      </widgetPadding>

    </div>
  </div>
</template>

<script>

import LoadingSpinner from './LoadingSpinner.vue';
import YogoApi from '../gateways/YogoApi';
import YogoFrontend from '../gateways/YogoFrontend';
import { mapGetters } from 'vuex';
import WidgetPadding from './WidgetPadding';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import LoginStatus from './LoginStatus.vue';
import Imgix from '../services/Imgix';
import currencyFormatters from '@/mixins/currencyFormatters';


export default {
  name: 'products',
  components: {
    loadingspinner: LoadingSpinner,
    loginstatus: LoginStatus,
    widgetPadding: WidgetPadding,
  },
  mixins: [Imgix, currencyFormatters],
  data() {
    return {
      products: [],
      productCount: [],
      loading: true,
      initialized: false,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'client',
      'stateReady',
      'productResetCounter',
    ]),
  },
  mounted: function () {
    this.fetchProducts();
  },
  watch: {
    stateReady(newReadyState) {
      if (newReadyState && this.initialized) {
        this.loading = false;
      }
    },
  },
  methods: {
    async fetchProducts() {

      this.loading = true;

      this.products = await YogoApi.get('/products' +
          '?populate[]=image' +
          '&populate[]=price_with_member_discount',
      );

      this.productCount = Array(this.products.length).fill(0);

      this.initialized = true;
      if (this.stateReady) {
        this.loading = false;
      }
    },
    async buyProducts() {

      let cartItems = _map(
          this.products,
          (product, idx) => {
            return {
              item_type: 'product',
              item_id: product.id,
              item_count: this.productCount[idx],
              user: this.user ? this.user.id : null,
            };
          });

      cartItems = _filter(cartItems, cartItem => cartItem.item_count);

      if (!cartItems.length) return;

      setTimeout(
          () => {
            this.productCount.fill(0);
            this.$forceUpdate();
          },
          2000,
      );

      if (this.user) {

        this.loading = true;

        await Promise.all(_map(cartItems, cartItem => YogoApi.post('/cart-items', cartItem)));

        YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'));
        this.loading = false;

      } else {

        const loginWithCartRouteParams = {
          cartItemJson: JSON.stringify(cartItems),
        };
        YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(
            loginWithCartRouteParams)));
      }
    },

    async buyAllSelectedProducts() {

    },
  },
};

</script>

<style lang="stylus" scoped>

.yogo-products
  img
    width 160px
    height auto

</style>
