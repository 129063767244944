<template>
  <div class="yogo-widget">
    <div class="theme--frame-box">

      <widgetPadding>


        <loadingspinner v-if="loading"></loadingspinner>

        <div v-else>

          <div class="space8"></div>
          <loginstatus></loginstatus>


          <div class="flex__1-3--margin yogo-input" v-if="visiblePriceGroups.length > 1">

            <select name="priceGroup" v-model="selectedPriceGroup">
              <option v-for="priceGroup in visiblePriceGroups" :value="priceGroup.id"
                      :key="'price_group_' + priceGroup.id">
                {{ priceGroup.name }}
              </option>
            </select>
            <div class="space4"></div>
            <div class="space8"></div>
          </div>

          <div v-if="showPricesDropdown && sortedPriceItems.length > 0" class="flex__1-3--margin yogo-input yogo-prices-input">
            <select name="price" v-model="selectedPriceItem">
              <!--If showPricesAllOption is true then show an "All" option as the first option.-->
              <option v-if="showPricesAllOption" :value="null"> - {{$t('prices.allPricesFilter')}} - </option>
              <option v-for="priceItem in sortedPriceItems" :value="priceItem" :key="'price_' + priceItem.id">
                {{ priceItem.name }}
              </option>
            </select>
            <div class="space4"></div>
            <div class="space8"></div>
          </div>

          <ul>

            <!-- Either loop over an artificial array around the selectedPriceItem or all of the sortedPriceItems if none has been selected -->
            <li v-for="(priceItem, idx) in (selectedPriceItem && selectedPriceItem.id ? [selectedPriceItem] : sortedPriceItems)" :key="'price_' + selectedPriceGroup + '_' + idx">

              <div class="flex__grid--thirds align--top yogo-border-b yogo-py-5 yogo-py-10-md"
                   v-if="priceItem.priceItemType === 'gift_card'">
                <div class="flex__1-3--margin">
                  <img :src="Imgix(giftCardPricesImage.filename, { w: 960, h: 960, fit: 'crop' })"
                       alt=""
                       v-if="giftCardPricesImage">
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">
                  <div class="yogo--h3 yogo-mb-2">{{ client.settings.gift_card_prices_title }}</div>
                  <nl2br tag="div" :text="client.settings.gift_card_prices_description"></nl2br>
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">
                  <button @click="buyGiftCard()" class="yogo-btn yogo-btn-primary">
                    {{$t('global.buy')}}
                  </button>
                </div>
              </div>


              <div class="flex__grid--thirds align--top yogo-border-b yogo-py-5 yogo-py-10-md"
                   v-if="priceItem.priceItemType === 'membership_type'">
                <div class="flex__1-3--margin">
                  <img :src="Imgix(priceItem.image.filename, { w: 960, h: 960, fit: 'crop' })"
                       alt=""
                       v-if="priceItem.image">
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">
                  <div class="yogo--h3 yogo-mb-2">{{ priceItem.name }}</div>
                  <ReadMore :text="priceItem.description" />
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">

                  <div v-if="priceItem.payment_options.length > 1">
                    <div class="yogo-input yogo-mb-2">
                      <label>{{ $t('global.paymentOptions') }}</label>
                      <select v-model="selectedPaymentOption[priceItem.id]"
                              @change="$forceUpdate()">
                        <option v-for="paymentOption in priceItem.payment_options"
                                :value="paymentOption.id"
                                :key="'payment_option_' + paymentOption.id">
                          {{ paymentOption.nameThatUserSees }}
                        </option>
                      </select>
                    </div>
                    <p class="yogo-font-bold">
                      {{
                        getSelectedMembershipTypePaymentOption(priceItem).priceTextThatUserSees
                      }}
                    </p>
                  </div>

                  <div v-else>
                    <label>{{ $t('global.payment') }}:
                      {{ priceItem.payment_options[0].nameThatUserSees }}</label>
                    <p class="yogo-font-bold">
                      {{ priceItem.payment_options[0].priceTextThatUserSees }}
                    </p>
                  </div>

                  <div class="customer-has-this" v-if="priceItem.user_has_membership_type">
                    {{ $t('global.haveMembership') }}
                    <br>
                    <a href="#" @click.prevent="openCustomerMembershipForMembershipType(priceItem)">
                      {{ $t('global.seeMembership') }}</a>
                  </div>

                  <div
                      v-else-if="priceItem.max_number_of_memberships_reached">
                    <a
                        :href="'mailto:' + client.email + '?subject=Venteliste%20til%20' + encodeURIComponent(priceItem.name)"
                    >
                      {{ $t('global.waitlist') }}
                    </a>
                  </div>

                  <div v-else>
                    <button @click="buyProduct('membership_type', priceItem.id)"
                            class="yogo-btn yogo-btn-primary yogo-my-2">
                      {{$t('global.buy')}}
                    </button>
                  </div>


                </div>
              </div>


              <div class="flex__grid--thirds align--top yogo-border-b yogo-py-5 yogo-py-10-md"
                   v-if="priceItem.priceItemType === 'class_pass_type'">
                <div class="flex__1-3--margin">
                  <img :src="Imgix(priceItem.image.filename, { w: 960, h: 960, fit: 'crop' })"
                       alt=""
                       v-if="priceItem.image">
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">
                  <div class="yogo--h3 yogo-mb-2">{{ priceItem.name }}</div>
                  <ReadMore :text="priceItem.description" />
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">

                  <span class="yogo-font-bold yogo-mb-2">
                    <span :class="{strikethrough: priceItem.has_member_discount}">{{
                        formatCurrency(priceItem.price)
                      }}</span>
                    <span v-if="priceItem.has_member_discount">
                      <br>{{ formatCurrency(priceItem.price_with_member_discount) }}
                    </span>
                  </span>

                  <div class="list__space--between">
                    <button @click="buyProduct('class_pass_type', priceItem.id)"
                            class="yogo-btn yogo-btn-primary"
                            v-if="!user || priceItem.user_can_buy">
                      {{$t('global.buy')}}
                    </button>
                    <div v-if="user && priceItem.max_number_per_customer_already_used">
                      {{ $t('global.cantBuyMoreOfType') }} {{
                        priceItem.pass_type === 'fixed_count' ?
                            $t('global.classPass') :
                            $t('global.seasonPass')
                      }}
                    </div>
                  </div>


                </div>
              </div>


              <div class="flex__grid--thirds align--top yogo-border-b yogo-py-5 yogo-py-10-md"
                   v-if="priceItem.priceItemType === 'class_series_type'">
                <div class="flex__1-3--margin">
                  <img :src="Imgix(priceItem.image.filename, { w: 960, h: 960, fit: 'crop' })"
                       alt=""
                       v-if="priceItem.image">
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">
                  <div class="yogo--h3 yogo-mb-2">{{ priceItem.name }}</div>
                  <ReadMore :text="priceItem.description" />
                </div>
                <div class="space4"></div>
                <div class="flex__1-3--margin">

                  <div class="yogo-font-bold yogo-mb-2">
                    <span :class="{strikethrough: priceItem.has_member_discount}">{{
                        formatCurrency(priceItem.is_available_for_purchase ? priceItem.current_price :
                            priceItem.price)
                      }}</span>
                    <span v-if="priceItem.has_member_discount">
                      <br>{{ formatCurrency(priceItem.current_price_with_member_discount) }}
                    </span>
                    <div v-if="priceItem.has_started && priceItem.is_available_for_purchase">
                      ({{
                        $t('global.ReducedPriceNClassesRemaining',
                            { n: priceItem.number_of_remaining_classes_and_livestream_classes })
                      }})
                    </div>
                  </div>

                  <div class="space2"></div>
                  <div v-if="priceItem.displayClasses.length">
                    <YogoShowLessWrapper :rows="priceItem.displayClasses">
                      <template slot="default" slot-scope="slotProps">
                        <div class="min-w-full divide-y divide-gray-300 flex--row"
                             v-for="classObj in slotProps.rows"
                             :key="classObj.id">
                          <div class="yogo-leading-loose yogo-flex yogo-items-center">
                            <span class="yogo-mr-2">
                              {{
                                formatDate(`${classObj.date} ${classObj.start_time}`,
                                    { weekday: true, time: true })
                              }}
                            </span>
                            <span :data-tool-tip="$t('global.Livestream')"
                                  v-if="classObj.isLivestreamClass"
                                  class="yogo-w-4 yogo-h-4 yogo-tool-tip relative">
                              <ScreenIcon/>
                            </span>
                          </div>
                        </div>
                      </template>
                    </YogoShowLessWrapper>

                  </div>
                  <div v-else>
                    {{ $t('global.NoClasses') }}
                  </div>

                  <div class="space2"></div>

                  <div>
                    <button @click="buyProduct('class_series_type', priceItem.id)"
                            class="yogo-btn yogo-btn-primary" v-if="priceItem.is_available_for_purchase
                      && !(user && !priceItem.customer_can_buy_class_series_type)">
                      {{$t('global.buy')}}
                    </button>
                    <button @click="buyProduct('class_series_type_first_installment', priceItem.id)"
                            class="yogo-btn yogo-btn-primary" v-if="showClassSeriesInstallmentPaymentOption(priceItem)">
                      {{ $t('prices.classSeries.SignupPayInInstallments') }}
                    </button>
                    <div>
                      <a
                          v-if="showClassSeriesInstallmentPaymentOption(priceItem)"
                          href="#"
                          @click.prevent="showClassSeriesInstallments(priceItem)"
                          class="yogo-underline"
                      >
                        {{ $t('event.ShowInstallments') }}
                      </a>
                    </div>
                    <div v-if="priceItem.customer_has_class_series_type" class="yogo-font-bold">
                      {{ $t('global.YouAlreadyHaveThisClassSeries') }}
                    </div>
                    <div class="yogo-font-bold"
                         v-if="!priceItem.is_available_for_purchase && priceItem.fully_booked_remaining_classes.length > 0">
                      {{ $t('global.FullyBooked') }}
                    </div>
                    <div v-else-if="priceItem.has_started && !priceItem.is_available_for_purchase"
                         class="yogo-font-bold">
                      {{ $t('global.ClassSeriesHasStarted') }}
                    </div>
                  </div>


                </div>
              </div>

            </li>

          </ul>
        </div>

        <div class="space8"></div>

        <md-dialog-alert :md-content="alertDialogText" :md-confirm-text="alertDialogConfirmText"
                         :md-active.sync="showAlertDialog"/>

      </widgetPadding>

    </div>

    <md-dialog :md-active.sync="showClassSeriesInstallmentDialog" v-if="classSeriesInstallmentDialogClassSeries">
      <md-dialog-title>{{
          classSeriesInstallmentDialogClassSeries ? classSeriesInstallmentDialogClassSeries.name : ''
        }}
      </md-dialog-title>
      <md-dialog-content>
        <div>
          <h3>{{ $t('event.Installments') }}</h3>
          <div class="inline-block min-w-full py-2 align-middle">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                <tr>
                  <th class="py-3.5 pl-4 yogo-pr-4 yogo-text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {{ $t('global.Date') }}
                  </th>
                  <th class="px-3 py-3.5 yogo-text-right text-sm font-semibold text-gray-900">{{
                      $t('global.Amount')
                    }}
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(row, idx) in classSeriesInstallmentDialogRows" :key="'installment_' + idx">
                  <td class="whitespace-nowrap yogo-text-left py-4 pl-4 yogo-pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                    {{ row.date }}
                  </td>
                  <td class="whitespace-nowrap yogo-text-right py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <span :class="{strikethrough: row.has_member_discount}">{{
                          row.amount
                        }}</span>
                    <span v-if="row.has_member_discount"><br>{{ row.amount_with_member_discount }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </md-dialog-content>
    </md-dialog>

  </div>
</template>

<script>

import LoadingSpinner from './LoadingSpinner.vue';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _includes from 'lodash/includes';
import _concat from 'lodash/concat';
import _isInteger from 'lodash/isInteger';
import YogoApi from '../gateways/YogoApi';
import YogoFrontend from '../gateways/YogoFrontend';
import { mapGetters } from 'vuex';
import WidgetPadding from './WidgetPadding';
import ReadMore from '@/components/ReadMore.vue';

import LoginStatus from './LoginStatus.vue';
import YogoShowLessWrapper from './ui/YogoShowLessWrapper.vue';

import Imgix from '../services/Imgix';
import dateTimeFunctions from '../mixins/dateTimeFunctions';
import currencyFormatters from '@/mixins/currencyFormatters';
import ScreenIcon from '@/graphics/icons/ScreenIcon';
import htmlUtils from '@/mixins/htmlUtils.vue';

export default {
  mixins: [Imgix, dateTimeFunctions, currencyFormatters, htmlUtils],
  components: {
    ScreenIcon,
    loadingspinner: LoadingSpinner,
    loginstatus: LoginStatus,
    widgetPadding: WidgetPadding,
    YogoShowLessWrapper,
    ReadMore
  },
  data() {
    return {
      membershipTypes: [],
      classPassTypes: [],
      classSeriesTypes: [],
      priceGroups: [],
      selectedPriceGroup: null,
      selectedPriceItem: null,
      loading: true,

      selectedPaymentOption: [],

      showAlertDialog: false,
      alertDialogText: '',
      alertDialogConfirmText: '',

      initialized: false,

      giftCardPricesImage: null,

      showClassSeriesInstallmentDialog: false,
      classSeriesInstallmentDialogClassSeries: null,
    };
  },
  props: {
    showSinglePriceGroup: {
      type: String,
      required: false
    },
    showPricesDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    showPricesAllOption: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    ...mapGetters([
      'user',
      'client',
      'stateReady',
      'classesCounter',
    ]),
    // YogoApi: YogoApi,
    visiblePriceGroups() {
      if (this.showSinglePriceGroup) {
        return _filter(
            this.priceGroups,
            pg => pg.name.toLocaleLowerCase() === this.showSinglePriceGroup.toLocaleLowerCase(),
        );
      }
      return _filter(this.priceGroups, priceGroup => priceGroup.show_in_default_price_list);
    },
    filteredMembershipTypes() {
      if (this.showSinglePriceGroup) return this.membershipTypes; // Only the relevant membership types have been fetched from the server
      // No priceGroup specified for widget. Displaying default price list.
      if (!this.priceGroups.length) return this.membershipTypes;
      if (!this.selectedPriceGroup) return [];

      return _filter(
          this.membershipTypes,
          membershipType => _includes(
              _map(membershipType.price_groups, 'id'),
              this.selectedPriceGroup,
          ),
      );
    },

    filteredClassPassTypes() {
      if (this.showSinglePriceGroup) return this.classPassTypes; // Only the relevant class pass types have been fetched from the server
      // No priceGroup specified for widget. Displaying default price list.
      if (!this.priceGroups.length) return this.classPassTypes;
      if (!this.selectedPriceGroup) return [];
      return _filter(this.classPassTypes,
          classPassType => _includes(_map(classPassType.price_groups, 'id'),
              this.selectedPriceGroup));
    },
    filteredClassSeriesTypes() {
      // Only the relevant class series types are fetched from the server if the widget is for
      // a single price group
      if (this.showSinglePriceGroup) return this.classSeriesTypes;

      // No priceGroup specified for widget. Displaying default price list.
      if (!this.priceGroups.length) return this.classSeriesTypes;
      if (!this.selectedPriceGroup) return [];
      return _filter(this.classSeriesTypes,
          classSeriesType => _includes(_map(classSeriesType.price_groups, 'id'),
              this.selectedPriceGroup));
    },
    sortedPriceItems() {
      if (!this.priceGroups.length) {
        return this.sortedPriceItemsWithoutPriceGroups;
      }
      const priceGroup = _find(this.priceGroups,
          pg => parseInt(pg.id) === parseInt(this.selectedPriceGroup));
      if (!priceGroup) return [];
      const membershipTypes = _map(this.filteredMembershipTypes, mt => {
        mt.sort_in_price_group = _find(priceGroup.membership_types,
            { id: mt.id })?.sort_in_price_group;
        mt.priceItemType = 'membership_type';
        return mt;
      });
      const classPassTypes = _map(this.filteredClassPassTypes, cpt => {
        cpt.sort_in_price_group = _find(priceGroup.class_pass_types,
            { id: cpt.id })?.sort_in_price_group;
        cpt.priceItemType = 'class_pass_type';
        return cpt;
      });
      const classSeriesTypes = _map(this.filteredClassSeriesTypes, cst => {
        cst.sort_in_price_group = _find(priceGroup.class_series_types,
            { id: cst.id })?.sort_in_price_group;
        cst.priceItemType = 'class_series_type';
        return cst;
      });
      const unsortedPrices = _concat(
          membershipTypes,
          classPassTypes,
          classSeriesTypes,
      );
      const giftCardSettings = this.client.settings.gift_card_show_in_price_groups;
      if (_isInteger(giftCardSettings[0])) {
        if (giftCardSettings.indexOf(this.selectedPriceGroup) > -1) {
          unsortedPrices.push({ priceItemType: 'gift_card', sort_in_price_group: 0 });
        }
      } else {
        const giftCardSetting = _find(giftCardSettings,
            o => parseInt(o.price_group_id) === parseInt(this.selectedPriceGroup));
        if (giftCardSetting) {
          unsortedPrices.push({
            priceItemType: 'gift_card',
            sort_in_price_group: giftCardSetting.sort,
          });
        }
      }

      return _sortBy(unsortedPrices, 'sort_in_price_group');
    },

    sortedPriceItemsWithoutPriceGroups() {
      const membershipTypes = _map(this.filteredMembershipTypes, mt => {
        mt.priceItemType = 'membership_type';
        return mt;
      });
      const classPassTypes = _map(this.filteredClassPassTypes, cpt => {
        cpt.priceItemType = 'class_pass_type';
        return cpt;
      });
      const classSeriesTypes = _map(this.filteredClassSeriesTypes, cst => {
        cst.priceItemType = 'class_series_type';
        return cst;
      });
      const sortedPriceItems = _concat(
          membershipTypes,
          classPassTypes,
          classSeriesTypes,
      );
      if (this.client.settings.gift_card_show_in_prices) {
        sortedPriceItems.unshift({
          priceItemType: 'gift_card',
        });
      }
      return sortedPriceItems;
    },
    classSeriesInstallmentDialogRows() {
      // If no class series selected, return empty array
      if (!this.classSeriesInstallmentDialogClassSeries) return [];

      // First payment (now)
      const rows = [{
        date: this.$t('global.Now'),
        amount: this.formatCurrency(this.classSeriesInstallmentDialogClassSeries.current_required_paid_amount),
        has_member_discount: this.classSeriesInstallmentDialogClassSeries.has_member_discount,
        amount_with_member_discount: this.classSeriesInstallmentDialogClassSeries.has_member_discount
            ? this.formatCurrency(this.classSeriesInstallmentDialogClassSeries.current_required_paid_amount_with_member_discount)
            : null,
      }];

      // Future payments
      for (
          let i = this.classSeriesInstallmentDialogClassSeries.installments.length - this.classSeriesInstallmentDialogClassSeries.number_of_installments_left;
          i < this.classSeriesInstallmentDialogClassSeries.installments.length;
          i++
      ) {
        rows.push({
          date: this.formatDate(this.classSeriesInstallmentDialogClassSeries.installments[i].date),
          amount: this.formatCurrency(this.classSeriesInstallmentDialogClassSeries.installments[i].amount),
          has_member_discount: this.classSeriesInstallmentDialogClassSeries.installments[i].has_member_discount,
          amount_with_member_discount: this.classSeriesInstallmentDialogClassSeries.installments[i].has_member_discount
              ? this.formatCurrency(this.classSeriesInstallmentDialogClassSeries.installments[i].amount_with_member_discount)
              : null,
        });
      }
      return rows;
    },
  },
  beforeUpdate() { // Before updating the DOM tree

    /**
     * Please read the selectedPriceGroup watcher as well.
     *
     * Set pre-selected first Price Item if:
     * 1) showPricesDropdown is true.
     * 2) We don't want to use the "All" option in prices filter.
     * 3) No price item has been selected.
     * 4) There are any price items to select from.
     * **/
    if (this.showPricesDropdown && !this.showPricesAllOption && !this.selectedPriceItem && this.sortedPriceItems && this.sortedPriceItems.length > 0) {
      this.selectedPriceItem = this.sortedPriceItems[0];
    }
  },
  mounted: function () {
    this.fetchMembershipsAndClassPasses();
  },
  watch: {
    async stateReady(newReadyState) {
      if (this.client.settings.gift_card_show_in_prices && this.client.settings.gift_card_prices_image_id) {
        this.giftCardPricesImage = await YogoApi.get('/images/' + this.client.settings.gift_card_prices_image_id);
      }
      if (newReadyState && this.initialized) {
        this.loading = false;
      }
    },
    user(newUser) {
      this.$forceUpdate();
    },
    classesCounter() {
      this.fetchMembershipsAndClassPasses();
    },
    selectedPriceGroup() {
      /** Currently there's a weird bug where an Error is thrown if I access the computed property "sortedPriceItems",
       * this in turns makes it a bit weird to handle setting the first in the list of sortedPriceItems when the
       * component has either loaded or the selectedPriceGroup. This results in a combination of this watcher on
       * selectedPriceGroup and the beforeUpdated lifecycle hook make sure that the right selectedPriceItem is set (if
       *  needed).
       *  So in this watcher: if there's a selectedPriceItem and the selectedPriceGroup changes, then make sure to
       *  reset the selectedPriceItem and let the beforeUpdate logic handle setting the default one. **/
      if (this.selectedPriceItem) {
        this.selectedPriceItem = null;
      }
    }
  },
  methods: {
    async fetchMembershipsAndClassPasses() {

      this.loading = true;

      let membershipTypes, classPassTypes;

      [membershipTypes, classPassTypes, this.priceGroups, this.classSeriesTypes] = await Promise.all(
          [
            YogoApi.get('/membership-types' +
                '?populate[]=image' +
                '&populate[]=payment_options' +
                '&populate[]=membershipCount' +
                '&populate[]=max_number_of_memberships_reached' +
                '&populate[]=price_groups' +
                '&populate[]=active_campaign' +
                '&populate[]=userIsEligibleForCampaign' +
                '&populate[]=payment_options.nameThatUserSees' +
                '&populate[]=payment_options.priceTextThatUserSees' +
                '&populate[]=user_has_membership_type' +
                '&populate[]=user_membership_id' +
                (this.showSinglePriceGroup ? '&priceGroupName=' + encodeURIComponent(this.showSinglePriceGroup) : ''),
            ),
            YogoApi.get('/class-pass-types' +
                '?populate[]=image' +
                '&populate[]=price_groups' +
                '&populate[]=user_can_buy' +
                '&populate[]=max_number_per_customer_already_used' +
                '&populate[]=price_with_member_discount' +
                '&populate[]=has_member_discount' +
                (this.showSinglePriceGroup ? '&priceGroupName=' + encodeURIComponent(this.showSinglePriceGroup) : ''),
            ),
            YogoApi.get('/price-groups' +
                '?populate[]=membership_types' +
                '&populate[]=class_pass_types' +
                '&populate[]=class_series_types',
            ),
            YogoApi.get('/class-series-types' +
                '?populate[]=price_groups' +
                '&populate[]=image' +
                '&populate[]=number_of_remaining_classes_and_livestream_classes' +
                '&populate[]=current_price' +
                '&populate[]=next_class' +
                '&populate[]=first_class' +
                '&populate[]=is_available_for_purchase' +
                '&populate[]=fully_booked_remaining_classes' +
                '&populate[]=customer_has_class_series_type' +
                '&populate[]=customer_can_buy_class_series_type' +
                '&populate[]=has_started' +
                '&populate[]=classes_and_classes_livestream' +
                '&populate[]=has_member_discount' +
                '&populate[]=current_price_with_member_discount' +
                '&populate[]=price_with_member_discount' +
                '&populate[]=number_of_installments_left' +
                '&populate[]=installments.amount_with_member_discount' +
                '&populate[]=current_required_paid_amount' +
                '&populate[]=current_required_paid_amount_with_member_discount' +
                '&excludeEnded=1' +
                (this.showSinglePriceGroup ? '&priceGroupName=' + encodeURIComponent(this.showSinglePriceGroup) : ''),
            ),
          ]);

      this.membershipTypes = _keyBy(membershipTypes, 'id');
      this.classPassTypes = _keyBy(classPassTypes, 'id');

      _each(this.membershipTypes, membershipType => {
        membershipType.payment_options = _filter(membershipType.payment_options,
            paymentOption => paymentOption.for_sale);
        if (!membershipType.payment_options.length) return;
        membershipType.payment_options = _sortBy(membershipType.payment_options,
            'number_of_months_payment_covers');
        this.selectedPaymentOption[membershipType.id] = membershipType.payment_options[0].id;
      });

      this.membershipTypes = _filter(this.membershipTypes,
          membershipType => !!membershipType.payment_options.length);

      this.classSeriesTypes = _sortBy(
          this.classSeriesTypes,
          ['first_class.date', 'first_class.start_time', 'price'],
      );
      _each(
          this.classSeriesTypes,
          (classSeriesType) => {
            _each(classSeriesType.classes_livestream, (livestreamClass) => {
              livestreamClass.isLivestreamClass = true;
            });
            classSeriesType.displayClasses = _sortBy(
                _concat(classSeriesType.classes, classSeriesType.classes_livestream),
                ['date', 'start_time'],
            );
          },
      );

      if (this.priceGroups.length) {
        if (this.showSinglePriceGroup) {
          this.selectedPriceGroup = _find(
              this.priceGroups,
              pg => pg.name.toLocaleLowerCase() === this.showSinglePriceGroup.toLocaleLowerCase(),
          );
          this.selectedPriceGroup = this.selectedPriceGroup ? this.selectedPriceGroup.id : this.selectedPriceGroup;
        } else {
          this.selectedPriceGroup = _find(
              this.priceGroups,
              priceGroup => !!priceGroup.show_in_default_price_list,
          );
          this.selectedPriceGroup = this.selectedPriceGroup ? this.selectedPriceGroup.id : this.selectedPriceGroup;
        }

      }

      this.initialized = true;
      if (this.stateReady) {
        this.loading = false;
      }
    },
    async buyProduct(accessType, id) {

      if (this.user) {

        this.loading = true;

        if (accessType === 'class_pass_type') {

          const classPassType = await YogoApi.get('/class-pass-types?id=' + id + '&populate[]=max_number_per_customer_already_used');

          if (
              classPassType.max_number_per_customer_already_used
          ) {
            this.loading = false;
            this.alertDialogText = this.$t('global.cantBuyMore', {
              classPassTypeName: classPassType.class_type.name,
            });
            this.alertDialogConfirmText = 'Ok';
            this.showAlertDialog = true;

            return;
          }


          if (parseInt(classPassType.price) === 0) {

            await YogoApi.post('/class-passes', {
              user: this.user.id,
              class_pass_type: classPassType.id,
            });

            this.loading = false;
            this.alertDialogText = this.$t('global.isCreatedOnProfile', {
              classPassTypeName: classPassType.name,
            });
            this.alertDialogConfirmText = 'Ok';
            this.showAlertDialog = true;

            return;

          }

        }


        let cartItem = {
          item_type: accessType,
          item_id: id,
          user: this.user.id,
        };

        if (accessType === 'membership_type') {

          cartItem.payment_option = this.selectedPaymentOption[id];

          const membershipType = _find(this.membershipTypes, ['id', id]);

          const paymentOption = this.getSelectedMembershipTypePaymentOption(membershipType);
          if (parseInt(paymentOption.number_of_months_payment_covers) === 1 && membershipType.active_campaign_id) {
            cartItem.membership_campaign = membershipType.active_campaign_id;
          }
        }


        await YogoApi.post('/cart-items', cartItem);

        YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'));
        this.loading = false;


      } else {
        const cartItem = {
          itemType: accessType,
          itemId: id,
          paymentOption: this.selectedPaymentOption[id],
        };
        if (accessType === 'membership_type') {

          cartItem.paymentOption = this.selectedPaymentOption[id];

          const membershipType = _find(this.membershipTypes, ['id', id]);

          const paymentOption = this.getSelectedMembershipTypePaymentOption(membershipType);

          if (parseInt(paymentOption.number_of_months_payment_covers) === 1 && membershipType.active_campaign_id) {
            cartItem.membershipCampaign = membershipType.active_campaign_id;
          }
        }

        const loginWithCartRouteParams = {
          cartItemJson: JSON.stringify(cartItem),
        };
        YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(
            loginWithCartRouteParams)));
      }

    },

    async buyGiftCard() {
      YogoFrontend.open('/gift');
    },

    getSelectedMembershipTypePaymentOption(membershipType) {
      const paymentOptionId = this.selectedPaymentOption[membershipType.id];
      return _find(membershipType.payment_options,
          paymentOption => paymentOption.id === paymentOptionId);
    },
    openCustomerMembershipForMembershipType(membershipType) {
      const path = '/system-route/Membership/' + encodeURIComponent(JSON.stringify({ id: membershipType.user_membership_id }));
      YogoFrontend.open(path);
    },
    showClassSeriesInstallments(classSeriesType) {
      this.classSeriesInstallmentDialogClassSeries = classSeriesType;
      this.showClassSeriesInstallmentDialog = true;
    },
    showClassSeriesInstallmentPaymentOption(classSeriesType) {
      return !(this.user && !classSeriesType.customer_can_buy_class_series_type)
          && classSeriesType.installment_payments_enabled && classSeriesType.number_of_installments_left >= 1
    },
  },
};

</script>

<style lang="stylus" scoped>

</style>
