import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMaterial from 'vue-material';
import moment from 'moment';
import store from '../store';
import da from '../locales/da.json';
import en from '../locales/en.json';
import nb from '../locales/nb.json';
import sv from '../locales/sv.json';
import de from '../locales/de.json';
import pt from '../locales/pt.json';

const initialLocale = 'en';

/**
 * Configure VueI18n
 */
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: initialLocale,
  fallbackLocale: 'en',
  messages: { da, en, nb, sv, de, pt },
});

/**
 * Configure moment.js
 */
moment.locale(initialLocale);

/**
 * Configure VueMaterial
 */
Vue.use(VueMaterial);

function changeVueMaterialLocale(locale) {
  const momentLocale = moment.localeData(locale);

  Vue.prototype.$material.locale = {
    startYear: 1900,
    endYear: 2099,
    dateFormat: 'YYYY-MM-DD',
    days: momentLocale.weekdays(),
    shortDays: momentLocale.weekdaysShort(),
    shorterDays: momentLocale.weekdaysMin(),
    months: momentLocale.months(),
    shortMonths: momentLocale.monthsShort(),
    shorterMonths: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
  };
}

changeVueMaterialLocale(initialLocale);

window.changeYogoWidgetsLocale = locale => {
  i18n.locale = locale;
  moment.locale(locale);
  changeVueMaterialLocale(locale);
};

export default i18n;
