<template>
  <Portal to="yogomodal" v-if="show">
      <transition name="modal-fade">
        <div class="yogo-modal-backdrop yogo-z-top" @click="close">
          <div class="yogo-modal" @click.stop>
            <button
                type="button"
                class="yogo-btn-close yogo-flex justify-end"
                @click="close"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                   stroke-linejoin="round" class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <div class="yogo-modal-header">
              <slot name="header"></slot>
            </div>
            <div class="yogo-modal-body">
              <slot name="body"></slot>
            </div>
            <div class="yogo-modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </transition>
  </Portal>
</template>

<script>

export default {
  name: 'Modal',
  props: ['show'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">

.yogo-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.yogo-modal {
  background: #FFFFFF;
  margin: 20px;
  width: 95%;
  border-radius: 3px;
  max-width: 700px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin: 40px;
  }
}

.yogo-modal-footer {
  padding: 30px;
  display: flex;
}

.yogo-modal-header {
  padding: 0 30px 10px 30px;
}

.yogo-modal-header {
  justify-content: space-between;
}

.yogo-modal-footer {
  justify-content: flex-end;
}

.yogo-modal-body {
  position: relative;
  padding: 0 30px;
  color: #000;
}

.yogo-btn-close {
  border: none;
  font-size: 20px;
  padding: 30px;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }
}

.yogo-modal-fade-enter,
.yogo-modal-fade-leave-active {
  opacity: 0;
}

.yogo-modal-fade-enter-active,
.yogo-modal-fade-leave-active {
  transition: opacity .3s ease
}


</style>
