<template>
  <img v-if="image" :src="Imgix(image.filename || image, options)" class="yogo-rounded-full">
  <avatar v-else/>
</template>

<script>

import Imgix from '@/services/Imgix';
import Avatar from '@/graphics/Avatar.vue';

export default {
  components: { Avatar },
  mixins: [Imgix],
  props: ['image', 'options'],
};

</script>
