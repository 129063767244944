<template>
  <div class="modal__teacher--info">
    <div class="yogo-my-2" v-for="(teacher, index) in teachers" :key="'teacher-' + index">
      <div class="yogo-flex pr1 items-center" :class="teacher.teacher_description.trim().length ? 'pointer' : ''" @click.prevent="toggleTeacherInfo(index)">
        <ProfileImage :image="teacher.image" :options="{w:200, h:200, fit:'crop'}" style="width:60px;height:60px;" class="mr-2"/>
        <div class="yogo-m-0 yogo-font-bold">
          {{ $t('global.teacher') | capitalize }}: {{ teacher.first_name + ' ' + teacher.last_name }}
        </div>

        <div class="yogo-flex items-center pointer ml2" v-if="teacher.teacher_description.trim().length">
          <span class="yogo-flex yogo-items-center" :class="{ 'rotate': showTeacherInfo[index] }">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
          </span>
        </div>
      </div>

      <!-- dropdown content -->
      <transition name="slide-fade" mode="out-in" appear>
        <nl2br tag="div" :text="teacher.teacher_description" v-if="showTeacherInfo[index]" className="my-4"></nl2br>
      </transition>
      <!-- end dropdown content -->
    </div>
  </div>
</template>

<script>

  import Imgix from "../services/Imgix"
  import ProfileImage from '@/components/ProfileImage';
  import { mapGetters } from "vuex";

  export default {
    props: ['teachers'],
    mixins: [Imgix],
    components: {ProfileImage},
    data() {
      return {
        showTeacherInfo: [],
      }
    },
    computed: {
      ...mapGetters(['client']),
    },
    mounted() {
      this.showTeacherInfo = Array.from({length: this.teachers.length})
        .fill(this.client.settings.frontend_teacher_descriptions_in_dialogs_are_initially_expanded);
    },
    methods: {
      toggleTeacherInfo(index) {
        this.$set(this.showTeacherInfo, index, !this.showTeacherInfo[index])
      },
    }
  }

</script>

<style lang="scss" scoped>

  .teacher {
    margin-right: 20px;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
    z-index: 2;
    position: relative;
  }

  .slide-fade-leave-active {
    transition: all .2s ease;
    z-index: 2;
    position: relative;
  }

  .slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
    z-index: 2;
    position: relative;
  }

  .slide-fade-enter {
    opacity: 0;
    transform: translateY(-30px);
    z-index: 2;
    position: relative;
  }

</style>
