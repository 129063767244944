<template>
  <div class="yogo-widget">
    <div>

      <span v-if="loading">...</span>

      <span v-else-if="errorMessage">[{{ errorMessage }}]</span>

      <div v-else>
        <div class="yogo-flex flex-wrap yogo-cards-container" ref="cardsContainer">
          <div class="yogo-video-card" v-for="(video, idx) in videoGroup.videos" :style="cardInlineStyle">
            <div class="yogo-video-thumb-container" :ref="'videoContainer_' + idx">
              <!--<div class="yogo-video-group-name">
                Mixed level practice
              </div>-->
              <div
                class="yogo-video-thumb"
                :ref="'videoThumbnail_' + idx"
                :style="{'background-image':'url(\'' + getThumbnailUrl(video, 800) + '\')', 'padding-bottom': (video.video_provider_data.height / video.video_provider_data.width * 100) + '%'}"
                @click="playButtonClick(idx)"
              >
              </div>
              <div class="yogo-video-duration" :ref="'videoDuration_' + idx">
                {{ formatVideoDuration(video.video_provider_data.duration ) }}
              </div>
            </div>
            <div class="p-4 flex flex-col h-full">
              <div class="yogo-video-title mb-2 bold text-gray">
                {{ video.video_provider_data.name }}
              </div>
              <div class="h-100">
                <nl2br
                  tag="div"
                  className="yogo-video-description text-black text-sm leading-normal"
                  v-if="videoGroup.show_video_descriptions && video.video_provider_data.description"
                  :text="video.has_long_description && !video.show_full_description ? video.description_preview : video.video_provider_data.description"
                >
                </nl2br>
              </div>
              <a class="yogo-video-description-more-switch mt-2" href="#"
                 @click.prevent="switchShowFullVideoDescription(video)" v-if="video.has_long_description">{{
                video.show_full_description ? $t('global.less') : $t('global.more') }}</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <md-dialog :md-active.sync="showNoAccessDialog">
      <md-dialog-title>{{ $t('global.noAccess') }}</md-dialog-title>
      <md-dialog-content>
        <p>
          {{ $t('global.youDontHaveAccess') }}
        </p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="showNoAccessDialog = false">{{ $t('global.cancelButton') }}</md-button>
        <md-button md-primary @click="openPopupPrices">{{ $t('global.seePrices') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>

  import YogoApi from '../gateways/YogoApi'
  import YogoFrontend from '../gateways/YogoFrontend'
  import {mapGetters} from 'vuex'
  import LoadingSpinner from './LoadingSpinner'
  import _sortBy from 'lodash/sortBy'
  import _reverse from 'lodash/reverse'
  import _find from 'lodash/find'
  import _each from 'lodash/each'
  import _padStart from 'lodash/padStart'
  import _debounce from 'lodash/debounce'
  import VimeoPlayer from '@vimeo/player'
  import {DateTime} from 'luxon'

  export default {
    components: {
      loadingspinner: LoadingSpinner,
    },
    data: function () {
      return {
        loading: true,

        columnCount: 1,

        videoGroup: null,
        vimeoPlayers: [],

        errorMessage: '',

        showNoAccessDialog: false,

        debouncedAdjustColumnCountFunction: null
      }
    },
    props: ['videoGroupId'],
    computed: {
      ...mapGetters([
        'user',
        'classesCounter',
      ]),
      cardInlineStyle() {
        const style = {}
        if (this.columnCount === 1) {
          style.width = '100%'
        } else {
          style.width = `calc(${100 / this.columnCount}% - 30px)`
        }
        style['margin-top'] = this.columnCount === 1 ? 0 : '15px'
        return style
      },
    },
    watch: {
      classesCounter() {
        this.getVideoGroup()
      },
    },

    created() {
      this.getVideoGroup()
    },

    mounted() {
      this.debouncedAdjustColumnCountFunction = _debounce(this.adjustColumnCount, 100)
      window.addEventListener('resize', this.debouncedAdjustColumnCountFunction)
      this.adjustColumnCount()
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.debouncedAdjustColumnCountFunction)
    },

    methods: {

      async getVideoGroup() {

        this.loading = true

        this.videoGroup = await YogoApi.get(
          '/video-groups/' + this.videoGroupId + '?populate[]=videos',
        )

        this.loading = false

        if (this.videoGroup === 'E_VIDEO_GROUP_DOES_NOT_EXIST') {
          this.errorMessage = 'Invalid video group ID'
        }

        switch (this.videoGroup.sort_videos_by) {
          case 'created':
            this.videoGroup.videos = _sortBy(
                this.videoGroup.videos,
                video => DateTime.fromISO(video.created_at_datetime_for_sorting_utc || video.video_provider_data.created_time).toMillis()
            )
            break
          case 'name':
            this.videoGroup.videos = _sortBy(this.videoGroup.videos, video => video.video_provider_data.name)
            break
        }

        if (this.videoGroup.sort_videos_direction === 'desc') {
          this.videoGroup.videos = _reverse(this.videoGroup.videos)
        }

        _each(this.videoGroup.videos, video => {
          video.has_long_description = video.video_provider_data.description && video.video_provider_data.description.length > 220
          if (video.has_long_description) {
            const lastSpacePosition = video.video_provider_data.description.lastIndexOf(' ', 179)
            video.description_preview = video.video_provider_data.description.substr(0, lastSpacePosition) + '...'
            video.show_full_description = false
          }
        })

        this.$nextTick(this.adjustColumnCount)

      },

      switchShowFullVideoDescription(video) {
        video.show_full_description = !video.show_full_description
        this.$forceUpdate()
      },

      async playButtonClick(videoIdx) {
        if (!this.videoGroup) return

        const video = this.videoGroup.videos[videoIdx]

        if (this.user) {

          if (video.video_provider_id) {
            this.playVideo(videoIdx)
          } else {
            this.showNoAccessDialog = true
          }

        } else {
          // On mobile, YogoFrontend opens a new tab/window, so in that case, we play the video from the frontend
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            const routeParamsJson = JSON.stringify({
              videoId: video.id,
            })
            YogoFrontend.open('/system-route/VideoPlayer/' + routeParamsJson)
            return
          }
          YogoFrontend.open('/system-route/Login')
        }

      },

      async playVideo(videoIdx) {

        const video = this.videoGroup.videos[videoIdx]

        this.vimeoPlayers[videoIdx] = await new VimeoPlayer(
          this.$refs['videoContainer_' + videoIdx][0],
          {
            id: video.video_provider_id,
            width: this.$refs['videoThumbnail_' + videoIdx][0].offsetWidth,
            height: this.$refs['videoThumbnail_' + videoIdx][0].offsetHeight,
            autoplay: true,
          },
        )
        this.vimeoPlayers[videoIdx].element.style.height = this.$refs['videoThumbnail_' + videoIdx][0].offsetHeight + 'px'
        this.$refs['videoThumbnail_' + videoIdx][0].style.display = 'none'
        this.$refs['videoDuration_' + videoIdx][0].style.display = 'none'

      },

      openPopupPrices() {
        this.showNoAccessDialog = false
        YogoFrontend.open('/system-route/Prices/%7B%7D')
      },

      getThumbnailUrl(video, minWidth) {
        const sortedSizes = _sortBy(video.video_provider_data.pictures.sizes, 'width')
        let thumbnail = _find(sortedSizes, size => size.width >= minWidth)
        if (!thumbnail) {
          thumbnail = sizes[sizes.length - 1]
        }
        return thumbnail.link_with_play_button
      },

      formatVideoDuration(seconds) {
        return _padStart(Math.floor(seconds / 60), 2, '0') + ':' + _padStart(seconds % 60, 2, '0')
      },

      adjustColumnCount() {
        if (this.$refs.cardsContainer) {
          this.columnCount = Math.max(
            Math.ceil(
              (this.$refs.cardsContainer.offsetWidth - 380) / 320,
            ),
            1,
          )
        }
      },

    },

  }
</script>

<style lang="scss">

  .yogo-video-title {
    font-size: 1.1em;
  }

  .yogo-cards-container {
    margin-left: -15px;
    margin-right: -15px;
  }

  .yogo-video-card {
    border-radius: 3px;
    border: 1px solid #ece8e8;
    width: 100%;
    background: #fff;
    margin: 15px;

    .yogo-video-thumb-container {
      position: relative;
    }

    .yogo-video-thumb {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 0;
      cursor: pointer;
    }

    /*.yogo-video-group-name {
      background: rgba(0, 0, 0, .5);
      position: absolute;
      top: 15px;
      left: 15px;
      color: #fff;
      padding: 3px 5px;
      border-radius: 2px;
      font-size: .8em;
    }*/

    .yogo-video-duration {
      background: rgba(0, 0, 0, .5);
      position: absolute;
      bottom: 15px;
      right: 15px;
      color: #fff;
      padding: 3px 5px;
      border-radius: 2px;
      font-size: .8em;
      line-height: 1.2;
    }

    .yogo-video-description-more-switch {
      display: block;
      text-align: right;
    }
  }

</style>

