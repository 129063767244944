<template>
  <div>
    <slot :rows="displayedRows1"></slot>
    <button
        v-if="rows.length >= numberOfClassesToTriggerWrapper"
        @click="toggleShowLess"
        class="yogo-btn yogo-btn-secondary yogo-my-1"
    >
      <span class="yogo-flex yogo-items-center">
        <ChevronUpDownIcon class="yogo-h-4 yogo-w-4 yogo-mr-1"/>
        {{ buttonText }}
      </span>
    </button>
    <slot :rows="displayedRows2"
          v-if="rows.length >= numberOfClassesToTriggerWrapper && !isShowingAll"></slot>
  </div>
</template>

<script>

import ChevronUpDownIcon from '@/graphics/icons/ChevronUpDownIcon';

export default {
  name: 'YogoShowLessWrapper',
  components: { ChevronUpDownIcon },
  props: {
    rows: Array,
  },
  data() {
    return {
      isShowingAll: false,

      numberOfClassesToTriggerWrapper: 9,
      numberOfClassesVisibleInTopAndBottomWhenWrapperActive: 2,
    };
  },
  computed: {
    displayedRows1() {
      if (this.isShowingAll || this.rows.length < this.numberOfClassesToTriggerWrapper) {
        return this.rows;
      } else {
        return this.rows.slice(0, this.numberOfClassesVisibleInTopAndBottomWhenWrapperActive);
      }
    },
    buttonText() {
      return this.isShowingAll
          ? this.$t('global.showLess')
          : `${this.rows.length - this.numberOfClassesVisibleInTopAndBottomWhenWrapperActive * 2} ${this.$t(
              'global.dates')} (${this.$t('global.show')})`;
    },
    displayedRows2() {
      return this.rows.slice(-this.numberOfClassesVisibleInTopAndBottomWhenWrapperActive);
    },
  },
  methods: {
    toggleShowLess() {
      this.isShowingAll = !this.isShowingAll;
    },
  },
};

</script>
