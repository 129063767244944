<template>
  <md-snackbar md-position="center"
               :md-active.sync="showSnackbar" md-persistent :md-duration="4000">
    {{ snackbarText }}
  </md-snackbar>
</template>

<script>

  import YogoApi from '@/gateways/YogoApi'
  import YogoFrontend from "../gateways/YogoFrontend"

  export default {
    data() {
      return {
        snackbarText: '',
        showSnackbar: false,
      }
    },
    props: [
      'eventSignups',
      'user',
      'client'
    ],

    created() {
      this.$on('signUpForEvent', this.signUpForEvent)
      this.$on('signOffFromEvent', this.signOffFromEvent)
    },

    methods: {

      async signUpForEvent(event) {
        if (!this.user) {
          const loginWithCartRouteParams = {
            cartItemJson: JSON.stringify({
              itemType: 'event',
              itemId: event.id,
            }),
          }
          YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(loginWithCartRouteParams)))
          return
        }

        if (parseInt(event.price) === 0) {
          const response = await YogoApi.post('/event-signups', {
            user: this.user.id,
            event: event.id,
          })
          if (response === 'E_EVENT_IS_FULL') {
            alert(this.$t('global.fullyBooked'))
            this.$emit('updateCalendar')
            return
          }
          if (response === 'E_USER_IS_ALREADY_SIGNED_UP_FOR_EVENT') {
            alert(this.$t('global.youAreAlreadySignedUp'))
            this.$emit('updateCalendar')
            return
          }
          if (response.substr(0,2) === 'E_') {
            alert('Error: '+ response)
            this.$emit('updateCalendar')
            return
          }
          this.snackbarText = this.$t('global.youAreNowSignedUp', {
            eventName: event.name,
          })
          this.showSnackbar = true
          this.$emit('eventSignupSuccess', response, event)
          return
        }
        await YogoApi.post('/cart-items', {
          user: this.user.id,
          item_type: 'event',
          item_id: event.id,
        })
        YogoFrontend.open('/system-route/Checkout/' + encodeURIComponent('{}'))
      },


      async signOffFromEvent(eventTimeSlot) {
        await YogoApi.delete('/event-signups/' + eventTimeSlot.user_signup_id)
        await this.$store.dispatch('updateUser')
        this.snackbarText = this.$t('global.youAreNoLongerSignedUpForEventName', {
          eventName: eventTimeSlot.event.name,
        })
        this.showSnackbar = true
        this.$emit('eventSignoffSuccess')
      },

    },
  }

</script>
