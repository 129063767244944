<template>
  <div class="yogo-widget">
    <div class="schedule yogo--wrapper">
      <div class="theme--frame-box">

        <div class="flex--1-3 yogo-input mb-4"
             v-if="!branchName && client.branches && client.branches.length > 1">
          <label for="branch">{{ $t('global.Location') }}</label>
          <select name="branch" v-model="filterByBranch">
            <option value="all">
              {{ $t('global.AllLocations') }}
            </option>
            <option v-for="branch in client.branches" :value="branch.id" :key="branch.id">
              {{ branch.name }}
            </option>
          </select>
        </div>

        <widgetPadding>

          <div class="flex--row-reverse space--between flex--start">

            <button
                v-if="showFilterButton"
                class="btn underline fr mb20"
                @click.prevent="toggle"
            >
              {{ $t('global.filters') }}
            </button>
          </div>

          <div class="space--between fc-sm mb-2">

            <div class="flex flex-shrink-0 items-center rounded-full py-1 mb-4 cursor-pointer">
              <span class="mr-2">
                <CalendarIcon></CalendarIcon>
              </span>

              <Datepicker
                  v-model="calendarDate"
                  :disabled-dates="{ customPredictor: datepickerDateIsDisabled }"
                  class="datepicker"
              >
              </Datepicker>

            </div>

            <span class="fac space-between calendar__week--nav">
            <div @click.prevent="gotoPreviousWeek"
                 :class="{disabled:!canGoBack, pointer: canGoBack}" class="flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                   stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12"
                                                                                    x2="5"
                                                                                    y2="12"></line><polyline
                  points="12 19 5 12 12 5"></polyline></svg>
            </div>
            <span class="align--center">
              {{ $t('global.weekNumber', { weekNumber }) }}
            </span>
            <div class="pointer flex" @click.prevent="gotoNextWeek">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                   stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12"
                                                                                     x2="19"
                                                                                     y2="12"></line><polyline
                  points="12 5 19 12 12 19"></polyline></svg>
            </div>
          </span>

            <div
                v-if="client.settings && client.settings.calendar_show_classes_and_events_separately_or_together === 'separately'">
              <md-radio v-model="filterBySessionType" value="group">{{
                  $t('global.classes')
                }}
              </md-radio>
              <md-radio v-model="filterBySessionType" value="private">{{
                  $t('global.privateLessons')
                }}
              </md-radio>
              <md-radio v-model="filterBySessionType" value="event">{{
                  $t('global.events')
                }}
              </md-radio>
            </div>

          </div>

          <slide-up-down :active="filtersOpen" :duration="500" ref="filterAnimateDown">

            <div class="flex--row mb20">

              <md-field v-if="client.settings && client.settings.calendar_show_teacher_filter"
                        class="flex--1-3 mr10-md mr20-lg">
                <label>{{ $t('global.teacher') | capitalize }}</label>
                <md-select v-model="selectedTeacher">
                  <md-option value="0">- - {{ $t('global.teachersAll') }} - -</md-option>
                  <md-option v-for="teacher in teachers" :value="teacher.id" :key="teacher.id">
                    {{ teacher.first_name + ' ' + teacher.last_name }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field
                  v-if="client.settings && client.settings.calendar_show_classtype_filter && !(widgetClassTypes && widgetClassTypes.length)"
                  class="flex--1-3"
                  :style="{visibility: filterBySessionType === 'event' ? 'hidden' : 'visible'}">
                <label>{{ $t('global.classType') }}</label>
                <md-select v-model="filterByClassType">
                  <md-option value="0">- - {{ $t('global.classTypeAll') }} - -</md-option>
                  <md-option v-for="classType in classTypes" :value="classType.id"
                             :key="classType.id">
                    {{ classType.name }}
                  </md-option>
                </md-select>
              </md-field>

            </div>

          </slide-up-down>

          <loginstatus></loginstatus>
          <div v-if="branchNameIsInvalid" class="invalid-branch-name">
            INVALID BRANCH NAME: {{ invalidBranchName.toUpperCase() }}
          </div>
          <div class="calendar" ref="calendarWithContainer" v-else>

            <ScheduleList
                v-if="client.settings && client.settings.calendar_layout_type === 'list'"
                :filterBySessionType="client.settings.calendar_show_classes_and_events_separately_or_together === 'separately' ? filterBySessionType : null"
                :date="calendarDate"
                :filterByTeacher="filterByTeacher"
                :filterByClassType="filterByClassType"
                :branchName="branchName || filterByBranchName"
                :widgetClassTypes="widgetClassTypes"
                :widgetOnlyLivestream="widgetOnlyLivestream"
            >
            </ScheduleList>

            <ScheduleTimetable
                v-if="client.settings && client.settings.calendar_layout_type === 'timetable'"
                :filterBySessionType="client.settings.calendar_show_classes_and_events_separately_or_together  === 'separately' ? filterBySessionType : null"
                :date="calendarDate"
                :filterByTeacher="filterByTeacher"
                :filterByClassType="filterByClassType"
                :branchName="branchName || filterByBranchName"
                :widgetClassTypes="widgetClassTypes"
                :widgetOnlyLivestream="widgetOnlyLivestream"
            >
            </ScheduleTimetable>


          </div> <!-- calendar -->

        </widgetPadding>

      </div>

      <div class="space8"></div>

    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import LoadingSpinner from './LoadingSpinner.vue';
import WidgetPadding from './WidgetPadding.vue';

import _find from "lodash/find";
import _sortBy from 'lodash/sortBy';
import YogoApi from '../gateways/YogoApi';
import { mapGetters, mapActions } from 'vuex';
import SlideUpDown from 'vue-slide-up-down';
import Datepicker from '@/components/ui/Datepicker.vue';
import CalendarIcon from '../graphics/icons/CalendarIcon.vue';

import LoginStatus from './LoginStatus.vue';

import ScheduleList from "./Schedule/ScheduleList";
import ScheduleTimetable from "./Schedule/ScheduleTimetable";
import _debounce from 'lodash/debounce';
import qs from 'qs';

export default {
  name: 'Calendar',
  components: {
    ScheduleTimetable,
    ScheduleList,
    Datepicker,
    CalendarIcon,
    widgetPadding: WidgetPadding,
    loadingspinner: LoadingSpinner,
    loginstatus: LoginStatus,
    SlideUpDown,
  },
  data() {
    return {
      dateLabel: 'dato',
      calendarDate: DateTime.now().toISODate(),

      filterBySessionType: 'group',
      isMobile: false,
      selectedTeacher: 0,
      teachers: [],

      filterByClassType: 0,
      classTypes: [],

      filterByBranch: 'all',

      earliestAllowedMonday: DateTime.now().startOf('week').toISODate(),
      loading: false,

      branchNameIsInvalid: false,
      invalidBranchName: '',

      filtersOpen: false,
    };
  },
  props: ['branchName', 'widgetClassTypes', 'widgetStartDate', 'widgetTeachers', 'widgetOnlyLivestream'],
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userName',
      'client',
      'user',
      'stateReady',
      'eventSignups',
      'classSignups',
      'classPasses',
      'memberships',
      'classSignoffDeadlineInHumanFormat',
    ]),
    ...mapGetters('calendarFilters', {
      filters: 'all',
    }),
    weekNumber() {
      return DateTime.fromISO(this.calendarDate).weekNumber;
    },
    canGoBack() {
      return DateTime.fromISO(this.calendarDate)
          .startOf('week')
          .toISODate() > this.earliestAllowedMonday;
    },
    filterByBranchName() {
      if (this.filterByBranch && this.filterByBranch !== 'all') {
        return _find(this.client.branches, { id: this.filterByBranch }).name;
      }
      return '';
    },
    filterByTeacher() {
      return parseInt(this.selectedTeacher)
          ? this.selectedTeacher
          : this.widgetTeachers;
    },
    todayISO() {
      return DateTime.now().setZone(this.client ? this.client.timezone : 'UTC').toISODate();
    },
    showFilterButton() {
      return this.client.settings && (
          this.client.settings.calendar_show_teacher_filter
          || (
              this.client.settings.calendar_show_classtype_filter
              && !(this.widgetClassTypes && this.widgetClassTypes.length)
          )
      );
    },
  },
  async created() {
    if (this.stateReady) {
      this.init();
    }
  },

  mounted() {
    const el = this.$refs.filterAnimateDown.el;
    el.addEventListener('transitionend', () => {
    });
    this.debouncedGetCalendarParentWidth = _debounce(this.getCalendarParentWidth, 100);
    window.addEventListener('resize', this.debouncedGetCalendarParentWidth);
    this.getCalendarParentWidth();

  },

  methods: {
    async init() {
      this.calendarDate = DateTime.now().setZone(this.client.timezone).toISODate();
      this.earliestAllowedMonday = DateTime.now()
          .setZone(this.client.timezone)
          .startOf('week')
          .toISODate();

      if (this.widgetStartDate && this.widgetStartDate > this.earliestAllowedMonday) {
        this.calendarDate = this.widgetStartDate;
        this.earliestAllowedMonday = DateTime.fromISO(this.widgetStartDate)
            .startOf('week')
            .toISODate();
      }

      const teacherQuery = {
        teacher: 1,
        hasClassesOrEventTimeSlotsFromDateForward: DateTime.now()
            .setZone(this.client.timezone)
            .startOf('week')
            .toISODate(),
      };
      if (this.widgetTeachers) {
        teacherQuery.id = this.widgetTeachers;
      }
      const teacherQueryString = qs.stringify(teacherQuery);
      [this.teachers, this.classTypes] = await Promise.all([
        YogoApi.get(`/users?${teacherQueryString}`),
        YogoApi.get(
            '/class-types' +
            '?hasClassesFromDateForward=' + DateTime.now().setZone(this.client.timezone).startOf(
                'week').toISODate(),
        ),
      ]);
      this.teachers = _sortBy(this.teachers, ['first_name', 'last_name']);
      this.classTypes = _sortBy(this.classTypes, 'name');
    },
    getCalendarParentWidth() {

      let calendar = this.$refs.calendarWithContainer;
      if (calendar.clientWidth > 700) {
        calendar.classList.add("yogo-calendar-full");
      } else {
        calendar.classList.remove("yogo-calendar-full");
      }
    },

    ...mapActions('calendarFilters', ['setFilter']),
    toggle() {
      this.filtersOpen = !this.filtersOpen;
    },
    gotoNextWeek() {
      this.calendarDate = DateTime.fromISO(this.calendarDate).plus({ weeks: 1 }).toISODate();
    },
    gotoPreviousWeek() {
      if (!this.canGoBack) return;
      this.calendarDate = DateTime.fromISO(this.calendarDate).minus({ weeks: 1 }).toISODate();
    },

    datepickerDateIsDisabled(date) {
      return DateTime.fromJSDate(date).toISODate() < this.earliestAllowedMonday;
    },
  },
  watch: {
    calendarDate(newDate) {
      if (newDate === '') {
        this.calendarDate = DateTime.now().setZone(this.client.timezone).toISODate();
      }
    },
    stateReady(newReadyState) {
      if (newReadyState) {
        this.init();
      }
    },
  },


};
</script>

<style lang="stylus">

.cd-schedule--loading
  opacity .3

.fadein-enter-active
  transition .5s

.fadein-enter
  opacity 0

.md-datepicker.calendar-datepicker
  .md-clear
    display none !important

</style>

<style lang="scss" scoped>

.disabled {
  opacity: 0.4;
}

</style>

<style lang="scss">

@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("default", (
    primary: #12169c,
    accent: #12169c
));

@import "~vue-material/dist/theme/all"; // Apply the theme

.yogo-calendar-full {

  .class-items {
    flex-direction: row;

    .events-group {
      width: 14.2857142857%;
    }
  }
}

.before-today {
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
}


</style>
